import React, { Component } from 'react';
import { styled } from "@material-ui/core/styles";
import Slider from 'react-slick';

import {
  Grid,
  Box,
  Card,
  Typography, CardMedia, Link
} from "@material-ui/core";
import i18n from './i18next/i18n';

interface carouselList {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    subtitle: string;
    description: string;
    link: string;
    rank: number;
    created_at: string;
    updated_at: string;
    image: string;
  };
}[];

type MyProps = { data: carouselList[] };
type MyState = { onChangeValue?: (value: boolean) => void; currentSlide: number; };

export default class CustomCarousel extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentSlide: 0
    }
  }


  render() {
    const { data } = this.props
    const settings = {
      rtl: i18n.dir() === 'rtl',
      centerMode: true,
      centerPadding: "0px",
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      focusOnSelect: true,
      beforeChange: (oldIndex: number, newIndex: number) => {
        this.setState({
          currentSlide: newIndex
        })
      },
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]

    };

    return (
      <>
        <MainConatinerGrid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='slider-container' style={{ width: '95% ' }}>
            <Slider {...settings}>
              {data && data.map((item: carouselList, index: number) => (
                <Grid key={index} className={`item ${this.state.currentSlide === index ? "slide activeSlide" : "slide"}`}>
                  <Card style={{ margin: '10px', boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px" }}>
                    <Box style={{ position: 'relative' }} key={index} >
                      <CardMedia
                        component={"img"}
                        image={item?.attributes?.image}
                        alt={`Slide ${index + 1}`}
                        style={{ position: 'relative', display: 'block', margin: 'auto' }}
                      />
                    </Box>
                    {this.state.currentSlide === index &&
                      <Box style={{ padding: '20px', textAlign: 'center' }}>
                        <Typography className='carouselText2' >{item?.attributes?.title}</Typography>
                        <Typography className='carouselText3' >{item?.attributes?.subtitle}</Typography>
                        <Link href={item?.attributes?.link} underline="always" target='_blank' className='carouselText' >{'View collection & Shop'}</Link >
                      </Box>}
                  </Card>
                </Grid>
              ))}

            </Slider>
          </div>
        </MainConatinerGrid>

      </>
    );
  }
}

const MainConatinerGrid = styled(Grid)({
  "& .activeSlide": {
    transform: 'scale(1.4) !important',
    opacity: '1 !important',
  },
  "& .slide": {
    transform: 'scale(1.0) !important',
    opacity: '0.9 !important',
    transition: 'transform 300ms !important',
    "@media(max-width:1440px)": {
     display: "flex !important",
     justifyContent: "center !important",
    },
  },
  "& .slide img": {
    maxWidth: '100%',
    height: '311px !important',
  },
  "& .slide .MuiCard-root": {
    position: 'relative',
    height: '400px !important',
    transition: '0.3s !important',
    margin: '0 20px !important', // Spacing between cards for non-active slides
  },
  "& .activeSlide .MuiCard-root": {
    "@media(max-width:1440px)": {
      right: '0px !important',
    },
    "@media (min-width: 1441px) and (max-width: 1900px)": {
      right: '7% !important',
      width: "100% !important"
    },
    height: '480px !important',
    transition: '0.3s !important',
    margin: '0 40px !important', // Increase spacing between cards for active slide
    width: '700px !important',
    position: 'relative',
    right: '89px !important'
  },
  "& .slick-list": {
    padding: '20px 0 !important',
    height: '500px !important'
  },
  "& .slick-prev": {
    left: '100px !important',
  },
  "& .slick-next": {
    right: '100px !important',
  },
  "& .slick-slide": {
    outline: 'none !important'
  },
  /* Apply transform to slides other than the current slide */
  "& .slick-slide:not(.slick-center) .MuiCard-root": {
    transform: 'scale(0.8) !important'
  },
  "& .slick-dots li button::before": {
    fontSize: '21px !important',
    color: '#375280 !important',
  },
  "& .slick-prev::before": {
    color: 'red !important'
  },
  " &.slick-next::before": {
    color: 'red !important'
  },
  "& .carouselText": {
    color: '#375280',
    fontSize: '20px',
    fontWeight: 700,
    paddingTop: '5px',
    fontFamily: "Lato"
  },
  "& .carouselText2": {
    color: '#375280',
    fontSize: '20px',
    fontWeight: 400,
    textAlign: "center" as "center",
    fontFamily: "Lato"
  },
  "& .carouselText3": {
    color: '#375280',
    fontSize: '28px',
    fontWeight: 800,
    textAlign: "center" as "center",
    paddingTop: '5px',
    paddingBottom: '5px',
    fontFamily: "Lato"

  },
});




