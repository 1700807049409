import React from "react";
// Customizable Area Start
import { Box, styled, Grid, TextField, InputAdornment, Snackbar, CircularProgress, Modal, Typography, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { tshirt, setting, search,  arrow, cross, upload } from "./assets";
import Alert from '@material-ui/lab/Alert';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddProductCatalougeWeb from "./AddProductCatalougeWeb";
// Customizable Area End

// Customizable Area Start
import CatalogueController, { Props, configJSON } from "./AddProductCatalougeController";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
// Customizable Area End


export default class CatalogueWeb extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const getRemainData = ()=>{
      return(
        this.state.catalogueDataLoading?<CustomBox>
                <CircularProgress />
              </CustomBox>:
                (!this.state.openMainForm&&<CustomBox>
                <img src={tshirt} alt="tshirt" style={webStyle.tshirtImg} />
                <h1 style={webStyle.titleOfDivShirtHeader}>{configJSON.tshirtTitle}</h1>
                <button data-test-id="addProduct" style={webStyle.btnTshirt} onClick={()=>{this.setState({openModel:true,editProductDetail :false})
                localStorage.removeItem("editProduct")
              }}>+ Add Products</button>
              </CustomBox>)
      )
    }
    // Customizable Area End
    return (
      // Customizable Area Start
        <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
          {this.state.roleData === "stylist" ? <StylishSidebar navigation={this.props.navigation} /> : <SellerSideBar navigation={this.props.navigation} />}
          </Grid>
          <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
          {this.state.roleData === "stylist" && <StylishHeader navigation={this.props.navigation} /> } 
 {this.state.roleData === "seller" && <SellerHeader navigation={this.props.navigation} />}
            <div style={webStyle.mainDiv}>
         {!this.state.openMainForm&&     <>
              <div style={webStyle.headerDiv}>
                {!this.state.openMainForm&&
              
                <TitleContainer>
                {this.state.roleData === "stylist" && (
                <img
                  src={arrow}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() => this.handleServiceRedirect("StylishCatalogue")}
                />
              )}
                <Typography className="title">{configJSON.mainTitle}</Typography>
              </TitleContainer>
              }
                
                {this.state.arrayHolder&&this.state.arrayHolder.length>0&&(
                  <CustomBoxSearch>
                  <CustomInput id="outlined-basic" placeholder="Search"  variant="standard"
                    InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} alt="" />
                      </InputAdornment>
                    ),
                  }} /> &nbsp;
                  <button style={webStyle.btnSetting} data-test-id="AddProduct" onClick={()=>{
                      localStorage.removeItem("editProduct")
                    this.setState({openModel:true,editProductDetail :false})}}>+ Add New Products</button>&nbsp;
                  <img src={setting} alt="filter" />
                  </CustomBoxSearch>
                )}           
              </div>
              {this.state.arrayHolder&&this.state.arrayHolder?.length>0?(
                 <div style={webStyle.contentDivConteiner} id="scrollableDiv"> 
                 <InfiniteScroll
                 dataLength={this.state.arrayHolder.length}
                 next={this.getCatalogueListRequest}
                 hasMore={this.state.hasmore}
                 loader={
                  <h4>Loading...</h4>
                }
                 scrollableTarget="scrollableDiv"
                 endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>You have seen all the records.</b>
                  </p>
                }
               >
               <div>
                {this.state.arrayHolder?.map((item: {id: string; attributes: { primary_image: any; name: string; description: string; primary_price: string; is_wishlist: boolean; }; },index: number)=>{
                  return(
                    <div key={index}>
                    <CustomBoxContent  data-test-id="getNavigationMessage" onClick={() => this.getNavigationMessage(item.id)} >
                       <div style={webStyle.divofZara}>
                        <div>
                        <img src={item?.attributes?.primary_image||""} alt="No image found" style={webStyle.zaraImg}/>
                        </div>
                        <div style={{marginLeft:20}}>
                        <h1 style={webStyle.h1First}>{item?.attributes?.name}</h1><br />
                        <h1 style={webStyle.h1Sec}>{item?.attributes?.description}</h1><br />
                        <h1 style={webStyle.h1Last}>${item?.attributes?.primary_price}</h1>
                        </div>
                       </div>
                     </CustomBoxContent>
                     <hr style={{border: "1px solid rgb(226, 232, 240)",marginTop:12,marginBottom:18}}/>
                    </div>
                  )
                })}</div>
                </InfiniteScroll></div>
              ):(getRemainData()
              )}
              </>}
             {this.state.openMainForm&&(<AddProductCatalougeWeb navigation={this.props.navigation} id={""}/>)}  
              </div>
          </Grid>
        </Grid>
        <Snackbar
                    open={this.state.showAlert}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    style={{marginTop:'10px'}}
                    data-testid="oncloseAlert"
                    onClose={()=>this.closeModel()}
                ><Alert variant="filled" onClose={()=>this.closeModel()} severity={ "error"}>{this.state.showError}</Alert></Snackbar>
      
                <div>
                  <Modal
                    open={this.state.openModel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModel}>
                      <CustomDiv>
                        <img src={cross} alt="cross" data-test-id="modelOne" onClick={()=>this.setState({openModel:false})}/>
                      </CustomDiv>
                      <CustomH1 variant="h1">Add Products</CustomH1>
                      <div style={{margin: "40 49 20 50"}}>
                        <div>
                          <h1 style={webStyle.menuH1} data-test-id="Manuaaly" onClick={()=>{this.setState({openMainForm:true,openModel:false})}}>Manually</h1>
                          <hr style={webStyle.hrModel}/>    
                          </div>
                          <div data-test-id="modelTwo" onClick={()=>this.setState({openModelCsv:true,openModel:false})}>          
                          <h1 style={webStyle.csvH1}>CSV Excel Sheet</h1>
                          <hr style={webStyle.hrModel}/>    
                          </div>                                   
                          <h1 style={webStyle.apiH1}>API Integration</h1>
                                           
                      </div>
                    </Box>
                  </Modal>
                </div>      
                <div>
                  <Modal
                    open={this.state.openModelCsv}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModelCsv}>
                      <CustomDiv>
                        <img src={cross} alt="cross" data-test-id="modelFr" onClick={()=>this.setState({openModelCsv:false})}/>
                      </CustomDiv>
                      <CustomH1 variant="h1">CSV Excel Sheet</CustomH1>
                      <div style={{margin: "40 49 20 50"}}>
                      <Button component="label"   style={{paddingLeft:60,display:"grid",boxShadow:'none',width:'516px',height:'230px',backgroundColor:'#F3F3F3',borderRadius:'2px'}} variant="contained">
                        <img src={upload} alt="upload" />
                        <UploadBtn>
                          Upload Products
                        </UploadBtn>
                        <UploadText >Only CSV file accepted</UploadText>
                        <input
                          type="file"
                          hidden
                          data-test-id="upload-image-test" 
                          onChange={(e)=>{this.setState({fileName:e.target.value})}}
                          accept=".csv"
                        />
                      </Button>
                        <p style={webStyle.fileName}>{this.state.fileName}</p>
                          <BtnBox>
                           <button data-test-id="backBtn"  style={webStyle.backCsvBtn} onClick={()=>this.setState({openModelCsv:false,openModel:true})}>Back</button>&nbsp;&nbsp;
                           <button data-test-id="confirmBtn" style={webStyle.confirmBtn} onClick={()=>{this.setState({openMainForm:true,openModelCsv:false})}}>Confirm</button>  
                          </BtnBox>                  
                      </div>
                    </Box>
                  </Modal>
                </div>   
                
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const TitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 20,
  // paddingBottom: 47,
  "& .title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 24,
    color: "#375280",
  },
});
const styleModel = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: "1px solid #CCBEB1",
  borderRadius: "12px",
  width: "450px",
  height: "434px",
  padding:0
};
const styleModelCsv = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: "1px solid #CCBEB1",
  borderRadius: "12px",
  width: "620px",
  height: "484px",
  padding:0
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const CustomBox = styled(Box)({
    textAlign:'center',
    marginTop: "160px",
    marginBottom: "150px",
    marginLeft:'-40px'
});

const CustomBoxSearch = styled(Box)({
    marginLeft:"auto",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center"
});
const CustomInput = styled(TextField)({
    marginRight:25,
    border: "1px solid #CBD5E1",
    borderRadius: "4px",
    width:"250px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    paddingTop:11,
    paddingLeft:14,
    '& ::placeholder':{
      color: "#43556E;",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400,
      fontStyle: "normal !important",
      fontFamily: "Lato , sans-serif",
    }
});
const CustomBoxContent = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  cursor: "pointer"
});
const CustomDiv= styled(Typography)({
    textAlign:'end'
})
const CustomH1 =styled(Typography)({
    color: "#375280",
    fontSize: "30px",
    fontWeight: 500,
    lineHeight: "40px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    textAlign:"center"
})
const UploadBtn = styled(Typography)({
  boxShadow:'none',
  textTransform: "capitalize",
  color: "#375280",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginLeft: "-45px",
  marginTop: "15px"
})
const UploadText = styled(Typography)({
  textTransform: "lowercase",
  color: "#94A3B8",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginLeft: "-59px",
  marginTop: "5px"
})
const BtnBox = styled(Box)({
  display:'flex',
  flexWrap:'wrap',
  marginTop:'32px'
})
const webStyle = {
  fileName:{
    color: "#375280",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  backCsvBtn:{
    width:'252px',
    height:'54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer"
   },
   confirmBtn:{
     width:'252px',
     height:'54px',
     background: "#CCBEB1",
     border: "1px solid #CCBEB1",
     color: "#FFFFFF",
     fontSize: "18px",
     fontWeight: 700,
     lineHeight: "26px",
     fontStyle: "normal !important",
     fontFamily: "Lato , sans-serif",
     borderRadius:'2px',
     cursor: "pointer"
    },
  backBtn:{
   width:'170px',
   height:'54px',
   background: "#FFFFFF",
   border: "1px solid #CCBEB1",
   color: "#375280",
   fontSize: "18px",
   fontWeight: 700,
   lineHeight: "24px",
   fontStyle: "normal !important",
   fontFamily: "Lato , sans-serif",
   cursor: "pointer"
  },
  addBtn:{
    width:'170px',
    height:'54px',
    background: "#CCBEB1",
    border: "1px solid #CCBEB1",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    borderRadius:'2px',
    cursor: "pointer"
   },
  hrModel:{
    border:"1px solid #D9D9D9",
    marginTop:24,
    marginBottom:24
  },
  modelCross:{
    textAlign:"end"
  },
  menuH1:{
    color: "#375280",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor:"pointer",
  },
  csvH1:{
    color: "#375280",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  apiH1:{
    color: "#375280",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  zaraImg:{
  width: "110",
  height: "107",
  border: "1px solid #E2E8F0"
  },
  h1First:{
    margin:0,
    color: "#375280",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  h1Sec:{
    margin:0,
    color: "#375280",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  h1Last:{
    margin:0,
    color: "#059669",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  divofZara:{
    display:'flex'
  },
  contentDivConteiner:{
    border:"1px solid #E2E8F0",
    borderRadius: "4px",
    marginTop: "25px",
    padding: "20 20 0 20",
    height:"800px",
    overflow:"scroll"
  },
  contentHeartDiv:{
    marginLeft:'auto',
    border: "10px solid #FFFFFF",
    borderRadius: "45px",
    marginBottom: 70,
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px 5px #00000014",
    width:"24px",
    height:"24px"
  },
  headerDiv: {
    display: "flex",
    marginBottom: "-5px"
  },
  tshirtImg:{
    marginBottom:12
  },
  btnSetting:{
    background: "#CCBEB1",
    width: "240px",
    marginLeft: -3,
    height: 56,
    color: "#FFFFFF",
    borderRadius: "4px",
    fontSize: "17px",
    lineHeight: "24px",
    fontWeight: 700,
    border: "1px solid",
    marginRight:18,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer"
  },
  
  btnTshirt:{
    background: "#CCBEB1",
    width: "250px",
    marginLeft: -3,
    height: 58,
    color: "#FFFFFF",
    borderRadius: "4px",
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: 700,
    border: "1px solid",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer"
  },
  divTshirt: {
    textAlign:'center',
    marginTop: "190px",
    marginBottom: "150px"
  },
  titleNotification: {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  mainDiv: {
    margin: "30px",
    marginTop: "20px"
  },
  titleOfDivShirtHeader: {
    color: "#375280",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
};
// Customizable Area End
