export const enStylist = {
    "Home": "Home",
    "Stylist": "Stylist",
    "Welcome to your Account": "Welcome to your Account",
    "Favorite Stylist": "Favorite Stylist",
    "Your Stylist wishlist is empty.": "Your Stylist wishlist is empty.",
    "Continue": "Continue",
    "Empty Stylist Message": "You don't have any stylist in the favourite yet. You will find a lot of stylish on our Stylish page."
}

export const arStylist = {
    "Home": "الصفحة الرئيسية",
    "Wishlist": "قائمة الرغبات",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Stylist": "المصممون",
    "Favorite Stylist": "المصمم المفضل",
    "Your Stylist wishlist is empty.": "قائمة أمنيات المصمم الخاص بك فارغة.",
    "Continue": "يكمل",
    "Empty Stylist Message": "ليس لديك أي مصفف شعر في المفضلة حتى الآن. ستجد الكثير من الأناقة على صفحتنا الأنيقة."
}