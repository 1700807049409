import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config");
interface DeliveryAddressAttributes {
  country_code: string;
  name: string;
  contact_number: string;
  phone_number: string;
  area: string;
  street: string;
  block: string;
  zip_code: string;
  city: string;
  house_or_building_number: string;
  address_name: string;
  longitude: number;
  latitude: number;
  is_default: boolean;
}

interface DeliveryAddress {
  type: string;
  attributes: DeliveryAddressAttributes;
  id: string;
}

interface PaymentDetail {
  status: string;
  id: number;
  created_at: string;
  updated_at: string;
  refund_id: string | null;
  charge_id: string;
  amount: number;
  account_id: number;
  currency: string;
  customer_id: string;
  reason: string;
  deleted: boolean;
  refund_reason: string | null;
  merchant_id: string | null;
  order_management_order_id: number;
  refund_amount: string | null;
  order_id: string;
  seller_order_id: string | null;
  payment_type: string;
  last_four_card_digit: string;
}

interface OrderManagementOrderAttributes {
  account: string;
  total: string;
  delivery_addresses: DeliveryAddress;
  order_number: string;
  in_transit_at: string | null;
  placed_at: string;
  status: string;
  sub_total: string;
  confirmed_at: string;
  cancelled_at: string | null;
  deliver_by: string | null;
  delivered_at: string | null;
  refunded_at: string | null;
  order_status_id: number;
  order_deliver_time: string | null;
  updated_at: string;
  returned_at: string | null;
  payment_detail: PaymentDetail;
  order_deliver_date: string | null;
  order_return_date: string | null;
  created_at: string;
  order_return_time: string | null;
}

interface OrderManagementOrder {
  id: string;
  attributes: OrderManagementOrderAttributes;
  type: string;
}

interface OrderItemAttributes {
  placed_at: string;
  status: string;
  in_transit_at: string | null;
  confirmed_at: string;
  cancelled_at: string | null;
  delivered_at: string | null;
  process_at: string;
  rejected_at: string | null;
  return_at: string | null;
  shipped_at: string | null;
  return_pick_at: string | null;
  return_cancel_at: string | null;
  unit_price: string;
  driver_latitude: number | null;
  quantity: number;
  reason_of_rejection: string | null;
  brand_name: string;
  catalogue_name: string;
  catalogue_variant_sku: string;
  catalogue_variant_color: string;
  store_name: string;
  catalogue_variant_back_image: string;
  total_price: string;
  driver_name: string | null;
  catalogue_variant_size: string;
  catalogue_variant_front_image: string;
  otp: string | null;
  catalogue_variant_side_image: string;
  driver_longitude: number | null;
  driver_phone_number: string | null;
}

interface OrderItem {
  id: string;
  attributes: OrderItemAttributes;
  type: string;
}
interface SellerOrderAttributes {
  order_management_order: OrderManagementOrder;
  id: number;
  accept_order_upload_time: string | null;
  order_items: OrderItem[];
  status: string;
}

interface SellerOrder {
  attributes: SellerOrderAttributes;
  id: string;
  type: string;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  page: number;
  rejectOpen: boolean;
  tableAllOrder: SellerOrder[];
  short: string[],
  txtInputValue: string;
  numberData: string;
  visible: boolean,
  isAlert: boolean,
  isLoading: boolean,
  orderId: number,
  reloadTime: number,
  confirmId: number,
  newOrderaOpen: boolean,
  rejectId: number,
  already: string,
  isAlertOrder: boolean,
  roleData:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllOrderDetailseCallId: string = "";
  getMy_OrdersCallId: string = "";
  getTimeOrderDetailsCallId: string = "";
  getRejectOrderDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page: 1,
      orderId: 0,
      tableAllOrder: [],
      visible: false,
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      numberData: "",
      rejectOpen: false,
      txtInputValue: "",
      reloadTime: 45,
      isLoading: true,
      newOrderaOpen: true,
      rejectId: 0,
      isAlert: false,
      confirmId: 0,
      already: "",
      isAlertOrder: false,
      roleData:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllOrderDetailseCallId !== null &&
      this.getAllOrderDetailseCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategoryDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ tableAllOrder: responseJsonCategoryDetails?.orders.data, isLoading: false, isAlert: true })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectOrderDetailCallId !== null &&
      this.getRejectOrderDetailCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonDetailsCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonDetailsCategory) {
        this.setState({ isLoading: true, isAlertOrder: true, already: "Order Rejected" })
        setTimeout(() => {
          this.contioneShopping()
        }, 3000)
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeOrderDetailsCallId !== null &&
      this.getTimeOrderDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategoryDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategoryDetails.data) {
        this.setState({ visible: true, isLoading: true, isAlertOrder: true, already: "Order Accepted" })
        setTimeout(() => {
          this.contioneShopping()
        }, 3000)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };
  dateFormetTransaction = (created_at: string | null) => {
    if (!created_at) return "";
    const [day, month, year] = created_at.split('-').map(Number);
    const monthsData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedMonth = monthsData[month - 1];
    return `${day} ${formattedMonth} ${year}`;
  };
  
  handleClose1 = () => {
    this.setState({ rejectOpen: false })
  };
  oncloseAlert = () => {
    this.setState({ isAlertOrder: false });
  };
  handleOpen1 = (rejectid: number) => {
    this.setState({ rejectOpen: true, rejectId: rejectid })
  };
  async componentDidMount() {
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ orderId: idData })
    this.allOrderData()
    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })
  }
  contioneShopping = () => {
    const toNavigateData = new Message(getName(MessageEnum.NavigationMessage));
    toNavigateData.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    toNavigateData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessageData: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessageData.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 1 });
    toNavigateData.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessageData);
    this.send(toNavigateData);
  }
  rejectData = () => {
    this.setState({ rejectOpen: false })
    this.rejectOrderData()
  }
  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListDeatils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllOrderDetailseCallId = requestMessageListDeatils.messageId;
    requestMessageListDeatils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageListDeatils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getnew_ordersAllOrder
    );
    requestMessageListDeatils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListDeatils.id, requestMessageListDeatils);
  }

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListReject = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectOrderDetailCallId = requestMessageListReject.messageId;
    requestMessageListReject.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListReject.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=reject&reason_of_rejection=${this.state.numberData}`
    );
    requestMessageListReject.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListReject.id, requestMessageListReject);
  }

  timeOrderData = async (confirmid: number) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListTime = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeOrderDetailsCallId = requestMessageListTime.messageId;
    requestMessageListTime.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListTime.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${confirmid}&type=ready_to_ship`
    );
    requestMessageListTime.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListTime.id, requestMessageListTime);
  }

  getAddress = (delivery_addresses: {
    id: string;
    type: string;
    attributes: {
      name: string;
      country_code: string;
      phone_number: string;
      contact_number: string;
      street: string;
      zip_code: string;
      area: string;
      block: string;
      city: string;
      house_or_building_number: string;
      address_name: string;
      is_default: boolean;
      latitude: number;
      longitude: number;
    };
  }) => {
    if (delivery_addresses) {
      const { house_or_building_number, block, area, street, city, zip_code } = delivery_addresses.attributes
      return `${house_or_building_number}, ${block} ${area}, ${street}, ${city} - ${zip_code}`
    } else {
      return ""
    }
  }
  // Customizable Area End
}
