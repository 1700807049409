export const enProdDesc = {
    "PAIR IT WITH": "PAIR IT WITH",
    "Home": "Home",
    "Select Size": "Select Size",
    "No size selected": "No size selected",
    "Colours Available": "Colours Available",
    "Add to Cart": "Add to Cart",
    "Buy Now": "Buy Now",
    "Secure payment": "Secure payment",
    "Size & Fit": "Size & Fit",
    "Fast shipping": "Fast shipping",
    "Fast Return Process": "Fast Return Process",
    "Product description": "Product description",
    "Fit:": "Fit:",
    "Product care & Material": "Product care & Material",
    "Material - ": "Material - ",
    "Expected Delivery & Total Cost": "Expected Delivery & Total Cost",
    "Delivery by:": "Delivery by:",
    "Product Price : ": "Product Price : ",
    "Approx Delivery Cost :": "Approx. Delivery Cost : ",
    "Total Cost : ": "Total Cost : ",
    "Store information": "Store information",
    "Stylish information": "Stylish information",
    "Similar Items": "Similar Items",
}

export const arProdDesc = {
    "PAIR IT WITH": "إقرانها مع",
    "Home": "بيت",
    "Select Size": "حدد الحجم",
    "No size selected": "لم يتم تحديد الحجم",
    "Colours Available": "الألوان المتاحة",
    "Add to Cart": "أضف إلى السلة",
    "Buy Now": "اشتري الآن",
    "Secure payment": "الدفع الآمن",
    "Size & Fit": "الحجم والملاءمة",
    "Fast shipping": "شحن سريع",
    "Fast Return Process": "عملية العودة السريعة",
    "Product description": "وصف المنتج",
    "Fit:": "ملائم:",
    "Product care & Material": "العناية بالمنتج والمواد",
    "Material - ": "مادة - ",
    "Expected Delivery & Total Cost": "التسليم المتوقع والتكلفة الإجمالية",
    "Delivery by:": "التسليم عن طريق:",
    "Product Price : ": "سعر المنتج : ",
    "Approx Delivery Cost :": "تقريبا. تكلفة التوصيل : ",
    "Total Cost : ": "التكلفة الإجمالية : ",
    "Store information": "تخزين المعلومات",
    "Stylish information": "أنيق المعلومات",
    "Similar Items": "عناصر مماثلة",
}