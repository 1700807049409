import React from "react";
// Customizable Area Start
import SellerHelpController, { Props } from "./SellerHelpController";
import { Typography, Grid, Box, styled, Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
    Dialog,
    TextField, DialogContent, DialogActions, Button
 } from "@material-ui/core";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { close } from "../../customisableuserprofiles2/src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class SellerHelp extends SellerHelpController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <ThemeProvider theme={theme}>

                <Grid container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                        { this.state.usertype === "seller" ? (
                                < SellerSidebar navigation={this.props.navigation} />
                            ) : (
                                <StylishSidebar navigation={this.props.navigation} />
                            )}
                    </Grid>
                    <Grid item xl={10} md={10} lg={10} sm={10} xs={10}  >
                    { this.state.usertype === "seller" ? (
                        <SellerHeader navigation={this.props.navigation} />
                            ) : (
                                <StylishHeader navigation={this.props.navigation} />
                            )}
                        <Snackbar
                            open={this.state.isAlert}
                            autoHideDuration={3000}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            onClose={this.oncloseAlert}
                            data-test-id="alertTestId"
                >
                           <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                         </Snackbar>
                        <StoreHelp>
                            <Box style={webStyle.mainHeading}>
                                <Typography className="store-title">{"Get Help"}</Typography>
                                <Box style={webStyle.contactSupportBtn} onClick={this.handleContactClick} data-test-id="contactSupport">
                                    <Typography style={webStyle.supportText}> {"Contact Support"}</Typography>
                                </Box>
                            </Box>
                            <Box style={webStyle.secondContainer}>
                                <Box>
                                    <Typography className="textStyle">Help Center</Typography>
                                    <Typography className="innerText">Please get in touch and we will be happy to help you.</Typography>
                                </Box>
                                <Box>
                                    <Typography className="moreQueryText">More Queries related to  your experience</Typography>
                                    <Muicard >
                                        <CardContent style={webStyle.cardContainer}>
                                            {this.state.getHelpData && this.state.getHelpData?.map((item: any, index: number) => (
                                                <Accordion
                                                    data-test-id={`accordion-${index}`}
                                                    key={item.id}
                                                    expanded={this.state.expanded === `panel${item.id}`}
                                                    onChange={this.handleChange(`panel${item.id}`)}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ArrowForwardIosIcon style={{ color: "#375280" }} fontSize="small" />}
                                                        aria-controls={`panel${item.id}a-content`}
                                                        id={`panel${item.id}a-header`}
                                                    >
                                                        <Typography style={webStyle.accordionTitle}>{item?.accordionHeading}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography style={webStyle.accordionDescribtion}>{item?.accordionSummary}</Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </CardContent>
                                    </Muicard>
                                </Box>
                                <Box style={webStyle.treandingArticalContainer as React.CSSProperties}>
                                    <Typography className="moreQueryText">Trending Articles</Typography>
                                    <Typography className="innerText1">Tap to view most helpful articles</Typography>
                                    <ul style={webStyle.listStyle}>
                                        <li>Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
                                        <li style={{margin: "15px 0px"}}>Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
                                        <li>Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
                                    </ul>
                                </Box>
                            </Box>

                        </StoreHelp>
                    </Grid>
                </Grid>

                <ContactSupport
                    open={this.state.contactSupport}
                    data-test-id="handleContactSupport"
                    onClose={this.handleContactSupport}
                >
                    <DiologcontentStyled>
                        <Box style={{
                            padding: "25px 25px 0px 25px"
                        }}>
                            <Box style={{
                                display: "flex",
                                justifyContent: "end"
                            }} onClick={this.handleContactSupport}>
                                <img src={close} alt="close" />
                            </Box>
                            <Box style={webStyle.deleteContainer}>
                                <Typography style={webStyle.deleteText}>
                                    Contact Support
                                </Typography >
                            </Box>
                                <Box >
                                    <Typography style={webStyle.inputHeadingBox1}>{"Name"}</Typography>
                                    <TextField
                                        data-test-id="name"
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.name}
                                        onChange={(event) => this.handleNameChange(event.target.value)}
                                        helperText={this.state.errorsMessage.nameErrorMsg ? this.state.errorsMessage.nameErrorMsg : ""}
                                        error={true}
                                        className={this.generateClassName(this.state.errorsMessage.nameErrorMsg, "inputFeild2", "inputFeild2Error2")}
                                        placeholder={"Enter Name"}
                                    />
                                </Box>

                                <Box >
                                    <Typography style={webStyle.inputHeadingBox1}>{"Email"}</Typography>
                                    <TextField
                                        data-test-id="Email"
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.email}
                                        onChange={(event) => this.handleEmailChange(event.target.value)}
                                        helperText={this.state.errorsMessage.emailErrorMsg ? this.state.errorsMessage.emailErrorMsg : ""}
                                        error={true}
                                        className={this.generateClassName(this.state.errorsMessage.emailErrorMsg, "inputFeild2", "inputFeild2Error2")}
                                        placeholder={"Enter Email"}
                                    />
                                </Box>

                                <Box >
                                    <Typography style={webStyle.inputHeadingBox1}>{"Subject"}</Typography>
                                    <TextField
                                        data-test-id="Subject"
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.subject}
                                        onChange={(event) => this.handleSubjectChange(event.target.value)}
                                        helperText={this.state.errorsMessage.subjectErrorMsg ? this.state.errorsMessage.subjectErrorMsg : ""}
                                        error={true}
                                        className={this.generateClassName(this.state.errorsMessage.subjectErrorMsg, "inputFeild2", "inputFeild2Error2")}
                                        placeholder={"Enter Subject"}
                                    />
                                </Box>

                                <Box >
                                    <Typography style={webStyle.inputHeadingBox1}>{"Description"}</Typography>
                                    <TextField
                                        placeholder="Enter Description"
                                        data-test-id="Description"
                                        fullWidth
                                        multiline
                                        error={true}
                                        onChange={(event)=>this.handleDescriptionChange(event.target.value)}
                                        className={this.generateClassName(this.state.errorsMessage.descriptionErrorMsg, "inputFeild2", "inputFeild2Error2")}
                                        helperText={this.state.errorsMessage.descriptionErrorMsg ? this.state.errorsMessage.descriptionErrorMsg : ""}
                                        rows={3}  
                                        variant="outlined"  
                                    />
                                </Box>
                        </Box>
                    </DiologcontentStyled>
                    <DialogActions style={{ justifyContent: "center", padding: "40px" }}>
                        <Button data-test-id="handleConfirmClose" style={webStyle.cancelButton} onClick={this.handleContactSupport} >
                            Close
                        </Button>
                        <Button data-test-id="handleConfirmAdd" style={webStyle.confirmButton} onClick={this.postContactUsApi}>
                            Add
                        </Button>
                    </DialogActions>
                </ContactSupport>
            </ThemeProvider>
        );
    }
    // Customizable Area End



}

// Customizable Area Start

const DiologcontentStyled = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    gap: "24px",    
});

const ContactSupport = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        minWidth: '620px',
        minHeight: "716px"
    },
    '& .MuiPaper-rounded': {
        borderRadius: '12px',
    },"& .inputFeild2Error2": {
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            border: "none"
        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
    },
    "& .inputFeild2": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",

        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
    "& .MuiDialogContent-root:first-child":{
        paddingTop: "0px"
    }
});

const StoreHelp = styled(Box)({
    minHeight: "calc(100vh - 85px)",
    padding: "30px 60px",
    "& .store-title": {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 700,
        color: "#375280",
        marginBottom: "20px"
    },
    "& .textStyle": {
        fontSize: "20px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280"
    },
    "& .innerText": {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        color: "375280",
        marginTop: "10px"
    },
    "& .innerText1": {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        color: "375280",
        marginTop: "12px"
    },
    "& .moreQueryText": {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 700,
        color: "375280",

    }
});

const Muicard = styled(Card)({
    width: '98%', marginTop: "1rem" ,
    "& .MuiPaper-elevation1": {
        boxShadow: '0 0  0 0 !important',
        background: "transparent !important"
    },
    "& .MuiCardContent-root:last-child": {
        paddingBottom: '0px',
    },
    "& .MuiAccordion-root.Mui-expanded": {
        margin: '0px 0',
    }
})
const webStyle = {
    mainHeading : { display: "flex", justifyContent: "space-between", marginBottom: "10px" },
    secondContainer: { display: "flex",
     flexDirection: "column", gap: "20px",
      maxHeight: "657px", backgroundColor: "#ffff",
       border: "2px solid #E2E8F0",
     padding: "10px 16px" } as React.CSSProperties,
     cardContainer:{ backgroundColor: '#FFF', padding: "0px" },
     listStyle:{color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400},
    deleteContainer: {
        marginLeft: "12px", display: "flex", flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,
    confirmButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "white",
        backgroundColor: "#CCBEB1",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "252px",
        padding: "10px",
        cursor: "pointer",
        textTransform: "capitalize"
    } as React.CSSProperties,
    deleteText: {
        fontSize: '30px',
        color: "#375280",
        fontWeight: 500,
        fontFamily: "Lato"
    },
    cancelButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "#375280",
        backgroundColor: "#FFFFFF",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "252px",
        padding: "10px",
        cursor: "pointer",
        textTransform: "capitalize"
    } as React.CSSProperties,
    inputHeadingBox1: {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px 0",
        textOverflow: "ellipsis",
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    accordionTitle: {
        color: '#375280',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: "Lato"
    },
    accordionDescribtion: {
        color: '#375280',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: "Lato",
        borderBottom: '1px solid #E2E8F0',
        paddingBottom: '20px',
        width: '100%'
    },
    treandingArticalContainer: {
        overflowY: "auto"
    },
    supportText: {
        fontFamily: 'Lato',
        fontSize: "16px",
        fontWeight: 700,
    },
    contactSupportBtn: {
        width: "160px",
        height: "44px",
        borderRadius: "2px",
        backgroundColor: "rgb(204, 190, 177)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        cursor: "pointer",
    },
}
// Customizable Area End
