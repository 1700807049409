import React from "react";
// Customizable Area Start
import {
    Box, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Grid, Typography
} from "@material-ui/core";
import SellersRejectedOrderDetailsController, {
    Props,
} from "../SellersRejectedOrder/SellersRejectedOrderDetailsController.web";
import { Noimageavailablepng, button_arrow } from "../assets"
import SellerHeader from "../../../../components/src/Seller/SellerHeader";
import StylishHeader from "../../../../components/src/Stylish/StylishHeader";
import SellerSidebar from "../../../../components/src/Seller/SellerSideBar";
import StylishSidebar from "../../../../components/src/Stylish/StylishSidebar";
// Customizable Area End

export default class SellersRejectedOrderDetailsWebPage extends SellersRejectedOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    orderDetailsRejected = () => {
        const orderData = this.state.tableAllOrder.find(
            (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
                order.attributes.order_management_order.attributes.order_number === this.state.orderId
        );
        if (!orderData) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBoxRejected, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        const { order_items, order_management_order } = orderData.attributes;
        const { delivery_addresses } = order_management_order.attributes;

        return (
            <>
                <Box style={{ margin: '30px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Box style={{ display: "flex", alignItems: 'center' }}>
                                <img src={button_arrow} style={{ cursor: "pointer" }} data-testid="contioneShopping" onClick={this.contioneShopping} /> <Box style={webStyle.newOrderIdRejected} data-testid={`order_number`}>#{order_management_order.attributes.order_number}</Box> <Box style={{ ...webStyle.rejectedBox, textAlign: 'center' }}>Rejected</Box>
                            </Box>
                            <Box style={{ color: "#9A9A9A", fontSize: "16px", fontFamily: "Lato", fontWeight: 500, marginTop: "6px" }}>
                                Total Products : {order_items.length}
                            </Box>
                        </Box>
                    </Box>
                    <Box style={webStyle.rejectBox}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Box style={webStyle.rejectinfoBox}>Reason of product rejection&nbsp;:</Box>&nbsp;&nbsp;
                            <Box style={{ ...webStyle.rejectinfoBox, fontWeight: 700 }}>{order_items[0].attributes.reason_of_rejection}</Box>
                        </Box>
                    </Box>
                    <Box style={webStyle.tableBox}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Box style={webStyle.orderDetailsNameRejected}>Store Name</Box>
                                <Box style={{ ...webStyle.orderDetailsValueRejected, textTransform: "capitalize" }}>{order_items[0].attributes.store_name}</Box>
                            </Grid>
                            <Grid item xs>
                                <Box style={webStyle.orderDetailsNameRejected}>Customer Name</Box>
                                <Box style={{ ...webStyle.orderDetailsValueRejected, textTransform: "capitalize" }}>{order_management_order.attributes.account}</Box>
                            </Grid>
                            <Grid item xs>
                                <Box style={webStyle.orderDetailsNameRejected}>Delivery Date</Box>
                                <Box style={{ ...webStyle.orderDetailsValueRejected, textTransform: "capitalize" }}>{this.dateFormetRejectTransaction(order_management_order.attributes.order_deliver_date)}</Box>
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: '30px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Box style={webStyle.orderDetailsNameRejected}>Shipping Address</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueRejected, textTransform: "capitalize" }}>{this.getAddressRejeted(delivery_addresses)}</Box>
                                </Grid>
                                <Grid item xs>
                                    <Box style={webStyle.orderDetailsNameRejected}>Coupon Applied</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueRejected, textTransform: "capitalize" }}>Fash10(-$5)</Box>
                                </Grid>
                                <Grid item xs>
                                    <Box style={webStyle.orderDetailsNameRejected}>Payment Method</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueRejected, textTransform: "capitalize" }}>Debit Card ending {delivery_addresses.attributes.house_or_building_number}</Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box>
                        <Box style={{ border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "20px" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {this.state.rejectedOrder.map((header: string, index: number) => (
                                            <TableCell key={index} style={webStyle.tableHader}>
                                                <Typography variant="h6">{header}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order_items?.map((order, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}>
                                                <Box style={{ display: "flex" }}>
                                                    <Box >
                                                        <img src={order.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : order.attributes.catalogue_variant_front_image} style={{ width: '44px', height: '44px', borderRadius: '2px', marginRight: "10px" }} />
                                                    </Box>
                                                    <Box style={{ ...webStyle.tableHaderrowRejected }}>{order.attributes.catalogue_name}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}>{order.attributes.brand_name}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_size}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box style={{ backgroundColor: order.attributes.catalogue_variant_color, width: '14px', height: '14px', borderRadius: '10px', marginRight: "8px" }} />{order.attributes.catalogue_variant_color}
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_sku}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}>${order.attributes.total_price}</TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }

    sellerRejectedHeader = () => {
        const { roleData } = this.state;
        switch (roleData) {
            case "seller":
                return <SellerHeader navigation={this.props.navigation} data-testid="sellerRejectedHeader" />;
            case "stylist":
                return <StylishHeader navigation={this.props.navigation} data-testid="sellerRejectedHeader" />;
            default:
                return <></>;
        }
    };
    sellerRejecteSidebar = () => {
        const { roleData } = this.state;
        switch (roleData) {
            case "seller":
                return (
                    <SellerSidebar
                        className="sellerRejectedHeader"
                        navigation={this.props.navigation}
                    />
                );
            case "stylist":
                return (
                    <StylishSidebar
                        className="sellerRejectedHeader"
                        navigation={this.props.navigation}
                    />
                );
            default:
                return <></>;
        }
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ display: 'flex' }}>
                    {this.sellerRejecteSidebar()}
                    <Grid container>
                        <Grid item lg={12} md={12}>
                            {this.sellerRejectedHeader()}
                            {this.orderDetailsRejected()}
                        </Grid>
                    </Grid>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    rejectinfoBox: { color: "#375280", fontSize: "14px", fontFamily: 'Lato', fontWeight: 400 },
    rejectBox: { backgroundColor: "#F4F4F4", height: "42px", paddingLeft: '20px', display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '30px' },
    tabsBoxRejected: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    orderDetailsValueRejected: { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px", marginTop: "10px", textTransform: "capitalize" },
    orderDetailsNameRejected: { color: "#375280", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px" },
    tableBox: { border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "30px", padding: '20px' },
    newOrderIdRejected: { color: "#375280", fontFamily: 'Lato', fontWeight: 700, fontSize: "24px", margin: '0px 16px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    tableHaderrowRejected: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
}
// Customizable Area End