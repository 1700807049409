import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Attributes } from "./ClientChatController";
import { formatLiveChatTime, setupOneToOneWebSocket } from "../../../components/src/Chat/chat.web";
import { apiCall } from "../../../components/src/APICall";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");

type ChatAttribute = {
  id: number;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
  message: string | OrderRequestMessage | WishlistMessage;
  role: string;
  message_type?: string
}

type ChatMessage = {
  id: string;
  type: string;
  attributes: ChatAttribute
};

type OrderRequestMessage = {
  order_request_id: number;
  product_name: string;
  gender: string;
  size: string;
  color: string;
  product_quantity: number;
  price_per_unit: string;
  shipping_cost: string;
  product_display_image_url: string | null;
};

type WishlistMessage = {
  wishlist_id: number;
  wishlist_name: string;
  catalogues: Catalogue[];
};

type Catalogue = {
  catalogue_id: number;
  catalogue_name: string;
  images: string[];
};

type AccountsChat = {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    muted: boolean;
    role: string;
    account_role: string;
    account_name: string;
    account_phone: string;
    profile_pic?: string;
  };
};

type Wishlist = {
  id: number;
  account_id: number;
  name: string;
  description: string | null;
  share_token: string | null;
  shareable_id: number;
};

type ChatData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    accounts_chats: AccountsChat[];
    messages: ChatMessage[];
    wishlist: Wishlist;
  };
  relationships: {
    accounts: {
      data: Account[];
    };
  };
};

export type Meta = {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}

type Account = {
  id: string;
  type: string;
};

type ChatResponse = {
  data: ChatData;
  current_user_role: string;
  meta: Meta;
  errors?: {[errKey:string]: string}[]
};

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  chat_id: number | string | undefined;
  senderDetails: {
    profile: string;
    full_name: string;
  };
  receiverDetails: Attributes | null;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorElClientsChat: HTMLElement | null;
  chatId: string | number;
  chatMessages: ChatAttribute[];
  receiverProfile: string;
  hasMoreChats: boolean;
  chatMeta: Meta;
  message: string;
  media: File | null;
  mediaPreviewUrl: string;
  isChatAlert: boolean;
  errorMessage: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OneToOneChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChatListApiCallId: string = ""
  sendMessageApiCallId: string = ""
  webSocket: WebSocket | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      anchorElClientsChat: null,
      chatId: 0,
      chatMessages: [],
      receiverProfile: "",
      hasMoreChats: false,
      chatMeta: {
        current_page: 1,
        next_page: 2,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      }, 
      message: "",
      media: null,
      mediaPreviewUrl: "",
      isChatAlert: false,
      errorMessage: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if(responseJson && !responseJson.errors) {
        if(this.getChatListApiCallId === apiRequestCallId){
          this.getChatListResponse(responseJson)
        }
        if(this.sendMessageApiCallId === apiRequestCallId){
          this.getSendMessageResponse(responseJson)
        }
    } else {
      this.handleChatError(responseJson)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if(this.props.chat_id !== prevProps.chat_id){
      this.setState(
        {
          chatId: Number(this.props.chat_id),
          chatMeta: {
            current_page: 1,
            next_page: 2,
            prev_page: null,
            total_pages: 0,
            total_count: 0,
          }, 
          message: "",
          media: null,
          mediaPreviewUrl: "",
          chatMessages: [],
          errorMessage: "",
          isChatAlert: false
        },
        () => {
          if(this.props.chat_id){
            this.webSocketConnection();
            this.getChatList();
          }
        }
      );
    }
  }

  handleChatError = (errResp: ChatResponse) => {
    if(errResp.errors) {
      const errorMessages = errResp.errors.map((error: { [key:string]: string}) => Object.values(error)[0]).join(", ");
      this.setState({
        isChatAlert: true,
        errorMessage: errorMessages
      })
    } else {
      this.setState({
        isChatAlert: true,
        errorMessage: "Something went wrong"
      })
    }
  }

  handleCloseChatSnack = () =>{
    this.setState({
      errorMessage: "",
      isChatAlert: false
    })
  }

  navigateToNewPaymentReq = (component: string) => {
    if(component==="NewOrderRequestId" || component==="NewPaymentRequestId"){
      this.props.navigation.navigate(component, {id: this.state.chatId} );
    }
  }

  handleClickChatMore = (event: React.MouseEvent<HTMLImageElement>) => {
    this.setState({ anchorElClientsChat: event.currentTarget });
  };

  handleCloseChatMore = () => {
    this.setState({ anchorElClientsChat: null });
  };

  webSocketConnection = () => {
    const URL = baseURL.replace(/^https?:\/\//i, "");
    const token = localStorage.getItem("auth-token");
    this.webSocket = setupOneToOneWebSocket(
      URL,
      token,
      this.state.chatId,
      () => {}
    );
  };

  getMoreApiChatList = () => {
    const { chatMeta } = this.state
    if (chatMeta.current_page <= chatMeta.total_pages) {
      this.getChatList();
    }
  }

  getChatList = async () => {
    const stylistToken = await getStorageData('auth-token')
    const {chatMeta} = this.state;
    if (!chatMeta.current_page || chatMeta.current_page === null) {
      this.setState({ hasMoreChats: false })
      return
    }

    const chatListEndpoint = `${configJSON.chatListEndPoint}/${this.state.chatId}?page=${chatMeta.current_page}&per_page=10`

    this.getChatListApiCallId = await apiCall({
      token: stylistToken,
      endPoint: chatListEndpoint,
      method: configJSON.getApiMethod,
      contentType: configJSON.apiContentType
    });
  };

  getChatListResponse = (resp: ChatResponse) => {
    if(resp.data.attributes){
      const messages  = resp.data.attributes.messages
      const accounts = resp.data.attributes.accounts_chats
      if(messages && Array.isArray(messages)){
        const newMessages = messages.map(attr=> {
          return  {...attr.attributes, created_at: formatLiveChatTime(attr.attributes.created_at), updated_at: formatLiveChatTime(attr.attributes.updated_at)}
         })
        this.setState((prevState)=>({
          chatMessages: [...prevState.chatMessages,...newMessages] as ChatAttribute[]
        }))
      }
      if(accounts && Array.isArray(accounts)){
        this.setState({
          receiverProfile: accounts.find((acc: AccountsChat) => acc.attributes.account_role === "receiver")?.attributes.profile_pic || ""
        })
      }
    }
    if(resp.meta){
      const { total_pages } = resp.meta
      this.setState((prevState: S) => ({
        chatMeta: { ...resp.meta, current_page: prevState.chatMeta.current_page + 1 },
        hasMoreChats: prevState.chatMeta.current_page < total_pages
      }));
    }
  }

  submitMessageOrMedia = (message: string) => {
    this.setState({ message }, () => {
      message && this.sendMessageChat();
    });
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const filePreviewUrl = URL.createObjectURL(file);
      this.setState({ media: file, mediaPreviewUrl: filePreviewUrl }, () => {
        this.sendMessageChat()
      });
    }
  };

  sendMessageChat = async () => {
    const chatToken = await getStorageData('auth-token')
    const formData = new FormData();
    formData.append("message[message]", this.state.message || "N/A");
    if (this.state.media) {
      formData.append("message[attachments]", this.state.media);
    }
    this.sendMessageApiCallId = await apiCall({
      token: chatToken,
      endPoint: `${configJSON.createMessageEndPoint}/${this.state.chatId}/messages`,
      method: configJSON.postApiMethod,
      body: formData,
    });
  };

  getSendMessageResponse = (response: {data: ChatData}) => {
    if(response.data && response.data.attributes){
      this.setState((prevState)=>({
        message: "",
        media: null,
        mediaPreviewUrl: "",
      }))      
    }
  }

  
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
