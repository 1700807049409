import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {removeStorageData, setStorageData, getStorageData } from "../../../framework/src/Utilities";
import i18n, { i18nUpdate } from "../../../components/src/i18next/i18n"
import storage from "../../../framework/src/StorageProvider.web";

interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
  }
  
interface Account {
    id: number;
    first_name: string | null;
    last_name: string | null;
    full_phone_number: string;
    country_code: number;
    phone_number: number;
    email: string;
    activated: boolean;
    device_id: string | null;
    unique_auth_id: string;
    password_digest: string;
    created_at: string;
    updated_at: string;
    user_name: string | null;
    platform: string | null;
    user_type: string | null;
    app_language_id: number | null;
    last_visit_at: string | null;
    is_blacklisted: boolean;
    suspend_until: string | null;
    status: string;
    gender: string | null;
    date_of_birth: string | null;
    age: number | null;
    stripe_id: string | null;
    stripe_subscription_id: string | null;
    stripe_subscription_date: string | null;
    role: string;
    full_name: string;
    is_verified: boolean | null;
    share_token: string | null;
    approve_status: string;
    seller_status: string;
    notification: {};
    customer_id: string | null;
    language:number;
    currency:number;
    stylist_redirect_flag?: string;
}

interface Meta {
    token: string;
    refresh_token: string;
    id: number;
    account: Account;
}

interface PostLoginResponse {
    meta: Meta;
    data: {}
}

  interface ValidResponseType
  {
    message?: object;
    data: object;
    meta:object;
  }
  
interface InvalidResponseType {
    errors: Array<string>;
}

type AlertType = 'success' | 'error' | 'warning' | 'info';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isEmail:string;
    showPasswordValue:boolean;
    passwords:string;
    errorsMessage: {
        emailMessage?: string;
        passwordMessage?: string;
        errorEmail?: boolean;
        errorPassword?: boolean;
    };
    isAlert: boolean;
    alertMsg: string;
    alertType: AlertType;
    alertTyperoute: AlertType;
    emailError:boolean;
    passError:boolean;
    login_page:boolean
    ;userType: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    emailLoginFormApiCallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isEmail:"",
            showPasswordValue:false,
            passwords:"",
            errorsMessage: {
                emailMessage: "",
                passwordMessage: "",
                errorEmail: false,
                errorPassword: false,
            },
            isAlert: false,
            login_page:false,
            alertMsg: '',
            alertType: 'success' as AlertType,
            alertTyperoute: 'error' as AlertType,
            emailError:false,
            passError:false
            ,userType: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        i18nUpdate()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            let apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiSucessCall(apiRequestCallId, responseJson);
           } else if (responseJson && responseJson.errors) {
             this.apiFailureCall()
           }
          }
        // Customizable Area End
    }

    // Customizable Area Start


    transLogin = (klog: string ) => {
        return i18n.t(klog, { ns: "loginSignup"})
    }

    handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isEmail = event.target.value
        this.setState({
            isEmail: isEmail,
            emailError:false,
            passError:false,
            errorsMessage: {
                emailMessage: "",
                errorEmail: false
            }
        })
    };
    handleLogin=(result:string)=>{
        if(result=='true'){

            setTimeout(() => {
                this.setState((state, props) => ({
                  login_page: true
                }), () => {
                  setTimeout(() => {
                    this.setState(() => ({
          
                  login_page: false
                }))
              }, 1500);
            })
          }, 100);
        }

    }
    async  componentDidMount(){
        super.componentDidMount();
        const userRole  = await getStorageData('userRole', true);
        this.setState({
          userType: userRole?.userType
        })
        let result=await storage.get('login_page');
      this.handleLogin(result)
    }

    handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const passwords = event.target.value;
        const passRegx = configJSON.passRegx;
        if (!passRegx.test(passwords)) {
            this.setState({
                passwords: passwords,
                passError:true,
                errorsMessage: {
                    passwordMessage: this.transLogin(configJSON.min8digitValidationMsg),
                    errorPassword: true
                }
            });
        } else {
            this.setState({
                passError:false,
                passwords: passwords,
                errorsMessage: {
                    passwordMessage: "",
                    errorPassword: false
                }
            });
        }  
    }
    
    handleTogglePassword = () => {
        this.setState((prevState) => ({
            showPasswordValue: !prevState.showPasswordValue,
        }));
    };

    handleSumitlogin = (submitEvent: React.FormEvent) => {
      
        submitEvent.preventDefault();
          
        const { isEmail, passwords } = this.state;
        const emailRegex = configJSON.emailRegex;
        const passRegx = configJSON.passRegx;
        if (isEmail === "" || !emailRegex.test(isEmail)) {
            if(isEmail !== ""){
                this.setState({
                    emailError:true,
                    errorsMessage: {
                        emailMessage: this.transLogin(configJSON.emailValidMsg),
                        errorEmail: true
                    }
                });
            }else{
            this.setState({
                emailError:true,
                errorsMessage: {
                    emailMessage: this.transLogin(configJSON.emailValidationMsg),
                    errorEmail: true
                }
            });
        }
        }else if (passwords === "" || !passRegx.test(passwords)) {
            if(passwords !== ""){
                this.setState({
                    passwords:passwords,
                    passError:true,
                    emailError:false,
                    errorsMessage: {
                        passwordMessage: this.transLogin(configJSON.min8digitValidationMsg),
                        errorPassword: true
                    }
                })
            }else{
            this.setState({
                passError:true,
                emailError:false,
                errorsMessage: {
                    passwordMessage: this.transLogin(configJSON.validationPassMsg),
                    errorPassword: true
                }
            })
        }
        }
        else {
            this.postFormApi()
        }
        removeStorageData('full_name')
        removeStorageData('email')
        removeStorageData('account_holder_name')
        removeStorageData('iban')
        removeStorageData('owner_full_address')
        removeStorageData('owner_contact_number')
        removeStorageData('isCountryData')
        removeStorageData('company_full_address')
        removeStorageData('isCountryData1')
        removeStorageData('company_contact_number')
        removeStorageData('account_number')
        removeStorageData('social_media_account')
    }

    apiCallService = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,

        };
        const requestMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessageSerice.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessageSerice.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessageSerice.id, requestMessageSerice);
        return requestMessageSerice.messageId;
    };
    getNavigationActivateaccount = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'AccountActiveProfile'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    
      }

      getnavigationToASplashScreen = () => {
        const navigateIcon: Message = new Message(getName(MessageEnum.NavigationMessage))
        navigateIcon.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'Home'
        );
        navigateIcon.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
        navigateIcon.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(navigateIcon);
      }
      getNavigationSellerDashboardaccount = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'SellerStore'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    
      }

      getNavigationCreateStore = () => {

    
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'CreateSellerStore'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    
      }

    apiSucessCall = async (apiRequestCallId: string, responseJson:PostLoginResponse) => {
        if (apiRequestCallId === this.emailLoginFormApiCallId) {
            
            if (responseJson && responseJson.meta && responseJson?.meta?.token) {
                const filter = configJSON.typeOptions.find((element: any)=>  element.value === this.state.userType);
                if((filter?.label === responseJson.meta.account?.role || (this.state.userType == null && responseJson.meta.account?.role === "buyer"))){
                    setStorageData("auth-token", responseJson.meta.token);
                    setStorageData("user_id", responseJson.meta.id)
                    setStorageData("user_data", JSON.stringify(responseJson.meta.account))
                    const tempRole = {
                        userType: responseJson.meta?.account?.role,
                        language: responseJson.meta?.account?.language,
                        currency: responseJson.meta?.account?.currency
                      };
                    await setStorageData("userRole",JSON.stringify(tempRole));
                    this.setState({ isAlert: true, alertMsg: configJSON.Login_SuccessfullyMsg, alertType: configJSON.SuccessMsg, isEmail: '', passwords: '' });                
                    switch (tempRole.userType) {
                        case configJSON.buyerMsg:
                            const message: Message = new Message(getName(MessageEnum.NavigationMessage))
                            message.addData(
                                getName(MessageEnum.NavigationTargetMessage),
                                'LandingPage'
                            );
                            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
                            this.send(message);
                            break;
                        case configJSON.sellerMsg:
                            this.handleSellerMsg(responseJson.meta.account.seller_status)
                            break;
                        case configJSON.StylishMsg:
                            this.handlStylistNavigation(responseJson.meta.account?.stylist_redirect_flag)
                            break;
                    }
                }else{
                    this.setState({
                        isAlert: true,
                        alertMsg: this.transLogin("You have selected a different role to sign in. Please select role again."),
                        alertType: "warning"
                    })
                }
            }
        }
    };

    openMobileScreen = () => {
        const toMobile = new Message(getName(MessageEnum.NavigationMessage));
        toMobile.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.MobileAccountLoginBlockMsg);
        toMobile.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(toMobile);
    };

    opeForgotPasswordScreen = () => {
    const toMobile = new Message(getName(MessageEnum.NavigationMessage));
    toMobile.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.ForgotPasswordMsg);
    toMobile.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(toMobile);
    };

    getNavigationStylist = (navigateTo: string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),`${navigateTo}`
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    };
    handlStylistNavigation = (stylist_status?: string) => {
        if (stylist_status == 'stylist_document_submission_page') {
            this.getNavigationStylist('StylistUploadDocumentsweb')
        } else if (stylist_status == 'stylist_landing_page') {
            setStorageData("stylishType", "landing");
            this.getNavigationStylist('StylishDashboard');
        } else if(stylist_status == "stylist_confirmation_page"){
            setStorageData("stylishType", "confirmation");
            this.getNavigationStylist("StylishDashboard");
        } else{
            this.getNavigationStylist("StylistAccountActiveProfile")
        }
    };
    handleSellerMsg = (seller_status: string) => {
        if (seller_status == 'Signup') {
            this.getNavigationActivateaccount()
        } else if (seller_status == 'Account_activation') {
            this.getNavigationCreateStore()
        } else {
            this.getNavigationSellerDashboardaccount()
        }
    }
    apiFailureCall = async () => {
        this.setState({ isAlert: true, alertMsg: this.transLogin(configJSON.account_not_foundMsg), alertType: configJSON.errorMsg });
    };

    postFormApi = async () => {
        const body = {
                data: {
                    type: configJSON.email_accountMsg,
                    attributes: {
                        email: this.state.isEmail,
                        password: this.state.passwords
                    }
                }
        }
        this.emailLoginFormApiCallId = await this.apiCallService({
            contentType: configJSON.loginApiContentType,
            method: configJSON.loginAPiMethod,
            endPoint: configJSON.loginEndPoint,
            body: body
        });
    };  

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    openRegistrationScreen = () => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.EmailAccountRegistrationMsg);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(toNavigate);
      };

    
    // Customizable Area End
}
