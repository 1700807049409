export const setupOneToOneWebSocket = (
    URL: any,
    token: any,
    chatId: any,
    handleMessage: any
  ) => {
    const webSocket = new WebSocket(`wss://${URL}/cable?token=${token}`);
  
    webSocket.onopen = () => {
      console.log("WebSocket connection established.");
      webSocket.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "ChatChannel",
            id: chatId,
          }),
          data: JSON.stringify({ id: chatId }),
        })
      );
    };
  
    webSocket.onclose = () => console.log("WebSocket connection closed.");
    webSocket.onerror = (error) => console.error("WebSocket error:", error);
  
    webSocket.onmessage = (event) => {
      let data = JSON.parse(event.data);
  
      if (data.message?.message) {
        data = JSON.parse(data.message.message);
        handleMessage(data);
      }
    };
  
    return webSocket;
};


export const formatChatDate = (dateString:string) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };
  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  let timeString = date.toLocaleTimeString([], options);
  if (isToday) {
    return timeString;
  } else if (isYesterday) {
    return "Yesterday";
  } else {
    return date.toLocaleDateString;
  }
}

export const formatLiveChatTime = (dateString:string) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  
  const date = new Date(dateString);
  return date.toLocaleTimeString( undefined, options);
}

export const truncateText = (title: string, titleLength: number) => {
  if (title === undefined || title === null) return "";
  if (title.length > titleLength) {
    return `${title.slice(0, titleLength)}...`;
  } else {
    return title;
  }
};
