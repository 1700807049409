import React from "react";
// Customizable Area Start
import {
    Box, Table, TableBody, TableRow, Select, Snackbar, Drawer, Divider, MenuItem, TableCell, TableHead, CircularProgress, Button, Typography, Grid, Backdrop, Fade, Modal, Dialog, TextField, FormHelperText, withStyles, FormControl
} from "@material-ui/core";
import SellersAllOrderDetailsController, {
    Props,
} from "./SellersAllOrderDetailsController.web";
import Alert from '@material-ui/lab/Alert';
import { Noimageavailablepng, button_arrow, button_close, document, group_Group, message, calls } from "../assets"
import SellerHeader from "../../../../components/src/Seller/SellerHeader";
import { styled } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from "@material-ui/icons/Close";
import { statusContainerReturnData, statusContainerTrackDriver } from "../../../../components/src/Seller/SellerStatusPopup";
import { withScriptjs } from 'react-google-maps';
import TrackMap from "../../../../components/src/TrackMap";
import StylishHeader from "../../../../components/src/Stylish/StylishHeader";
import SellerSidebar from "../../../../components/src/Seller/SellerSideBar";
import StylishSidebar from "../../../../components/src/Stylish/StylishSidebar";
export const configJSON = require("../config.js");
// Customizable Area End

export default class SellersAllOrderDetailsWebPage extends SellersAllOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    orderLabelButton = (label: string, orderId: number | string) => {
        switch (label) {
            case 'in_process':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.acceptOrderInProcess, textTransform: "capitalize" }} onClick={() => this.handleInProcess(orderId)} data-testid={`handleInProcess=${orderId}`}>Ready to Ship</Button>
                    </Box>
                </>
            case 'return_under_process':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.orderstatus, width: '100px', textTransform: "capitalize", marginRight: '10px' }} data-testid="handleOpenRejectDeleteModal" onClick={() => this.handleOpenRejectDelete(orderId)}>Reject</Button>
                        <Button style={{ ...webStyle.acceptOrderInProcess, width: 'fit-content', textTransform: "capitalize" }} data-testid="handleOpenDeleteModal" onClick={() => this.handleOpenDeleteData(orderId)}>Initiate Refund</Button>
                    </Box>
                </>
            case 'processed':
            case 'shipped':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.orderstatus, textTransform: "capitalize" }} >Order Status</Button>
                    </Box>
                </>
            case 'return_in_process':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.orderstatus, textTransform: "capitalize" }} data-testid="toggleStatusDrawer" onClick={this.toggleStatusDrawer(true, orderId)}>Order Status</Button>
                        <Button disabled={this.state.orderData.return_pick_at === null} style={{ ...webStyle.orderTrackDriver, textTransform: "capitalize" }} data-testid="toggleStatusData" onClick={this.toggleStatusData(true, orderId)}>Track Driver</Button>
                    </Box>
                </>
            default:
                return null;
        }
    }
    orderLabel = (value: string) => {
        let styles = {}
        let label = ''
        switch (value) {
            case 'delivered':
                styles = webStyle.deliveredBox
                label = 'Delivered'
                break;
            case 'shipped':
                styles = webStyle.shippedBox
                label = 'Out for Delivery'
                break;
            case 'processed':
                styles = webStyle.processedBox
                label = 'Ready fro Collection'
                break;
            case 'return_request':
                styles = webStyle.return_requestBox
                label = 'Return Request'
                break;
            case 'in_process':
                styles = webStyle.in_processBox
                label = 'In Process'
                break;
            case 'rejected':
                styles = webStyle.rejectedBox
                label = 'Rejected'
                break;
            case 'return_in_process':
                styles = webStyle.return_process
                label = 'Return In Process'
                break;
            case 'new_order':
                styles = webStyle.new_orderBox
                label = 'New Order'
                break;
            case 'return_under_process':
                styles = webStyle.refund_process
                label = 'Refund In Process'
        }
        return (<Box style={styles}>{label}</Box>)
    }
    orderDetailsInProcess = () => {
        const orderDatalsAllDetails = this.state.tableAllOrder.find(
            (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
                order.attributes.order_management_order.attributes.order_number === this.state.orderId
        );
        if (!orderDatalsAllDetails) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        const { order_items, order_management_order } = orderDatalsAllDetails.attributes;
        const { delivery_addresses } = order_management_order.attributes;

        return (
            <>
                <Box style={{ margin: '30px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Box style={{ display: "flex", alignItems: 'center' }}>
                                <img src={button_arrow} style={{ cursor: "pointer" }} data-testid="contioneShopping" onClick={this.contioneShoppingAllDetails} /> <Box style={webStyle.newOrderIdInProcess} data-testid={`order_number`}>#{order_management_order.attributes.order_number}</Box> <Box>{this.orderLabel(order_items[0].attributes.status)}</Box>
                            </Box>
                            <Box style={{ color: "#9A9A9A", fontSize: "16px", fontFamily: "Lato", fontWeight: 500, marginTop: "6px" }}>
                                Total Products : {order_items.length}
                            </Box>
                        </Box>
                        <Box>
                            {this.orderLabelButton(orderDatalsAllDetails.attributes.status, orderDatalsAllDetails.id)}
                            {orderDatalsAllDetails.attributes.status === "new_order" ? (
                                <>
                                    <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} >Reject</Button>
                                    <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} >Accept Order</Button>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                    <Box>
                        <Box style={{ border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "20px" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow id="tablerow">
                                        {this.state.tableLable.map((header: string, index: number) => (
                                            <TableCell id="tableHader" style={webStyle.tableHader} key={index}>
                                                <Typography id="header" variant="h6">{header}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order_items && order_items.map((order, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ ...webStyle.tableHaderrowAllDetails, verticalAlign: 'top' }}>
                                                <Box style={{ display: "flex" }}>
                                                    <Box >
                                                        <img src={order.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : order.attributes.catalogue_variant_front_image} style={{ width: '44px', height: '44px', borderRadius: '2px', marginRight: "10px" }} />
                                                    </Box>
                                                    <Box style={{ ...webStyle.tableHaderrowAllDetails }}>{order.attributes.catalogue_name}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowAllDetails, verticalAlign: 'top' }}>{order.attributes.brand_name}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowAllDetails, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_size}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowAllDetails, verticalAlign: 'top' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box style={{ backgroundColor: order.attributes.catalogue_variant_color, width: '14px', height: '14px', borderRadius: '10px', marginRight: "8px" }} />{order.attributes.catalogue_variant_color}
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowAllDetails, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_sku}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowAllDetails, verticalAlign: 'top' }}>${order.attributes.total_price}</TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box style={webStyle.tableBox}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameAllDetails}>Store Name</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueAllDetails, textTransform: "capitalize" }}>{order_items[0].attributes.store_name}</Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameAllDetails}>Customer Name</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueAllDetails, textTransform: "capitalize" }}>{order_management_order.attributes.account}</Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameAllDetails}>Shipping Address</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueAllDetails, textTransform: "capitalize" }}>{this.getAddressAllDetails(delivery_addresses)}</Box>
                                </Grid>
                            </Grid>
                            <Box style={{ marginTop: '30px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Box style={webStyle.orderDetailsNameAllDetails}>Coupon Applied</Box>
                                        <Box style={{ ...webStyle.orderDetailsValueAllDetails, textTransform: "capitalize" }}>Fash10(-$5)</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box style={webStyle.orderDetailsNameAllDetails}>Payment Method</Box>
                                        <Box style={{ ...webStyle.orderDetailsValueAllDetails, textTransform: "capitalize" }}>{order_management_order.attributes.payment_detail?.payment_type}</Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
    allOrderDetilseHeader = () => {
        const { roleData } = this.state;
        switch (roleData) {
            case "seller":
                return <SellerHeader navigation={this.props.navigation} data-testid="allOrderDetilseHeader"/>;
            case "stylist":
                return <StylishHeader navigation={this.props.navigation} data-testid="allOrderDetilseHeader"/>;
            default:
                return <></>;
        }
    };
    allOrderDetilseSidebar = () => {
        const { roleData } = this.state;
        switch (roleData) {
            case "seller":
                return (
                    <SellerSidebar
                        navigation={this.props.navigation}
                        data-testid="allOrderDetilseHeader"
                    />
                );
            case "stylist":
                return (
                    <StylishSidebar
                        navigation={this.props.navigation}
                        data-testid="allOrderDetilseHeader"
                    />
                );
            default:
                return <></>;
        }
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const MapLoader = withScriptjs(TrackMap);
        const orderDatalsAllDetails = this.state.tableAllOrder.find(
            (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
                order.attributes.order_management_order.attributes.order_number === this.state.orderId
        );
        const rejectMrp = Number(orderDatalsAllDetails?.attributes.order_items[0].attributes.total_price).toFixed(0)
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ display: 'flex' }}>
                    {this.allOrderDetilseSidebar()}
                    <Grid container>
                        <Grid item lg={12} md={12}>
                            {this.allOrderDetilseHeader()}
                            {this.orderDetailsInProcess()}
                        </Grid>
                    </Grid>
                </Box>
                <StyledDrawerAllDetail
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleCancelInProcess}
                    closeAfterTransition
                    aria-describedby="transition-modal-description"
                    BackdropProps={{
                        timeout: 500,
                    }}
                    open={this.state.visibleAllOrder}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.visibleAllOrder} style={webStyle.febBoxAllProcess}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleCancelInProcess} src={button_close} /></Box>
                            <h2 style={{ ...webStyle.febBoxOrderInProcess, textAlign: "center" }}>Change Status</h2>
                            <Box style={{ ...webStyle.modelTextInProcess, textAlign: 'center' }}>
                                <Box style={{ paddingBottom: '20px' }}>Are you sure you want to change the order status to ready for collection?</Box>
                                <Box>Delivery partner will be assigned soon for the order pickup.</Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonfebBoxAllDetail style={{ fontSize: "14px" }} data-testid="handleCancelInProcess" onClick={this.handleCancelInProcess}>Close</StyledButtonfebBoxAllDetail>
                                <StyledButtonModelAllDetail style={{ fontSize: "14px" }} data-testid="timeDataInProcess" onClick={this.timeOrderDataInProcess}>Confirm</StyledButtonModelAllDetail>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerAllDetail>
                <Dialog
                    open={this.state.isOpenDelete}
                    maxWidth="md"
                    PaperProps={{ style: { maxWidth: "600px", height: "444px", width: "100%", borderRadius: "12px" } }}
                    onClose={this.handleCloseDeleteModal}
                >
                    <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseDeleteModal} style={{ cursor: "pointer" }} /></Box>
                    <Box style={{ margin: '0 auto', padding: '0 20px' }}>
                        <Typography style={{ ...webStyle.refTitle, textAlign: "center" }}>Initiate Refund</Typography>
                        <Typography style={{ ...webStyle.ListTitle, textAlign: "center" }}>Are you sure you want to initiate a refund for the <br /> returned product?</Typography>
                        <Box style={{ marginTop: "32px" }}>
                            <Typography style={webStyle.refundTitle}>Refund Amount</Typography>
                            <InputField
                                id="outlined-multiline-static"
                                multiline
                                inputProps={{ 'data-testid': 'changeDisc' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                    'aria-label'?: string;
                                    'data-testid'?: string;
                                    type: 'number';
                                    min: '0';
                                }}
                                style={{ border: this.state.errorMessage ? '1px solid red' : '#ddd', width: '100%' }}
                                InputProps={{ startAdornment: <InputAdornment position="end" style={{ color: '#375280' }}><span style={{ color: '#375280', fontSize: '18px', marginRight: '0px' }}>$</span></InputAdornment>, }}
                                type="number"
                                variant="outlined"
                                data-testid="refundAmount"
                                disabled={true}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    const cleanedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                    this.setState({ refundAmount: cleanedValue || rejectMrp });
                                }}
                                value={rejectMrp}
                            />
                            {this.state.errorMessage && <FormHelperText error>{this.state.errorMessage}</FormHelperText>}
                        </Box>
                        <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                            <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} data-testid="handleCloseDeleteModal" onClick={this.handleCloseDeleteModal}>Close</Button>
                            <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="handleConfirm" onClick={() => this.handleConfirmDetalis(rejectMrp)}>Confirm</Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    onClose={this.handleCloseRejectDelete}
                    PaperProps={{
                        style: {
                            height: "731px",
                            maxWidth: "600px",
                            borderRadius: "12px",
                            width: "100%",
                        },
                    }}
                    open={this.state.isOpenDeleteRejectDetalis}
                    maxWidth="md"
                >
                    <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseRejectDelete} data-testid="handleCloseRejectDeleteModal" style={{ cursor: "pointer" }} /></Box>
                    <Box style={{ margin: "0 auto" }}>
                        <Typography style={{ ...webStyle.rejTitle, textAlign: "center" }}>Reject Refund</Typography>
                        <Typography style={webStyle.ListTitle}>Reason of Rejection</Typography>
                        <CustomSelectNewOrder variant="outlined" style={{ width: '100%', backgroundColor: '#ffffff' }}>
                            <MuiSelectSelectNewOrder
                                inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                value={this.state.numberDataOrder}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                }}
                                displayEmpty
                                onChange={this.handlenumberDataOrderChangeNumber}
                                data-testid='sub-categories-selection'
                                style={webStyle.reminderName}
                            >
                                <MenuItem value="" disabled><Box style={{ ...webStyle.newShort, display: 'flex' }}>Select Reason</Box></MenuItem>
                                {this.state.selectReson.map((select: string, index: number) => (
                                    <MenuItem data-testid="sub-cat-list" key={index} style={webStyle.newShort} value={select}>{select}</MenuItem>
                                ))}
                            </MuiSelectSelectNewOrder>
                        </CustomSelectNewOrder >
                        <Box style={{ marginTop: "32px" }}>
                            <Typography style={webStyle.uploadTitle}>
                                Upload the item image for rejecting the refund
                            </Typography>

                            {this.state.combineImgDetails.length == 0 ?
                                <Box style={webStyle.documentBox as React.CSSProperties}>
                                    <Box>
                                        <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                            <img className="fieldImg" alt='civilIdPassport' src={document} data-testid="openPictureUploadUplode" onClick={() => { this.openPictureUploadAllOrder(0) }} />
                                        </Box>
                                        <Typography style={webStyle.uplodeName}>Upload Image (PNG or JPG)</Typography>
                                        <Typography style={webStyle.uplodeDescrip}>Only PNG or JPG files are accepted</Typography>
                                    </Box>
                                </Box>
                                :
                                <Box style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
                                    <img className="uploadBoximg" style={{ cursor: "pointer", width: '100%', height: '14.5rem' }} src={this.state.combineImgDetails[0] || undefined} />
                                    <Box style={{ top: '75%', position: 'absolute', right: '20px' }}><img onClick={this.handleRemoveImg} width="48px" height="48px" src={group_Group} data-testid="handleRemove" /></Box>
                                </Box>
                            }
                            <Button onClick={() => { this.openPictureUploadAllOrder(0) }} style={{ textTransform: "none", ...webStyle.uploadBtn }} data-testid="openPictureUpload" >
                                Upload Photo
                            </Button>
                        </Box>
                        <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                            <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                                <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} data-testid="Close" onClick={this.handleCloseRejectDelete}>Close</Button>
                                <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="Confirm" onClick={this.timeOrderDataReject}>Confirm</Button>
                            </Box>
                        </Box>
                    </Box>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
                <Drawer anchor="right" open={this.state.isDrawerStatusOpen} onClose={this.toggleStatusDrawer(false)}>
                    <Box style={webStyle.MapComponentContainerTreak}>
                        <Box style={webStyle.mapHeaderTextTreak}>
                            <Box style={webStyle.mapTitleContainerTextTreak}>
                                <Typography style={webStyle.trackDriveTitleTreak}>Order Status</Typography>
                                <CloseIcon role="presentation"
                                    onKeyDown={this.toggleStatusDrawer(false)} 
                                    onClick={this.toggleStatusDrawer(false)}/>
                            </Box>
                        </Box>
                        <Box style={webStyle.trackContentStatusContainer}>
                            {statusContainerReturnData(this.state.orderData)}
                        </Box>
                    </Box>
                </Drawer>

                <Drawer anchor="right" open={this.state.isDrawerStatus} onClose={this.toggleStatusData(false)}>
                    <Box style={webStyle.MapComponentContainerTreak}>
                        <Box style={webStyle.mapHeaderTextTreak}>
                            <Box style={webStyle.mapTitleContainerTextTreak}>
                                <Typography style={webStyle.trackDriveTitleTreak}>Track Driver</Typography>
                                <CloseIcon role="presentation" id="trakDriver"
                                    onClick={this.toggleStatusData(false)}
                                    onKeyDown={this.toggleStatusData(false)} />
                            </Box>
                        </Box>
                        <Box style={{ height: '449px' }}>
                            <div>
                                <MapLoader
                                    loadingElement={<div style={{ width: "200px", height: "400px" }} className="MapComponentHeight" />}
                                    googleMapURL={configJSON.mapApk}
                                    destination={this.state.longitudeData}
                                    origin={this.state.latitudeData}
                                />
                            </div>
                            <Box style={{ padding: '25px' }}>
                                <Box style={webStyle.delivedTimeTrek}>
                                    <Typography style={webStyle.driverName}>{this.state.sellerDriverName}</Typography>
                                    {this.state.orderData.estimated_delivery_time === null ? null : <Typography style={webStyle.driverName}>Delivering in : {this.state.orderData.estimated_delivery_time}</Typography>}
                                </Box>
                                <Box id="orderDataOtp" style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 0px' }}>
                                    <Box style={webStyle.otpData} id="orderDataOtp">{this.state.orderData.otp === null ? "****" : this.state.orderData.otp} - OTP</Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }} id="orderDataOtp">
                                        <Box style={{ marginRight: '12px' }} id="orderDataOtp"><img src={message} /></Box>
                                        <Box><img src={calls} /></Box>
                                    </Box>
                                </Box>
                                <Divider style={{ marginBottom: '20px' }} />
                                {statusContainerTrackDriver(this.state.orderData)}
                            </Box>
                        </Box>
                    </Box>
                </Drawer>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    delivedTimeTrek: { display: 'flex', justifyContent: 'space-between', backgroundColor: '#F4F4F4', padding: '6px 16px', borderRadius: '2px' },
    otpData: { backgroundColor: '#CCBEB1', padding: '13px 20px', color: "#FFFFFF", fontWeight: 700, fontSize: '14px', fontFamily: 'Lato', display: 'flex', alignItems: 'center' },
    driverName: { color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '14px' },
    trackDriveTitleTreak: {
        fontFamily: "Lato",
        fontSize: "24px",
        color: "#375280",
        fontWeight: 700,
    },
    
    mapTitleContainerTextTreak: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    mapHeaderTextTreak: {
        padding: 25
    },
    MapComponentContainerTreak: {
        width: 440,
        height: "100%"
    },
    uplodeDescrip: { color: "#94A3B8", fontWeight: 400, fontSize: "14px", fontFamily: "Lato" },
    uplodeName: { color: "#375280", fontWeight: 700, fontSize: "18px", fontFamily: "Lato" },
    febBoxAllProcess: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    febBoxOrderInProcess: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '0px 0px 20px 0px' },
    modelTextInProcess: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 400, marginBottom: "30px" },
    trackContentStatusContainer: {
        padding: '10px 25px 80px 25px'
    },
    acceptOrderInProcess: { backgroundColor: "#CCBEB1", width: "160px", height: "44px", borderRadius: "2px", color: "#FFFFFF", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },
    acceptOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    rejectButton: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", marginRight: "8px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    return_process: { backgroundColor: "#B55B521A", color: '#B55B52', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    return_requestBox: { backgroundColor: '#e8f1ff', color: '#1a8fea', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    refund_process: { backgroundColor: "#FB31DB1A", color: '#FB31DB', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    in_processBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    processedBox: { backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    new_orderBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    newOrderIdInProcess: { color: "#375280", fontFamily: 'Lato', fontWeight: 700, fontSize: "24px", margin: '0px 16px' },
    orderDetailsNameAllDetails: { color: "#375280", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px" },
    orderDetailsValueAllDetails: { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px", marginTop: "10px", textTransform: "capitalize" },
    tableBox: { border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "30px", padding: '20px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    tableHaderrowAllDetails: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    orderstatus: { width: "160px", height: "44px", marginRight: "10px", border: "1px solid #CCBEB1", borderRadius: "2px", color: "#375280", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },
    orderTrackDriver: { width: "160px", height: "44px", backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },

    replasePhoto: {
        top: "90%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF", width: '80%',
        color: "#375280", fontSize: '18px', fontFamily: 'Lato', padding: '15px 0px', fontWeight: 500
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
    refundTitle: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 700,
    },
    ListTitle: {
        fontSize: "18px",
        margin: "10px",
        fontFamily: "Lato",
        color: "#375280",
    },
    inputBase: {
        width: '100%',
        border: '1px solid #ddd',
        maxWidth: '520px',
        padding: '10px 12px',
        borderRadius: '4px',
        transition: 'border-color 0.3s ease',
        backgroundColor: '#F8F8F8',
        fontSize: '16px',
        '&:focus-within': {
            boxShadow: `0 0 0 2px rgba(63, 81, 181, 0.3)`,
            borderColor: '#3f51b5',
        },
    },
    close: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500,
        color: "#375280",
        height: "54px",
        width: '252px',
        backgroundColor: 'transparent',
        border: '1px solid #CCBEB1',
        boxShadow: 'none',
        marginTop: "16px",
    },
    documentBox: {
        alignItems: 'center',
        display: 'flex',
        height: '14.5rem',
        justifyContent: 'center',
        position: "relative",
        backgroundColor: '#F0F0F0',
        width: '100%',
    },
    confirm: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500,
        color: "#FFFFFF",
        height: "54px",
        width: '252px',
        marginTop: "16px",
        boxShadow: 'none',
        backgroundColor: "#CCBEB1",
    },
    refTitle: {
        marginBottom: "30px",
        fontSize: "30px",
        color: "#375280",
        fontFamily: "Lato",
        fontWeight: 500,
    },
    closeicon: { display: "flex", justifyContent: "flex-end", margin: "20px 20px 0px 0px", color: "#475569" },
    rejTitle: {
        marginBottom: "30px",
        fontSize: "30px",
        fontFamily: "Lato",
        lineHeight: "40px",
        fontWeight: 500,
        color: "#375280",
    },
    uploadTitle: {
        margin: "10px",
        fontSize: "18px",
        color: "#375280",
        fontFamily: "Lato",
        lineHeight: "24px",
        fontWeight: 400,
    },
    uploadBtn: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500,
        color: "#375280",
        height: "54px",
        width: "520px",
        marginTop: "16px",
        border: "1px solid #CCBEB1",
    },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    uploadContainer: {
        width: "520px",
        backgroundColor: "#F8F8F8",
        borderRadius: "2px",
        height: "185px",
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
        textAlign: "center",
        justifyContent: "center",
    },
    cancel: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500,
        color: "#375280",
        width: "252px",
        lineHeight: "24px",
        border: "1px solid #CCBEB1",
        height: "54px",
        backgroundColor: "transparent",
        textTransform: "none",
        boxShadow: 'none',
        marginTop: "16px",
    },
    reject: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500,
        color: "#FFFFFF",
        width: "252px",
        lineHeight: "24px",
        backgroundColor: "#CCBEB1",
        height: "54px",
        boxShadow: 'none',
        marginTop: "16px",
    },
    selectholder: {
        fontFamily: "Lato",
        color: "#9E9E9E",
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: 400,
    }
}
const StyledDrawerAllDetail = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const StyledButtonfebBoxAllDetail = styled(Button)(() => ({
    fontWeight: 500,
    textTransform: "none",
    border: "1px solid #CCBEB1",
    color: "#375280",
    borderRadius: "2px",
    width: "252px !important",
    height: "45px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButtonModelAllDetail = styled(Button)(() => ({
    backgroundColor: '#CCBEB1',
    textTransform: "none",
    height: "45px",
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const InputField = styled(TextField)({
    "& .MuiInputBase-root.Mui-disabled": {
        border: 'none'
    },
    "& .MuiInputBase-input": {
        color: "rgb(55, 82, 128) !important"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#FFFFFF"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#FFFFFF",
            borderWidth: "1px",
        }
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: 'none'
    },
    "& .MuiInputBase-root": {
        margin: "auto",
        fontSize: "16px",
        width: '100%',
        background: 'rgb(248, 248, 248)',
        borderRadius: "2px",
        "@media only screen and (max-width: 414px)": {
            fontSize: "14px"
        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderRadius: "8px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-input": {
        color: "#375280",
        "&::placeholder": {
            color: "#375280",
            "@media only screen and (max-width: 414px)": {
                fontSize: "20px"
            }
        },
        "&:not(:placeholder-shown)": {
            color: "#1E293B"
        }
    },
});

const CustomSelectNewOrder = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            },
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },

        },
    },
}))(FormControl);
const MuiSelectSelectNewOrder = styled(Select)({
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',
    },
})
// Customizable Area End