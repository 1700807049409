import React from "react";

// Customizable Area Start
import {
  styled, Grid,
  Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import OutForDeliveryControllerDetail, {
  Props,
} from "./OutForDeliveryControllerDetail.web";
import { Noimageavailablepng, backBlueArrowImg } from "./assets";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import moment from "moment";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class OutForDeliveryDetail extends OutForDeliveryControllerDetail {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  outForDeliveryHeader = () => {
    const { roleData } = this.state;
    switch (roleData) {
        case "seller":
            return <SellerHeader navigation={this.props.navigation} data-testid="outForDeliveryHeader"/>;
        case "stylist":
            return <StylishHeader navigation={this.props.navigation} data-testid="outForDeliveryHeader"/>;
        default:
            return <></>;
    }
};
outForDeliverySidebar = () => {
    const { roleData } = this.state;
    switch (roleData) {
        case "seller":
            return (
                <SellerSidebar
                    navigation={this.props.navigation}
                    className="outForDeliveryHeader"
                />
            );
        case "stylist":
            return (
                <StylishSidebar
                    navigation={this.props.navigation}
                    className="outForDeliveryHeader"
                />
            );
        default:
            return <></>;
    }
};
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const orderDetailData = this.state.SellerOrdersProcessedView.find(
        (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
            order.attributes.order_management_order.attributes.order_number === this.state.productDetailId
    );
    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xl={2} md={3} lg={2} sm={3} xs={3}>
                   {this.outForDeliverySidebar()}
                </Grid>
                <Grid item xl={10} md={9} lg={10} sm={9} xs={9}>
                    {this.outForDeliveryHeader()}
                    {this.state.isLoadingOut ? (

                        <Box style={{ ...webStyle.tabsBoxReady }}>
                            <CircularProgress style={webStyle.CircularProgressColor} />
                        </Box>

                    ) :
                        <><Box style={webStyle.readyForCollectionTableContainer}>
                            <GroupDetailBox>
                                <Box display="block" alignItems="center">
                                    <Box className="GroupDetailBoxLeft">
                                        <Box data-testid="proceedToAllOrderPage" id="proceedToAllOrderPage" onClick={this.proceedToAllOrderPage}>
                                            <img src={backBlueArrowImg} alt="" />
                                        </Box>
                                        <Typography className="itemIdText" id="order_number" data-testid="order_number">
                                            #{orderDetailData?.attributes.order_management_order.attributes.order_number}
                                        </Typography>
                                        <button className="outForDeliveryStatusTopBtn">Out for Delivery</button>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <button className="readyForStatusButton">Order Status</button>
                                </Box>
                            </GroupDetailBox>
                            <Box style={webStyle.ArrivingOutCell}> 
                                      {orderDetailData?.attributes.order_items.map((items) => (
                                        <>
                                          <Typography variant="body1" style={webStyle.ArrivingTextOut as React.CSSProperties}>{items.attributes.driver_name}</Typography>
                                        </>
                                      ))}
                                    <Typography variant="body1" style={webStyle.ArrivingTextOut as React.CSSProperties}>Delivering in: <span style={webStyle.ArrivingTextOutValue}>14 mins</span></Typography>
                                 </Box>
                            <GroupOtherDetails className="container">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Store Name
                                        </Typography>
                                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_items[0].attributes.store_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Customer Name
                                        </Typography>
                                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.account}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Delivery Date
                                        </Typography>
                                        <Typography className="detailTitleDescription">{moment(orderDetailData?.attributes.estimated_arrival_time as string).format("D MMM, YYYY")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Shipping Address
                                        </Typography>
                                        <Typography className="detailTitleDescription">
                                            {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.house_or_building_number}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.street},{orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.block} {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.area}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.city} - {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.zip_code}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Coupon Applied
                                        </Typography>
                                        <Typography className="detailTitleDescription">Fash10(-$5)</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Payment Method
                                        </Typography>
                                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.payment_detail?.payment_type}</Typography>
                                    </Grid>
                                </Grid>
                            </GroupOtherDetails>

                            <CustomStyledTable className="container">
                                <Table stickyHeader size="small" className="custom-table">
                                    <TableHead>
                                        <TableRow style={webStyle.tableHeaderBg}>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6">Product</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6">Brand</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6">Size</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6">Color</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6">Product Code</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6">Price</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderDetailData?.attributes.order_items?.map((order, index: number) => (
                                            <React.Fragment>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box style={webStyle.productDflex}>
                                                            <Box><img src={order.attributes.catalogue_variant_front_image ? order.attributes.catalogue_variant_front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                                            <Box style={{ ...webStyle.tableHaderrowReady }}>{order.attributes.catalogue_name}</Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>{order.attributes.brand_name}</TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>{order.attributes.catalogue_variant_size}</TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}><Box style={webStyle.tableVarientColorMainBoxReady}>
                                                        <Box style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: order.attributes.catalogue_variant_color }} /> <Typography>{order.attributes.catalogue_variant_color}</Typography>
                                                    </Box></TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>{order.attributes.catalogue_variant_sku}</TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>${order.attributes.total_price}</TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CustomStyledTable>
                        </Box></>
                    }
                    
                </Grid>
            </Grid>
        </ThemeProvider> 
    ); 
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
    fontFamily: "Lato",
  tableHaderReady: {
    fontSize: '16px', 
    color: "#375280",
    fontWeight: 700, 
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px", 
  },
  ArrivingTextOut: {
    color: "#375280",
    fontWeight: 400,
    fontSize: "14px",
  },
  ArrivingTextOutValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  tableHaderrowReady: {
    fontSize: '16px', 
    color: "#375280", 
    fontWeight: 400,
  },
  tableVarientColorBoxReady: {
    width: '14px', height: '14px', borderRadius: '10px', marginRight: "8px"
  },
  tableVarientColorMainBoxReady: {
    display: "flex",
    alignItems: "center"
  },
  tableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  ArrivingOutCell: {
    backgroundColor: '#F4F4F4',
    padding: '12px 16px',
    justifyContent: "space-between",
    display: "flex",
  },
  productDflex: {
    display: "flex",
    alignItems:"center"
  },
  productIMg: {
    width: '34px',
    height:'34px',
    borderRadius:'2px',
    marginRight:"10px"
  },
  ArrivingCell: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between"
  },
  ArrivingCell2: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px"
  },
  ArrivingText: {
    fontWeight: 400,
    color: "#375280",
    fontSize: "14px",
  },
  ArrivingTextValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  readyForCollectionTableContainer: {
    padding: "20px 30px"
  },
  tabsBoxReady: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  itemIdTextDescription: {
      color: "#9A9A9A",
      fontSize: "16px",
      fontWeight: 500,
      marginLeft: "32px"
  }
}
const GroupOtherDetails = styled(Box)({
      border: '1px solid #E2E8F0',
      borderRadius: "5px 5px 0px 0px",
      padding: "24px",
      margin: "25px 0px",
      "& .detailTitle": {
        color: "#375280", 
        fontSize: "16px", 
        fontWeight: 400, 
      },
      "& .detailTitleDescription": {
        color: "#94A3B8", 
        fontSize: "16px", 
        fontWeight: 400,
        marginTop: "10px"
      }
  })
const CustomStyledTable = styled(TableContainer)({
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    height: "34px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  '& .custom-table': {
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
  },
  "& .radyToCollectionStatusBtn": {
    width: "147px",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FEF3C7",
    textTransform: "none",
    borderRadius: "2px",
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#F4F4F4",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .cell': {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: '#ffffff',
  },
})
const GroupDetailBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px",
    fontFamily: "Lato",
    "& .GroupDetailBoxLeft": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .itemIdText": {
        color: "#375280", 
        fontSize: "24px", 
        fontWeight: 700,
        marginLeft: "20px"
    },
    "& .readyForStatusButton": {
        color: "rgb(55, 82, 128)",
        width: "150px",
        border: "1px solid rgb(204, 190, 177)",
        height: "44px",
        fontWeight: 500,
        fontSize: "16px",
        borderRadius: "2px",
        backgroundColor: "#ffffff",
        textTransform: "none",
      },
    "& .readyForTrackButton": {
        color: "#ffffff",
        width: "150px",
        border: "none",
        height: "44px",
        fontWeight: 500,
        fontSize: "16px",
        borderRadius: "2px",
        backgroundColor: "#CCBEB1",
        textTransform: "none",
        marginLeft: "10px"
      },
    "& .outForDeliveryStatusTopBtn": {
        color: "#6200EA",
        width: "147px",
        border: "none",
        height: "30px",
        fontWeight: 500,
        textAlign: "center",
        marginLeft: "15px",
        fontSize: "12px",
        borderRadius: "2px",
        backgroundColor: "#F0E5FF",
        textTransform: "none",
    }
  })
// Customizable Area End
