export const enOrderDetail = {
  "Welcome to your Account": "Welcome to your Account",
  "Home": "Home",
  "My Account": "My Account",
  "My Order": "My Order",
  "Order ID": "Order ID",
  "Total": "Total",
  "Order Details": "Order Details",
  "Track Order": "Track Order",
  "Purchase date": "Purchase date",
  "Order Confirmation Text": "Your order has been confirmed.",
  "Order Placed": "Order Placed",
  "Order confirmed": "Order confirmed",
  "Order processing": "Order processing",
  "Order shipped": "Order shipped",
  "Out for delivery": "Out for delivery",
  "Order Delivered": "Order Delivered",
  "Order Placed Text": "We have received your order on ",
  "Order confirmed Text": "Your order has been confirmed. ",
  "Order processing Text": "Your order is processing. ",
  "Order shipped Text": "Your order is shipped. ",
  "Out for delivery Text": "Your order is out for delivery. ",
  "Order Delivered Text": "Your order has been delivered. ",
  "Color":"Color",
  "Size":"Size",
  "Qty":"Qty",
  "Return Status": "Return Status",
  "Return placed": "Return placed",
  "Return placed Text": "We have received your order on ",
  "Return confirmed":"Return confirmed",
  "Return confirmed Text": "Your order has been confirmed on ",
  "Out For Pickup": "Out For Pickup",
  "Out For Pickup Text": "Delivery partner is on the way to collect the order on ",
  "Order Picked Up": "Order Picked Up",
  "Order Picked Up Text": "Your order has been picked on ",
  "Return Completed": "Return Completed",
  "Return Completed Text": "Delivery partner has returned the order on ",
  "Return Order": "Return Order",
  "Pickup Time": "Pickup Time",
  "Store": "Store",
  "Total Refund": "Total Refund",
  "Pickup Charge": "Pickup Charge",
  "Return Products": "Return Products",
};

export const arOrderDetail = {
  "Welcome to your Account": "مرحبا بك في حسابك",
  "Home": "الصفحة الرئيسية",
  "My Account": "حسابي",
  "My Order": "طلباتي",
  "Order ID":"معرف الطلب",
  "Total":"الإجمالي",
  "Order Details": "تفاصيل الطلب",
  "Track Order": "تتبع الطلب",
  "Purchase date": "تاريخ الشراء",
  "Order Confirmation Text": "تم تأكيد طلبك.",
  "Order Placed": "تم تقديم الطلب",
  "Order confirmed": "تم تأكيد طلبك",
  "Order processing": "معالجة الطلب",
  "Order shipped": "تم شحن الطلب",
  "Out for delivery": "في طريق التوصيل",
  "Order Delivered": "تم توصيل الطلب",
  "Order Placed Text": "لقد تلقينا طلبك في ",
  "Order confirmed Text": "تم تأكيد طلبك في",
  "Order processing Text": "جاري معالجة طلبك. ",
  "Order shipped Text": "جاري معالجة طلبك. ",
  "Out for delivery Text": "طلبك في طريق التوصيل. ",
  "Order Delivered Text": "تم توصيل طلبك. ",
  "Color":"اللـون",
  "Size":"الحجم",
  "Qty":"الكمية",
  "Return Status": "حالة الإرجاع",
  "Return placed": "تم تقديم أمر الإرجاع",
  "Return placed Text": "لقد تلقينا طلبك في ",
  "Return confirmed":"تم تأكيد الإرجاع",
  "Return confirmed Text": "تم التأكيد في ",
  "Out For Pickup": "خارج للبيك اب ",
  "Out For Pickup Text": "شريك التوصيل في طريقه لاستلام الطلب ",
  "Order Picked Up": "تم استلام الطلب",
  "Order Picked Up Text": "لقد تم اختيار طلبك ",
  "Return Completed": "تم الإرجاع ",
  "Return Completed Text": "لقد أعاد شريك التوصيل الطلب بتاريخ ",
  "Return Order": "أمر الإرجاع",
  "Pickup Time": "وقت الاستلام",
  "Store": "محل",
  "Total Refund": "إجمالي المبلغ المسترد",
  "Pickup Charge": "رسوم الالتقاط",
  "Return Products": "إرجاع المنتجات",
};
