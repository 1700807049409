import React from "react";

// Customizable Area Start
import { Typography, Box, Divider, Grid, Card, TextField, styled, InputAdornment} from "@material-ui/core";
import Header from "../../../../components/src/Header";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

import BuyerStoreProductController, {
  Props,
  configJSON
} from "./BuyerStoreProductController";
import Footer from "../../../../components/src/Footer";
import { noImage } from "../../../landingpage/src/assets";
import { search } from "../../../catalogue/src/assets";


export default class BuyerStoreProduct extends BuyerStoreProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Header navigation={this.props.navigation} />
        <Snackbar
                    open={this.state.isAlert} 
                     autoHideDuration={3000}
                    anchorOrigin={{ horizontal:  'right', vertical: 'top' }}       
                     onClose={this.oncloseAlert} 
                    data-test-id="alertTestId" 
                    >
                      <Alert severity={this.state.alertType}>
                        {this.state.alertMsg}</Alert> 
                    </Snackbar>
        <MainWrapper>
        <Box className="primaryContainer">
          <Box className="imageContainer">
            <img src={this.state.storeList?.attributes?.image} className="img"/>
          </Box>

          <Box className="secondaryContainer">
            <Box>
                <Typography className="storeName">{this.state.storeList?.attributes?.store_name}</Typography>
                <Typography className="storeDescription">{this.state.storeList?.attributes?.description}</Typography>
                <Typography className="textStyle">{this.state.storeList?.attributes?.address}</Typography>
                <Typography className="textStyle">{`${this.state.storeList?.attributes?.contact_number?.country_code} - ${this.state.storeList?.attributes?.contact_number?.phone_number}`}</Typography>
                <Typography className="email">{this.state.storeList?.attributes?.email}</Typography>
            </Box>
            <Divider className="divider"/>
            <Box className="lastContainer">
              <Typography className="storeInformation">Store Open/Close Information:</Typography>
              <Box className="weekDayContainer">
               { ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((ele, index)=> {
                const weekData = this.state.storeList?.attributes?.store_operating_hours[ele] && Object.values(this.state.storeList?.attributes?.store_operating_hours[ele]);
                return(
                <Box style={{display: "flex", width: "45%", margin: "10px 10px 8px 0px"}} key={index}>
                { weekData?.length > 0 ? (
                  <React.Fragment>
                <Typography className="weekDay">{ele}</Typography>
                <Typography className="timings">{weekData[0]} - {weekData[1]}</Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                  <Typography className="weekDay">{ele}</Typography>
                  <Typography className="timings">{0} - {0}</Typography>
                  </React.Fragment>
              )}</Box>
               )})}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box className="thirdContainer">
             <Box className="products">Products</Box>
              <CustomInput id="outlined-basic" placeholder="Search"  variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                        <img src={search} alt="" />
                      </InputAdornment>
                    ),
                  }} /> &nbsp;
                  </Box>
          <Grid container
                    spacing={0} style={webStyles.heraderText}>
                        <Grid container spacing={1} style={webStyles.gridConter}>
                            {this.state.variantsData?.length >0 &&
                                this.state.variantsData?.map((cardDetail: any, index: number) => (
                                    <Grid item xl={3} lg={4} md={4} sm={4} xs={12}  style={webStyles.trendingGrid} key={cardDetail.id}>
                                        <Card data-test-id="trendingCart" style={webStyles.cardContainer}>
                                            <Box style={{ position: 'relative' }}>
                                                <img
                                                    src={ cardDetail?.attributes?.primary_image ? cardDetail?.attributes?.primary_image : noImage }
                                                    alt={`Image ${1}`}
                                                    style={webStyles.imgTag}
                                                    onClick={this.navigateToDeatils.bind(this,{ id: Number(cardDetail?.id), screenName: "Productdescription3" })}
                                                    data-test-id = {`navigateToDetail-${index}`}
                                                />
                                                <Box style={webStyles.productIcon1 }>
                                                    <Box style={webStyles.productDiscount1}>{configJSON.offerPercentage}</Box>
                                                    <Box style={webStyles.productFavorite1} data-test-id={`handleLikeClick-${index}`} 
                                                     onClick={()=> this.handleFavouriteApi(cardDetail?.id, cardDetail?.attributes?.is_wishlist)}
                                                     >
                                                        {cardDetail.attributes?.is_wishlist ? (
                                                   <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }}/>
                                                   ):(<FavoriteBorderIcon color="secondary"  style={{ fill: 'blue' }}/>)
                                                       }
                                                       </Box>

                                                </Box>
                                            </Box>
                                            <Box style={{ padding: '24px' }}>
                                                <Box style={webStyles.productData1}>
                                                    <Box style={webStyles.productValue1}>
                                                        <Typography style={webStyles.productName1} >{cardDetail?.attributes?.name}</Typography>
                                                        <Typography style={webStyles.productDescription1} >{cardDetail?.attributes?.description}</Typography>
                                                    </Box>
                                                    <Box style={webStyles.acountMrp1}>{`$ ${cardDetail?.attributes?.primary_price}`}</Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))} 
                        </Grid>
                    </Grid>
        </Box>
        </MainWrapper>
        <Footer />
      </>
    );
    // Customizable Area End3
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  display: "flex", gap: "45px", flexDirection: "column",
  "& .lastContainer": {display: "flex", flexDirection: "column", gap: 20},
  "& .imageContainer": {height: "70vh", width: "50%", minWidth: "250px"},
  "& .primaryContainer": {display: "flex", gap: "85px",minHeight: "75vh"},
  "& .img": {height: "100%", width: "100%",objectFit: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize:"cover"},
  "& .secondaryContainer": {height: "65vh", width: "60%", padding: "20px", display: "flex", gap: 45, flexDirection: "column"},
  "& .storeName": {fontSize: "30px", fontFamily: "Lato", fontWeight: 700, color: "#375280", textTransform: "capitalize"},
  "& .storeDescription": {fontSize: "22px", fontFamily: "Lato", fontWeight: 600, color: "#375280", textTransform: "capitalize"},
  "& .textStyle": {fontSize: "20px", fontFamily: "Lato", fontWeight: 500, color: "#375280", textTransform: "capitalize"},
  "& .email": {fontSize: "20px", fontFamily: "Lato", fontWeight: 500, color: "#375280"},
  "& .divider": {color: "#BEBCBD", width: "100%"},
  "& .storeInformation": {fontFamily: "lato", fontSize: "24px", color: "#375280", fontWeight: 700},
  "& .weekDayContainer" :{display: "flex", flexWrap: "wrap"},
  "& .weekDay": {width: "50%", color: "#375280", fontFamily: "Lato", fontWeight: 500, fontSize: "20px", textTransform: "capitalize"},
  "& .timings":{width: "45%", color: "#375280", fontFamily: "Lato", fontWeight: 500, fontSize: "20px"},
  "& .thirdContainer": {display: "flex", width: "89%", justifyContent: "space-between"},
  "& .products": {color: "#375280", fontFamily: "Lato", fontSize: "32px", fontWeight: 700, width: "90%", marginLeft: "15%"}
})
const webStyles = {
  heraderText:{  background: "#FFF", justifyContent: "center", alignItems: "center" },
  gridConter:{ padding: "30px", alignItems: "center",width:"85%"},
  productIcon1: { top: '20px', left: '20px', display: "flex", justifyContent: 'space-between', right: '0',position: 'absolute' as 'absolute' },
  productFavorite1: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  acountMrp1: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', border: "1px solid #D5D5D5", padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
  productDiscount1: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  trendingGrid:{ display: 'flex', justifyContent: 'center', marginBottom: '20px' },
  productValue1: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto',whiteSpace: 'nowrap' as 'nowrap', },
  productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500,whiteSpace: 'nowrap' as 'nowrap', textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  cardContainer: {boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "340px", height: "455px",backgroundColor:"#f2efef" },
  productData1: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', 
  textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' ,whiteSpace: 'nowrap' as 'nowrap', flexDirection: 'row' as 'row' },
  productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", whiteSpace: 'nowrap' as 'nowrap',overflow: "hidden", width: '90%', fontFamily: "Lato" },
  imgTag:{
    width: "340px",
    height: "370px",
    objectFit: "cover" as "cover",
    position: 'relative' as 'relative', display: 'block', margin: 'auto'
},
};

const CustomInput = styled(TextField)({
  marginLeft: "20px",
  border: "1px solid #CBD5E1",
  borderRadius: "4px",
  width:"350px",
  minHeight: "40px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  display: "flex",
  paddingLeft: 11,
  justifyContent: "center",
  alignItems: "center",
  color: "#375280",
  '& ::placeholder':{
    color: "#43556E;",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  }
});
// Customizable Area End

