import React from "react"
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    FormControl,
    withStyles,
    Card,
    Divider,
    AccordionSummary,
    Accordion,
    FormControlLabel,
    AccordionDetails,
    Checkbox,
    WithStyles,
    InputBase,
    CheckboxProps,Snackbar
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import { Loding, image_Like, Noimageavailable ,viewLike} from "./assets"
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { styled } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import clsx from 'clsx';
import CategoriessubcategoriesControllerWeb, { Props } from './CategoriessubcategoriesPageWebController';
import Alert from '@material-ui/lab/Alert';
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class CategoriessubcategoriesWebPage extends CategoriessubcategoriesControllerWeb {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start

    renderProgress = () => {
        return <>{this.state.circularProgress && <Box style={{ width: '100%', textAlign: 'center', paddingBottom: '20px' }}><CircularProgress style={{ color: '#375280' }} /></Box>}
        {this.state.setErrors && <Box style={{ width: "100%", textAlign: 'center', fontFamily: "Lato", fontSize: "22px", color: "#375280" }}>{this.state.setErrors}</Box>}
        {this.state.showLoader && <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} data-testid="LoadMore"><Box style={{ ...webStyle.lodingButton, textAlign: 'center' }}><img src={Loding} width="24px" height="24px" />&nbsp; {this.transFilter("Load More")}</Box></Box>}
        </>
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={this.props.navigation} />
                <FullBox style={webStyle.fullPage} dir={i18n.dir()}>
                    <Grid container>
                        <Grid item xs={12} sm={3} style={{ height: '100%' }} id="scrollId">
                            <Box style={{ background: '#ffffff', height: '100%' }}>
                                <Box
                                    sx={{ px: 1, py: 2 }}
                                    style={webStyle.filterData}
                                >
                                    <Typography variant="body2" style={webStyle.allFilterFont}>
                                        {this.transFilter("Filter")}
                                    </Typography>
                                </Box>
                                <Divider style={webStyle.allDivider} />
                                <Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyle.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }} id="genderCat1">
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                    <Typography id="genderCatTypo1" style={webStyle.allFilterFont}>{this.transFilter("Gender")}</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider id="genderCatDevider1"  />
                                            <AccordionDetails id="genderCatAccoDetails1" >
                                                <Box style={{ flexDirection: 'column', padding: '0px 15px' }}>
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label={this.transFilter("Men")}
                                                        value={"Men"}
                                                        checked={this.state.gender === 'Men'}
                                                        onChange={this.handleGenderChange}
                                                        style={webStyle.radioName}
                                                        data-testid="MenRadio"
                                                    />
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label={this.transFilter("Women")}
                                                        value={"Women"}
                                                        checked={this.state.gender === 'Women'}
                                                        onChange={this.handleGenderChange}
                                                        style={webStyle.radioName}
                                                        data-testid="WomenRadio"

                                                    />
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label={this.transFilter("Kids")}
                                                        value={"Kids"}
                                                        checked={this.state.gender === 'Kids'}
                                                        onChange={this.handleGenderChange}
                                                        style={webStyle.radioName}
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyle.allDivider} />
                                <Box id="genderCatSize1" >
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyle.allSelectColor}>
                                            <Box id="genderCatBoxText1" style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                    <Typography id="genderCatsize1" style={webStyle.allFilterFont}>{this.transFilter("Size")}</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails id="genderCatAccoDetails2">
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    style={{ ...webStyle.dummeyData, flexWrap: "wrap" }}
                                                >
                                                    {this.state.dummySizes.map((size, index: number) => {
                                                        return (
                                                            <Box key={index} data-testid={`handleSizeClick-${size.attributes.id}`} onClick={() => this.handleSizeClick(size.attributes.id)} style={{ ...webStyle.dummeySize, border: !this.state.dummySizesPush.includes(size.attributes.id) ? "1px solid rgba(190, 188, 189, 1)" : "1px solid #375280" }}>{size.attributes.name}</Box>
                                                        )
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyle.allDivider} />
                                <Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyle.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                    <Typography style={webStyle.allFilterFont}>{this.transFilter("Price Range")}</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails>
                                                <Box style={{ gap: "15px", width: '100%', padding: '0px 15px', display: "flex", justifyContent: 'space-between' }}>
                                                    <InputBase
                                                        style={webStyle.priceBox}
                                                        value={`$ ${this.state.value[0]}`}
                                                        placeholder={this.transFilter("number1")}
                                                        onChange={(event) => this.handleChange(event, 0)}
                                                        data-testid
                                                        ="number1"
                                                    />
                                                    <InputBase
                                                        style={webStyle.priceBox}
                                                        value={`$ ${this.state.value[1]}`}
                                                        placeholder={this.transFilter("number2")}
                                                        onChange={(event) => this.handleChange(event, 1)}
                                                        data-testid
                                                        ="number2"
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyle.allDivider} />
                                <Box className="colore">
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyle.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                    <Typography style={webStyle.allFilterFont}>{this.transFilter("Colors")}</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails>
                                                <Box style={{ ...webStyle.colorBox, flexWrap: "wrap", gap: "14px", }}>
                                                    {this.state.filterColor.map((color, index: number) => {
                                                        return (
                                                            <>
                                                                <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }} data-testid={`handleColorClick-${color.attributes.id}`} onClick={() => this.handleColorClick(color.attributes.id)}>
                                                                    <Box style={{ width: "36.73px", height: "36.73px", backgroundColor: color.attributes.name, borderRadius: "3px", marginBottom: '18px', border: "1px solid #375280" }} />
                                                                    <Typography key={index} style={{ width: "80px", textAlign: 'center', color: "#375280" }} data-testid="colorname">{color.attributes.name}</Typography>
                                                                </Box>
                                                            </>
                                                        );
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyle.allDivider} />
                                <Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion square={false} style={webStyle.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }}>
                                                <AccordionSummary data-testid="CategoryClick" expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                    <Typography style={webStyle.allFilterFont}>{this.transFilter("Category")}</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetailsData>
                                                <Box style={{ ...webStyle.sortedBox, flexWrap: "wrap" }}>
                                                    {!this.state.sortedCategoryLoading && this.state.sortedCategory.map((category, index: number) => {
                                                        return category.attributes.name.includes(this.state.gender) && (
                                                            <>
                                                                <Accordion key={index} elevation={0} square={false} style={{ ...webStyle.allSelectColor, width: "100%" }}>
                                                                    <Box style={{ padding: '0px 15px' }} key={index} data-testid={`sub_category_${category.id}`} onClick={() => this.sub_category(category.attributes.id)}>
                                                                        <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                                            <Typography style={webStyle.allFilterFont} data-testid="categoryName">{category.attributes.name}</Typography>
                                                                        </AccordionSummary>
                                                                    </Box>
                                                                    <Divider />
                                                                    <AccordionDetailsData>
                                                                        <Box style={{ ...webStyle.sortedBox, flexWrap: "wrap" }}>
                                                                            {!this.state.subCategoryLoading && category && category.subSubData?.map((subcategory, index: number) => {
                                                                                return (
                                                                                    <>
                                                                                        <Accordion elevation={0} square={false} style={{ ...webStyle.allSelectColor, width: "100%" }}>
                                                                                            <Box style={{ padding: '0px 15px' }} key={index} data-testId={`subsubCategorey${subcategory.id}`} onClick={() => this.subsubCategorey(subcategory.attributes.id)}>
                                                                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyle.expandMoreIcon} />}>
                                                                                                    <Typography style={webStyle.allFilterFont} data-testid={`namesubcategory${subcategory.id}`}>{subcategory.attributes.name}</Typography>
                                                                                                </AccordionSummary>
                                                                                            </Box>
                                                                                            <AccordionDetailsData>
                                                                                                <Box style={{ ...webStyle.sortedBox, flexWrap: "wrap" }}>
                                                                                                    {subcategory.subSubSubData?.map((deepSubcategory, index: number) => {
                                                                                                        return (
                                                                                                            <Box style={{ padding: '0px 30px' }} key={index} >
                                                                                                                <FormControlLabel style={{ width: "335px", color: "rgba(55, 82, 128, 1)" }} control={<CustomCheckbox   inputProps={{ 'aria-label': `subsubsubsubCategorey-${deepSubcategory.id}` }} onClick={() => this.subsubsubCategorey(deepSubcategory.attributes.id)} />} label={deepSubcategory.attributes.name} />
                                                                                                            </Box>
                                                                                                        );
                                                                                                    })}
                                                                                                </Box>
                                                                                            </AccordionDetailsData>
                                                                                        </Accordion>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </Box>
                                                                    </AccordionDetailsData>
                                                                </Accordion>
                                                            </>
                                                        );
                                                    })}
                                                </Box>
                                            </AccordionDetailsData>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyle.allDivider} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Box style={{ margin: i18n.dir() === "rtl"? "0 46px 0 0": '0 0 0 46px' }}>
                                <Box style={webStyle.homeBox}>
                                    <Box style={webStyle.nextBoxhome}>{this.transFilter("Home")}</Box>&nbsp;&nbsp;<ChevronRightRoundedIcon style={{ color: "rgba(55, 82, 128, 1)", transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }} />&nbsp;&nbsp;<Box style={webStyle.WomenBox}>{this.state.gender}</Box>
                                </Box>
                                <Box style={webStyle.womenCloth}>
                                    <Box style={webStyle.womenClothing}>{this.state.categoryName}</Box>
                                    <Box style={{ display: "flex", alignItems: 'center' }}>
                                        <Grid container >
                                            <Grid item xl={12} lg={4} md={6} sm={12} xs={12}> 
                                                <Box style={{ width: "282px", display: 'flex', alignItems: 'center' }}>
                                                    <Box style={webStyle.ShortBy}>{this.transFilter("Sort by:")}</Box>&nbsp;&nbsp;
                                                    <Box>
                                                        <CustomSelect variant="outlined" style={{ width: '196px', backgroundColor: '#ffffff' }}>
                                                            <MuiSelectSelect
                                                                style={webStyle.reminderName}
                                                                value={this.state.numberData}
                                                                onChange={this.handleSingleChangeNumber}
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                                                data-testid='sub-categories-selection'
                                                                MenuProps={{
                                                                    getContentAnchorEl: null,
                                                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                                                }}
                                                                dir={i18n.dir()}
                                                            >
                                                                <MenuItem value="" dir={i18n.dir()} aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.transFilter("What’s New")}</Box></MenuItem>
                                                                {this.state.short.map((select: string, index: number) => (
                                                                    <MenuItem dir={i18n.dir()} key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                                                ))}
                                                            </MuiSelectSelect>
                                                        </CustomSelect >
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box id="scroll" style={{ height: `${this.state.componentHight}px`, overflowY: 'auto', scrollbarWidth: "none", ...(this.state.setErrors && webStyle.errorHight) }} data-testid="handleScroll">
                                    <Grid container >
                                        {this.state.products.map((product, index: number) => (<>
                                            <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={index}>
                                                <Card style={{ boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "282px", marginBottom: "20px" }} data-testid={`navigateTo${product.id}`} onClick={() => this.navigateTo({ id: product.id, props: this.props, screenName: "Productdescription3" })}>
                                                    <Box style={{ position: 'relative' }}>                                                     
                                                            <img
                                                                style={{ position: 'relative' }}
                                                                width="100%" height="370px"
                                                                src={product.attributes.primary_image === null ? Noimageavailable : product.attributes.primary_image}
                                                                alt="Product"
                                                            />
                                                        <Box style={{ ...webStyle.productIcon, position: 'absolute' }}>
                                                            <Box style={webStyle.productDiscount}>{this.transFilter("15% Off")}</Box>
                                                            <Box style={webStyle.productFavorite}><img src={product.attributes.is_wishlist ? viewLike : image_Like} width="18px" height="18px" style={{cursor:"pointer"}} data-testid={`wishlistLikeadd=${product.id}`} onClick={(event)=>this.is_wishlistData(product,event)}/></Box>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ padding: '24px' }}>
                                                        <Box style={{ ...webStyle.productData, whiteSpace: 'nowrap', flexDirection: 'row' }}>
                                                            <Box style={{ ...webStyle.productValue, whiteSpace: 'nowrap' }}>
                                                                <Typography style={{ ...webStyle.productName, whiteSpace: 'nowrap' }} >{product.attributes.name}</Typography>
                                                                <Typography style={{ ...webStyle.productDescription, whiteSpace: 'nowrap' }} >{product.attributes.description}</Typography>
                                                            </Box>
                                                            <Box style={{ ...webStyle.acountMrp, textAlign: 'center' }}>$&nbsp;{`${product.attributes.primary_price}`}</Box>
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>

                                        </>))}
                                        {this.renderProgress()}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </FullBox >
                {this.state.modalOpen && this.renderDialog()}
                <Snackbar
                    open={this.state.isAlert}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    style={{marginTop:'10px'}}
                    data-testid="oncloseAlert"
                    onClose={this.oncloseAlert}
                    dir={i18n.dir()}
                ><Alert variant="filled" severity={this.state.errorWishlist ? "warning" :"success"}>{this.state.already}</Alert></Snackbar>
                <Footer navigation={this.props.navigation}/>
            </>
            // Customizable Area End
        )
    }

}
// Customizable Area Start
const webStyle = {
    errorHight: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    radioName: { color: "rgba(55, 82, 128, 1)", fontSize: "16px", fontWeight: 700, fontFamily: "Lato" },
    productData: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
    productValue: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto' },
    lodingButton: { color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(204, 190, 177, 1)", padding: "10px, 16px, 10px, 16px", width: "234px", height: "64px", alignItems: 'center', fontSize: '24px', borderRadius: '2px', display: 'flex', justifyContent: 'center', fontWeight: 700, fontFamily: "Lato" },
    allDivider: { color: '#D5D5D5' },
    expandMoreIcon: { color: "#807D7E", fontSize: '30px' },
    allSelectColor: { backgroundColor: '#ffffff', borderRadius: '0px' },
    filterData: { display: 'flex', justifyContent: 'space-between', padding: '21px 30px' },
    fullPage: { paddingBottom: "68px", backgroundColor: '#f8f8f8' },
    lableGender: { display: "flex", alignItems: "center", gap: "15px", width: "100%", padding: '0px 15px' },
    dummeyData: { display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", width: "100%" },
    dummeySize: { cursor: "pointer", color: "#375280", borderRadius: "4px", padding: "4px", width: "61px", height: "32px", marginRight: "20px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", fontWeight: 400, fontFamily: "Lato" },
    fitData: { display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", width: "100%", color: "#375280" },
    priceBox: { border: "1px solid #cbc9ca", color: "#375280", borderRadius: "8px", padding: "5px 15px", width: "97px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "15px", fontWeight: 400, fontFamily: "Lato" },
    colorBox: { display: "flex", gap: "15px", width: "100%", padding: '0px 15px' },
    sortedBox: { display: "flex", width: "100%" },
    checkbox: { display: "flex", alignItems: "center", justifyContent: "space-between" },
    productIcon: { top: '20px', left: i18n.dir() === "ltr" ? '0':'18px', display: "flex", justifyContent: 'space-between', right:i18n.dir() === "ltr" ? '18px':'0' },
    productFavorite: { color: "#375280", margin: i18n.dir() === "rtl" ? '0 18px 0 0':'0 0 0 18px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
    productDiscount: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 5px', borderRadius: '2px', width: "max-content", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
    productDescription: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    productName: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    acountMrp: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: 'rgba(225, 222, 222, 0.43)', padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
    allFilterFont: { color: '#375280', cursor: "pointer", fontSize: '22px', fontWeight: 700, fontFamily: "Lato" },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    ShortBy: { color: "#94A3B8", fontSize: '14px', fontFamily: "Lato", fontWeight: 400, minWidth: "max-content" },
    womenClothing: { color: "#375280", fontSize: '22px', fontFamily: "Lato", fontWeight: 700 },
    womenCloth: { paddingBottom: "46px", display: 'flex', justifyContent: 'space-between' },
    WomenBox: { color: 'rgba(55, 82, 128, 1)', fontSize: '14px', fontWeight: 400, fontFamily: "Lato" },
    nextBoxhome: { color: 'rgba(71, 85, 105, 1)', fontSize: '14px', fontWeight: 400, fontFamily: "Lato" },
    homeBox: { display: 'flex', alignItems: 'center', fontFamily: "Lato", padding: '17px 0px' },
    reminderName: { backgroundColor: '#FFFFFF', height: '45px' },
}
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const FullBox = styled(Box)({
    padding: "0px 100px",
    '@media (max-width: 600px)': {
        padding: "0px 10px !important"

    },
    '@media (max-width: 1380px)': {
        padding: "0px 10px !important"
    },
    '@media  (min-width: 700px) and (max-width: 1200px)': {
        padding: "0px 10px !important"
    }
})
const AccordionDetailsData = styled(AccordionDetails)({
    padding: 0
})
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const CustomCheckbox = withStyles({
    root: {
        color: '#CCBEB1',
        '&$checked': {
            color: '#CCBEB1',
        },
    },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const styles = {
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: '1px solid #CCBEB1',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#cbbeb2',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    },
};
const StyledRadioWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
    <Radio
        className={props.classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
        icon={<span className={props.classes.icon} />}
        {...props}
    />
));
// Customizable Area End
