import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
import { Chat } from "./ClientNewOrderRequestController";
import { apiCall } from "../../../components/src/APICall";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
import * as Yup from "yup";
import { ErrorMessage, FormError, FormErrorTouched } from "../../customform/src/CreateSellerStoreController";

interface IFormValue{
  reasonRequest: string;
  reqAmount: string
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isAlert: boolean,
  message: string,
  severity: "error" | "success",
  isLoading: boolean;
  stateAccountId: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientNewPaymentRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addNewPaymentRequestAPIcallId: string = "";
  chatIdAPIcallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isAlert: false,
      message: "",
      severity: "success",
      isLoading: true,
      stateAccountId: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responsePaymentData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responsePaymentData && !responsePaymentData.errors && !responsePaymentData.message && !responsePaymentData.error) {
        this.apiClientNewPaymentSuccess(apiRequestCallId, responsePaymentData);
      } else if (responsePaymentData && (responsePaymentData.errors || responsePaymentData.message || responsePaymentData.error)) {
        this.apiClientNewPaymentFailer(responsePaymentData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if (userData.userType === "stylist") {
      const chatId = this.props.navigation.getParam("id");
      await this.handleChatData(chatId);
    }
    else history.back();
    // Customizable Area End
  }

  apiClientNewPaymentSuccess = async (
    apiRequestCallId: string,
    responseJson: Chat
  ) => {
    if (apiRequestCallId === this.chatIdAPIcallId) {
      if (responseJson.data) this.handleChatIdAPIResp(responseJson);
    } else if (apiRequestCallId === this.addNewPaymentRequestAPIcallId){
      if (responseJson.data) 
        this.setState((prevState) => ({
          ...prevState,
          isAlert: true,
          message: "Your Payment Request Added Successfully",
          severity: "success"
        }), () => setTimeout(() => {
            this.navigateToClientChat("ClientChat");
        }, 2000));
    }
  };

  apiClientNewPaymentFailer = async (responseJsonNewPayment: ErrorMessage & { message: string } & { error: string }) => {
    if (responseJsonNewPayment.message) {
      this.handleRedirectWithOutChat("You are not authorize user to perform task. or chat not Found");
    } 
    else if(responseJsonNewPayment.error){
      this.setState((prevState) => ({
        ...prevState,
        message: responseJsonNewPayment.error,
        isAlert: true,
        isLoading: false,
        severity: "error"
      }));
    }
    else if (responseJsonNewPayment.errors[0].token) {
      this.handleRedirectToHome(responseJsonNewPayment.errors[0].token)
    } 
  }

  getErrorAndHelperText = (
    fieldName: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[fieldName]) && Boolean(touched[fieldName]);
    const helperText: string = isError ? errors[fieldName] ?? "" : "";
    return { isError, helperText };
  };

  handleChatIdAPIResp = async (responseJsonNewPayment: Chat) =>{
    if(responseJsonNewPayment.current_user_role!=="stylist"){
      this.handleRedirectWithOutChat("You are not authorize user to perform task.");
    } else{
      const userData = await getStorageData("user_data", true);
      const accountExists = responseJsonNewPayment.data.relationships.accounts.data.some(account => +account.id === userData.id);
      const accountId = responseJsonNewPayment.data.relationships.accounts.data.filter(account => +account.id !== userData.id).map((account) => account.id);
      this.setState((prevState)=>({
        ...prevState,
        stateAccountId: accountId[0]
      }));
      if(!accountExists) this.handleRedirectWithOutChat("You are not authorize user to perform task.");
    }
  }

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      isLoading: false,
      message: message,
      severity: "error",
    }), () => {
      setTimeout(() => {
        this.navigateToClientChat("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  onAlertNPBoxClose = () => {
    this.setState((prevState)=>({...prevState, isAlert: false}))
  }

  handleRedirectWithOutChat = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      message: message,
      isLoading: false,
      severity: "error"
    }), () => {
      setTimeout(() => {
        this.navigateToClientChat("ClientChat");
      }, 2000);
    });
  }

  navigateToClientChat = (path: string) => {
    const useMessage = new Message(getName(MessageEnum.NavigationMessage));
    useMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    useMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (path === "ClientChat") {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        id: this.props.navigation.getParam("id"),
      });
      useMessage.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
    }
    this.send(useMessage);
  }

  handleChatData = async (chatId: number) => {
    this.chatIdAPIcallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.chatDataEndPoint}${chatId}`,
      token: await getStorageData("auth-token"),
    });
  }

  handleNewPaymentRequestSubmit = async (values: IFormValue) => {
    const formData = new FormData();
    formData.append("account_id", this.state.stateAccountId);
    formData.append("reason", values.reasonRequest.trim());
    formData.append("amount", values.reqAmount.trim());
    formData.append("chat_id", this.props.navigation.getParam("id"));

    this.addNewPaymentRequestAPIcallId = await apiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.addNewPaymentRequestEndPoint,
      token: await getStorageData("auth-token"),
      body: formData,
      isJsonStringify: false,
    });
  }
  // Customizable Area End
}
// Customizable Area Start
export const validationSchema = Yup.object({
  reasonRequest: Yup.string()
  .trim()
  .matches(/^[A-Za-z][A-Za-z0-9\s]*$/, '* Request Reason must start with a letter and only contain letters')
  .required('* Request Reason is required. Please enter the necessary information'),
  reqAmount: Yup.string()
    .matches(/^\S+$/, '* Request Amount cannot contain spaces')
    .matches(/^[1-9]\d*$/, '* Request Amount must be a positive integer and cannot start with 0')
    .required('* Request Amount is required. Please enter the necessary information'),
});
// Customizable Area End
