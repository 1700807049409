

export const enHeader = {
  "Home": "Home",
  "Men": "Men",
  "Women": "Women",
  "Kids": "Kids",
  "Stores": "Stores",
  "Stylist": "Stylist",
  "Search": "Search",
  "TopTitle": "Shop now and enjoy stylish fashion delivered straight to your doorstep at no extra cost.",
  "Sign Up": "Sign Up",
  "Sign In": "Sign In",
  "My Account": "My Account",
  "Contact Us": "Contact Us",
  "FAQs": "FAQs",
  "Log Out": "Log Out",
  "accessProfile": "Are you sure you want to log out your account?",
  "logoutConfirmation": "Are you sure you want to log out your account?",
  "Log in to view stylists.": "Log in to view stylists.",
  "View list of stylists": "View list of stylists",
  "Log In or Sign up": "Log In or Sign up",
  "All Stores": "All Stores",
  "Cancel": "Cancel",
  "Change Online Status": "Change Online Status"
}

export const arHeader = {
  "Home": "الصفحة الرئيسية",
  "Men": "الرجال",
  "Women": "النساء",
  "Kids": "أطفال",
  "Stores": "المتاجر",
  "Stylist": "المصممون",
  "Search": "البحث",
  "TopTitle": "تسوق الآن واستمتع بأنماط الأزياء الأنيقة التي ستسلم إلى عتبة بابك دون تكاليف إضافية.",
  "Sign Up": "اشتراك",
  "Sign In": "تسجيل الدخول",
  "My Account": "حسابي",
  "Contact Us": "اتصل بنا",
  "FAQs": "الأسئلة الشائعة",
  "Log Out": "تسجيل الخروج",
  "accessProfile": "تحتاج إلى تسجيل الدخول للوصول إلى الملف الشخصي",
  "logoutConfirmation": "هل أنت متأكد أنك تريد تسجيل الخروج من حسابك؟",
  "Log in to view stylists.": "قم بتسجيل الدخول لعرض المصممون.",
  "View list of stylists": "عرض قائمة المصممون",
  "Log In or Sign up": "تسجيل الدخول أو الاشتراك",
  "All Stores": "جميع المتاجر",
  "Cancel": "يلغي",
  "Change Online Status": "تغيير حالة الاتصال بالإنترنت"

}