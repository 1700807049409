import React from "react"
// Customizable Area Start
import {
    Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, Button, Divider, Grid, InputBase, Snackbar, Modal, Backdrop, Fade
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ProductdetailsWebController, { Props } from './ProductdetailsWebController';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import { cartemptypng, Noimageavailable, Deletesvg } from "./assets"
import CheckoutSteps from "./CheckoutSteps.web";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class ProductdetailsWeb extends ProductdetailsWebController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={this.props.navigation} />
                {this.state.circularProgress ?
                    <Box  data-test-id="boxContainer" style={{ width: '100%', textAlign: 'center', paddingBottom: '20px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress style={{ color: '#375280' }} />
                    </Box>
                    :
                    <Box style={{ ...webStyle.mainDiv, backgroundColor: "rgb(248, 248, 248)" }} dir={i18n.dir()}>
                        <CheckoutSteps activeBread="cart" transStepLang={this.transPD} />
                        <Box>
                            {this.state.cartItems.length > 0 ? (
                                <Box>
                                    <Table aria-label="simple table" style={{ ...webStyle.table, backgroundColor: "#f8f8f8" }}>
                                        <TableHead >
                                            <TableRow style={webStyle.rows}>
                                                <StyledTableHead style={webStyle.cells} >{this.transPD("PRODUCT DETAILS ")}( {this.state.countCart} )</StyledTableHead >
                                                <TableCell style={webStyle.cells} align="center">{this.transPD("PRICE")}</TableCell>
                                                <TableCell style={webStyle.cells} align="center">{this.transPD("QUANTITY")}</TableCell>
                                                <TableCell style={webStyle.cells} align="center">{this.transPD("SHIPPING COST")}</TableCell>
                                                <TableCell style={webStyle.cells} align="center">{this.transPD("SUBTOTAL")}</TableCell>
                                                <StyledTableHead style={{ ...webStyle.cells, minWidth: "max-content" }} align="right">{this.transPD("ACTION")}</StyledTableHead>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.state.cartItems.map((item, index) => (
                                                <StyledTableRow key={item.attributes.catalogue_name}>
                                                    <StyledTableCell>
                                                        <Box style={{ ...webStyle.productDiv, ...webStyle.rowData }}>
                                                            <div style={{ boxShadow: "none", height: "120px", width: "105px", overflow: "hidden", borderRadius: "12px" }}>
                                                                <img src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailable : item.attributes.catalogue_variant_front_image} alt="" style={webStyle.image} />
                                                            </div>
                                                            <Box style={{ ...webStyle.productDisc, alignItems: "baseline", margin: i18n.dir() === "rtl" ? "0 20px 0 0" : "0 0 0 20px", flexDirection: "column" }}>
                                                                <Typography style={webStyle.title}> {item.attributes.catalogue_name}</Typography>
                                                                <Typography style={webStyle.detail}>{this.transPD("Color: ")}{item.attributes.catalogue_variant_color}</Typography>
                                                                <Typography style={webStyle.detail}>{this.transPD("Size: ")}{this.clothingSizes[item.attributes.catalogue_variant_size]}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </StyledTableCell>
                                                    <TableCell align="center" style={webStyle.rowData}>${item.attributes.unit_price}</TableCell>
                                                    <TableCell align="center" >
                                                        <Box style={{ ...webStyle.qtyBox, ...webStyle.rowData }}>
                                                            <div style={{ cursor: "pointer" }} data-testid={`handleDecreaseQuantity-${index}`} onClick={() => this.handleDecreaseQuantity(index, this.state.cartData.id)}>-</div>
                                                            {item.attributes.quantity}
                                                            <div style={{ cursor: "pointer" }} data-testid={`handleIncreaseQuantity-${index}`} onClick={() => this.handleIncreaseQuantity(index)}>+</div>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center" style={webStyle.rowData}>${this.state.cartData.shipping_net_amt}</TableCell>
                                                    <TableCell align="center" style={webStyle.rowData}>${item.attributes.total_price}</TableCell>
                                                    <StyledTableCell align="right" style={webStyle.delete}><img src={Deletesvg} width="14.88px" height="18px" data-testid={`handleDelete-${index}`} onClick={() => this.handleOpen(item.id, this.state.cartData.id)} /></StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Box style={webStyle.leftDiv}>
                                        <Grid container >
                                            <Grid item md={6} sm={6} xs={12} lg={12}>
                                                <Box style={webStyle.atEnd}>
                                                    <Box style={{ ...webStyle.rightDiv, textAlign: "center" }}>
                                                        <div style={webStyle.rightDiv1}>
                                                            <div style={webStyle.spaceBetween}>
                                                                <Typography style={webStyle.total1}>{this.transPD("Item Total ")}</Typography>
                                                                <Typography style={webStyle.mrpColor}>${this.state.cartData.sub_total} </Typography>
                                                            </div>
                                                            <div style={{ ...webStyle.spaceBetween, margin: "10px 0" }}>
                                                                <Typography style={webStyle.total1}>{this.transPD("Shipping fee")}</Typography>
                                                                <Typography style={webStyle.mrpColor}>${this.state.cartData.shipping_net_amt} </Typography>
                                                            </div>
                                                            <div style={{ ...webStyle.spaceBetween, margin: "10px 0" }}>
                                                                <Typography style={webStyle.appliedDiscount}>{this.transPD("Voucher")}</Typography>
                                                                <Typography style={webStyle.appliedDiscount} dir="ltr">-${this.state.cartData.applied_discount}</Typography>
                                                            </div>
                                                            <Divider style={webStyle.divider} />
                                                            <div style={{ ...webStyle.spaceBetween, margin: "20px 0" }}>
                                                                <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Lato", color: "#375280" }}>{this.transPD("Total Cost")}</Typography>
                                                                <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Lato", color: "#375280" }}>${this.state.cartData.total}</Typography>
                                                            </div>
                                                        </div>
                                                        <Box style={{ padding: "10px 88px 0px 91px" }}>
                                                            <button style={{ ...webStyle.button2, textTransform: "none" }} data-testid="proceedToCheckout" onClick={this.proceedToCheckout}>{this.transPD("Proceed to Checkout")}</button>
                                                            <button style={{ ...webStyle.button1, textTransform: "none" }} onClick={this.contioneShopping}>{this.transPD("Continue Shopping")}</button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            ) : (
                                <Box style={{ ...webStyle.emptyDiv, textAlign: "center" }}>
                                    <Typography style={webStyle.cartMsg}>{this.transPD("Your cart is empty")}</Typography>
                                    <p style={webStyle.disc}>{this.transPD("Add something to make it happy!")}</p>
                                    <Box style={{ margin: '32px 0px 33px 0px' }}><img src={cartemptypng} style={{ fontSize: '200px' }} /></Box>
                                    <Button style={{ ...webStyle.ContinueBtn, textTransform: "none" }} data-testid="contioneShopping" onClick={this.contioneShopping}>{this.transPD("Continue Shopping")}</Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={this.oncloseAlert}
                    data-testid="alertTestId"
                    dir={i18n.dir()}
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.errorLabale}</Alert></Snackbar>
                <StyledDrawer
                    open={this.state.visible}
                    aria-describedby="transition-modal-description"
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    data-testid="handleCancel"
                    onClose={this.handleCancel}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }} dir={i18n.dir()}>
                            <h2 id="transition-modal-title" style={webStyle.modelText}>{this.transPD("Are you sure you want to delete this item?")}</h2>
                            <h2 id="transition-modal-title" style={webStyle.modelText}>{this.transPD("This action cannot be undone.")}</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleCancel}>{this.transPD("No")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid={`cancelOrder-${this.state.cardNumberId}`} onClick={() => this.handleDelete(this.state.cardNumberId, this.state.cardIndeId)}>{this.transPD("Yes")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <Footer navigation={this.props.navigation} />
            </>
            // Customizable Area End
        )
    }

}
// Customizable Area Start
//Add a style or MUI customized style
const webStyle = {
    modelText: { width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" },
    febBox: { borderRadius: '10px', backgroundColor: "white" },
    rejectNameNewOrder: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    appliedDiscount:{fontWeight: 700, fontSize: "16px", fontFamily: "Lato", color: "#059669"},
    mrpColor: { fontWeight: 700, fontSize: "16px", fontFamily: "Lato", color: "#375280" },
    atEnd: { display: 'flex', justifyContent: 'flex-end' },
    divider: { backgroundColor: "#BEBCBD" },
    total1: { fontWeight: 700, fontSize: "16px", fontFamily: "Lato", color: "#94A3B8" },
    spaceBetween: { display: "flex", justifyContent: "space-between", },
    rightDiv1: { padding: "10px 88px 0 91px" },
    rightDiv: { minWidth: "465px" },
    inputBox: { display: "flex", justifyContent: "space-between", border: "1px solid #CCBEB1", borderRadius: "2px", width: "375px", },
    button2: { cursor: "pointer", color: "#fff", background: "#CCBEB1", fontFamily: "Lato", width: "100%", height: "64px", border: "1px solid #CCBEB1", fontWeight: 700, fontSize: "18px", marginTop: "30px", borderRadius: "2px" },
    button1: { cursor: "pointer", color: "#375280", width: "232px", height: "44px", fontFamily: "Lato", fontWeight: 400, fontSize: "16px", border: "1px solid #CCBEB1", marginTop: "36px", borderRadius: "2px" },
    button: { color: "#fff", background: "#CCBEB1", width: "161px", height: "43px", fontWeight: 400, fontSize: "16px", border: "1px solid #CCBEB1", fontFamily: "Lato" },
    discount: { fontWeight: 700, fontSize: "24px", fontFamily: "Lato" },
    leftDiv: { padding: "29px 99px 52px 99px", background: "#F4F4F4" },
    qtyBox: { borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", margin: "70px auto", width: "100px", height: "36px", border: "1px solid #D5D5D5" },
    mainDiv: { color: "#375280", fontFamily: "Lato" },
    breadDiv: { display: "flex", justifyContent: "center", margin: "20px auto 50px", gap: "30px" },
    firstBread: { borderBottom: "1px solid #375280", fontWeight: 700, fontSize: "16px", fontFamily: "Lato", height: "22px" },
    breads: { color: "#94A3B8", fontWeight: 700, fontSize: "16px", fontFamily: "Lato" },
    dess: { borderBottom: "1px dashed #375280", width: "123px", },
    cart: { display: "flex", justifyContent: "center", fontWeight: 700, fontSize: "32px", fontFamily: "Lato" },
    cartLine: { margin: "0 auto", width: "78px", height: "4px", background: "#CCBEB1", marginBottom: "40px" },
    emptyDiv: { justifyContent: "center", marginBottom: '200px' },
    cartMsg: { fontWeight: 700, fontSize: "34px", fontFamily: "Lato" },
    disc: { fontWeight: 400, fontSize: "16px", color: "#94A3B8", fontFamily: "Lato" },
    ContinueBtn: { height: "64px", width: "302px", borderRadius: "2px", padding: "10px 16px", background: "#CCBEB1", color: "#fff", fontWeight: 700, fontSize: "18px", fontFamily: "Lato" },
    table: { width: "100%", border: "1px solid #F4F4F4", overflow: "hidden" },
    rows: { background: "#CCBEB1", height: "76px" },
    cells: { color: "#FFFFFF", fontWeight: 700, fontSize: "18px", minWidth: "150px", fontFamily: "Lato" },
    rowData: { color: "#375280", fontWeight: 500, fontSize: "18px", minWidth: "max-content", fontFamily: "Lato" },
    image: { height: "120px", width: "105px", borderRadius: "12px" },
    productDiv: { display: "flex", },
    productDisc: { display: "flex", justifyContent: "center", minWidth: "max-content" },
    title: { fontWeight: 700, fontSize: "16px", fontFamily: "Lato" },
    detail: { fontWeight: 400, fontSize: "14px", color: "#94A3B8", fontFamily: "Lato" },
    delete: { cursor: "pointer", color: "#B2A69B", paddingRight: "125px" }
}
const StyledTableHead = styled(TableCell)(() => ({
    padding: '10px 100px',
}));
const StyledTableCell = styled(TableCell)(() => ({
    padding: i18n.dir() === "rtl" ? '50px 100px 50px 0px' : '50px 0 50px 100px',
}));
const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': { border: 0 },
    '&:th': { margin: "0 500px" }
}));
const StyledDrawer = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "150px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
// Customizable Area End