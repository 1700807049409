// Customizable Area Start
import React from "react"
import {
    Box,
    Typography,
    styled,
    MenuItem, Badge, Menu, Button, Backdrop, Fade, Modal, ClickAwayListener, IconButton
} from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { setStorageData, removeStorageData, getStorageData } from "../../../framework/src/Utilities";
import { faceLogo } from "../../../blocks/email-account-registration/src/assets";
import { defaultProfileIcon, expandArrowIcon, language, notSelectedStore, notification } from "./assets";
import { logout,admin,bank,policy,usericon,faqicon } from "../../../blocks/customisableuserprofiles2/src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CurrencyLanguage from "../../../blocks/customisableuserprofiles2/src/CurrencyLanguage";
import i18n from "../i18next/i18n";
import SellerStoreStatusUpdate from "../../../blocks/customform/src/Seller/SellerStoreStatusUpdate.web";
interface S {
    openMenu: boolean;
    anchorEl: HTMLElement | null;
    open1: boolean,
    anchorElStore: HTMLElement | null;
    openStore: boolean,
    numberOrder: number | null;
    auth: string;
    name: string;
    storeName: string;
    fullname: string;
    profileImg: string | null;
    showLanguage:boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: 'success' | 'error' | 'warning' | 'info';
    selectedStoreImg: string;
}
export interface Props {
    navigation?: any;
    id?: string;
    isSellerForm?: boolean;
}
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class SellerHeader extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            openMenu: false,
            anchorEl: null,
            open1: false,
            anchorElStore: null,
            openStore: false,
            numberOrder: 0,
            auth: "",
            profileImg: null,
            name: "Hello",
            storeName: "All Stores",
            fullname: '',
            showLanguage: false,
            isAlert: false,
            alertMsg: "Language Updated Successfully!",
            alertType: "success",
            selectedStoreImg: notSelectedStore
        };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleDocumentStoreClick = this.handleDocumentStoreClick.bind(this);
        // Customizable Area End
    }

    trnsSeller = (keyS: string) => {
        return i18n.t(keyS, { ns: "header" } )
    }

    logoutNavigation = async (pageLink: string, name: string) => {
        ["orderNumber", "auth-token", "sms-token","selectedItem"].map(async (value) => {
            await removeStorageData(value);
        });
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    };

    headerRedirect = (block: string) => {
        this.props.navigation.navigate(block)
    };

    handleShowLanguage = () => {
        this.setState({
            showLanguage: !this.state.showLanguage
        })
    };

    headerNavigation = async (pageLink: string, name?: string) => {
        await setStorageData("navigationGender", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
        document.addEventListener('mousedown', this.handleDocumentClick);
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
        document.removeEventListener('mousedown', this.handleDocumentClick);
    };

    handleClickStore = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorElStore: event.currentTarget });
        document.addEventListener('mousedown', this.handleDocumentStoreClick);
    };

    handleCloseStore = () => {
        this.setState({ anchorElStore: null });
        document.removeEventListener('mousedown', this.handleDocumentStoreClick);
    };

    handleOpen1 = () => {
        this.setState({ open1: true, anchorEl: null });
    };

    handleClose1 = () => {
        this.setState({ open1: false });
    };

    handleOpenStore = () => {
        this.setState({ openStore: true, anchorElStore: null });
    };

    handleCloseStore1 = () => {
        this.setState({ openStore: false });
    };

    logout = async () => {
        ["orderNumber", "auth-token", "sms-token", "userRole"].map(async value => {
            await removeStorageData(value)
        })
        this.setState({ open1: false });
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), 'Home');
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    handleDocumentClick(event: MouseEvent) {
        if (this.state.anchorEl && !this.state?.anchorEl.contains(event.target as Node)) {
            this.handleClose();
        }
    }

    handleDocumentStoreClick(event: MouseEvent) {
        if (this.state.anchorElStore && !this.state?.anchorElStore.contains(event.target as Node)) {
            this.handleCloseStore();
        }
    }

    async componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentClick);
        document.removeEventListener('mousedown', this.handleDocumentStoreClick);
    }
    async componentDidMount() {
        const authtokenData = await getStorageData('auth-token');
        const userData = await getStorageData('user_data', true);
        this.setState({
            fullname: userData?.full_name,
        })
        this.setState({ auth: authtokenData })
        const orderNumber = await getStorageData('orderNumber')
        if (orderNumber !== null || orderNumber !== "0") {
            this.setState({ numberOrder: orderNumber });
        } else {
            await removeStorageData("orderNumber");
        }
    }
    async componentDidUpdate() {
        const orderNumber = await getStorageData('orderNumber');
        if (orderNumber !== this.state.numberOrder) {
            if (orderNumber === null || orderNumber === "0") {
                await removeStorageData("orderNumber");
                this.setState({ numberOrder: null });
            } else {
                this.setState({ numberOrder: orderNumber });
            }
        }
    }

    oncloseAlert = () => {
        this.setState({
            isAlert: false
        })
    };

    headerRedirectPolicy = (pageLink: string,tabIndex?:number) => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage),{tabIndex:tabIndex} );
        toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(toNavigate);
    };

    succesfullyLanguageUpdate = () => {
        this.setState({
            isAlert: true
        });
    }
    render() {
        // Customizable Area Start
        return (
            <>
            <Snackbar 
           autoHideDuration={3000} data-test-id="alertTestId"
           open={this.state.isAlert}
            onClose={this.oncloseAlert}
            dir={i18n.dir()}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }} >
           <Alert severity={this.state.alertType}>
            {this.state.alertMsg}</Alert> 
        </Snackbar> 
            <StyledWrapper>
                <Box className="headerMainGrid" dir={i18n.dir()}>
                    {this.props.isSellerForm ?
                        <Box
                            className="logo-container"
                            onClick={() => {
                                this.headerRedirect("LandingPage");
                            }}
                        >
                            <img src={faceLogo} alt="fashLogo" className="logo" />
                        </Box> : <SellerStoreStatusUpdate 
                                    navigation={this.props.navigation} 
                                    handleCloseStore={this.handleCloseStore}
                                    handleClickStore= {this.handleClickStore}
                                    selectedStoreImg= {this.state.selectedStoreImg}
                                    trnsSeller= {this.trnsSeller}
                                    anchorElStore= {this.state.anchorElStore} />
                    }
                    <Box
                        className="profileSection"
                    >
                        {this.props.isSellerForm ?
                            <div>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <Box aria-controls="simple-menu" className="profileBox" aria-haspopup="true" onClick={this.handleClick}>
                                        <IconButton onClick={this.handleClick} aria-controls="simple-menu" aria-haspopup="true">
                                            <img src={defaultProfileIcon} className={`${this.state.profileImg!== null ? "profile-img":""}`} alt="profile-icon" />
                                            <img src={expandArrowIcon} alt="expand-arrow" />
                                        </IconButton>
                                        <Menu style={{ marginTop: '40px', marginRight: 20 }}
                                            id="simple-menu"
                                            keepMounted
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleClose}
                                            dir={i18n.dir()}
                                        >
                                            <MenuItem style={{ color: "#375280" }} onClick={() => this.handleOpen1()}>
                                                <StyledLogoutMenu dir={i18n.dir()}>
                                                    <img src={logout} />
                                                    <span className="link-text">Logout</span>
                                                </StyledLogoutMenu>
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </ClickAwayListener>
                            </div>
                            :
                            <>
                                <CustomBadge showZero>
                                    <img src={language} alt="language"  onClick={this.handleShowLanguage}/>
                                    { this.state.showLanguage && <CurrencyLanguage id="" navigation={this.props.navigation} showLanguage={this.state.showLanguage} succesfullyLanguageUpdate={this.succesfullyLanguageUpdate}/> }
                                </CustomBadge>
                                <CustomBadge showZero>
                                    <img src={notification} alt="notification"  onClick={()=> this.props.navigation.navigate("Notifications")}/>
                                </CustomBadge>
                                <div>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Box aria-controls="simple-menu" className="profileBox" aria-haspopup="true" onClick={this.handleClick}>
                                            <IconButton onClick={this.handleClick} aria-controls="simple-menu" aria-haspopup="true">
                                                <img src={defaultProfileIcon} className={`${this.state.profileImg!== null ? "profile-img":""}`} alt="profile-icon" />
                                                <Typography className="link-name">{this.state.fullname}</Typography>
                                                <img src={expandArrowIcon} alt="expand-arrow" />
                                            </IconButton>
                                            <Custommenu
                                                style={{ marginTop: '40px', }}
                                                id="simple-menu"
                                                keepMounted
                                                anchorEl={this.state.anchorEl}
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.handleClose}
                                                dir={i18n.dir()}
                                            >
                                                <Box style={{ padding: '5px 15px' }}>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("SellerProfile") }}> <img src={usericon}/>My Profile</MenuItem>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("AdminRequest") }}> <img src={admin}/>Admin Request</MenuItem>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("SellerBankDetails") }}> <img src={bank}/>Bank Details</MenuItem>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Faqs") }}> <img src={faqicon}/>FAQs</MenuItem>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() =>{this.headerRedirectPolicy("TermsConditions", 0)}}> <img src={policy}/>Policies</MenuItem>
                                                    <MenuItem className="itemtextlogout" dir={i18n.dir()} onClick={this.handleOpen1}>{this.state.auth === null ? "" : <img src={logout}/>}{this.state.auth === null ? "Sign In" : "Logout"}</MenuItem>
                                                </Box>
                                            </Custommenu>
                                        </Box>
                                    </ClickAwayListener>
                                </div>
                            </>
                        }
                        <StyledDrawer
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open1}
                            onClose={this.handleClose1}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            dir={i18n.dir()}
                        >
                            <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
                                <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                                    <Box id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 900, marginLeft: "10px" }}>{this.state.auth === "" ? "" : this.trnsSeller("Log Out")}</Box>
                                    <h2 id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px" }}>{this.state.auth === "" ? this.trnsSeller("accessProfile") : this.trnsSeller("accessProfile")}</h2>
                                    <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                        {this.state.auth === "" ? <StyledButton1 style={{ fontSize: "18px" }} onClick={this.handleClose1}>{this.trnsSeller("Cancel")}</StyledButton1> : <StyledButton style={{ fontSize: "18px", lineHeight: "20px" }} onClick={() => this.logout()}>{this.trnsSeller("Log Out")}</StyledButton>}
                                        {this.state.auth === "" ? <StyledButtonSigein style={{ fontSize: "18px" }} onClick={() => this.headerRedirect("EmailAccountLoginBlock")}>{this.trnsSeller("Sign In")}</StyledButtonSigein> : <StyledButton1 style={{ fontSize: "18px" }} onClick={this.handleClose1}>{this.trnsSeller("Cancel")}</StyledButton1>}
                                    </div>
                                </div>
                            </Fade>
                        </StyledDrawer>
                    </Box>
                </Box>
            </StyledWrapper>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .headerMainGrid": {
        borderBottom: "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
        display: "flex",
        justifyContent: "space-between",
        "& .logo-container": {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: 150,
            height: 40,
            margin: "20px 0px 0px 20px",
        }
    },
    "& .link": {
        display: "block !important",
        margin: "0 10px 0 10px !important",
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "18px !important", textTransform: "capitalize"
    },
    "& .link-name":{
        display: "block !important",
        margin: "0 10px 0 10px !important",
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 500,
        lineHeight: "18px !important", textTransform: "capitalize"
    },
    "& .profileSection": {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    "& .profileBox": {
        margin: "5px 10px 5px 10px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        cursor: "pointer",
        fontWeight: 100,
        gap: 10,
        "& .profile-img":{
            width: 40,
            height: 40, 
            borderRadius: "50%"
        }
    },
    "@media screen and (min-width: 600px) and (max-width: 959px)": {
        "& .link": { display: "none !important" },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    },
    "@media screen and (min-width: 0px) and (max-width: 599px)": {
        "& .link": { display: "none !important" },
        "& .profileSection": { padding: "10px 5px 10px 5px" },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    }
})
const StyledDrawer = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));

const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    backgroundColor: "#d16262", color: '#fff',
    border: "1px solid #d16262",
    textTransform: "none",
    width: "150px",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "#d16262",
    },
}));

const StyledButton1 = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    color: "#375280",
    width: "150px",
    height: "45px",
    textTransform: "none",
    border: '1px solid #c6c2b9',
    '&:hover': {
        backgroundColor: "#fff"
    },
}));
const StyledButtonSigein = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    border: '1px solid #c6c2b9',
    backgroundColor: "#CCBEB1",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}));
const CustomBadge = styled(Badge)(({ theme }) => ({
    height: '64px',
    minWidth: '48px',
    '& .MuiBadge-badge': {
        backgroundColor: '#cdc1b6',
        color: '#375280',
        fontSize: '10px',
        height: '16px',
        minWidth: '16px',
        padding: '0 4px',
        borderRadius: '50%',
    },
}));

const StyledLogoutMenu = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    backgroundColor: "none",
}));

const Custommenu = styled(Menu)({
    width:'260px',
    height:'395px',
    "& .itemtext": {
        color: "#375280",
        padding: '10px',
        borderBottom: '1px solid #E2E8F0',
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '16px',
        "& img": {
            marginRight: i18n.dir() === 'rtl' ? '0px':'10px', 
            marginLeft: i18n.dir() === 'rtl' ? '0px': '10px'
        }
    },
    "& .itemtextlogout":{
        color: "#F87171",
        padding: '10px',
        fontFamily: 'Lato',
        "& img": {
            marginRight: i18n.dir() === 'rtl' ? '0px':'10px', 
            marginLeft: i18n.dir() === 'rtl' ? '0px': '10px'
        },
        fontWeight: 500,
        fontSize: '16px',
    }
})
// Customizable Area End
