import React from "react"
// Customizable Area Start
import {
    Typography, Box, Button, Grid, Modal, Fade
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PaymentHistoryWebController, { Props } from './PaymentHistoryWebController';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import Sidebar from "../../../components/src/Sidebar";
import { scroll, Vector, button_, image_mastercard } from "./assets"
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class PaymentHistoryWeb extends PaymentHistoryWebController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start
    handleImgPaymentCSS = () =>{
        return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
    }

    navPaymentHeaderMargin = () =>{
        return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
    }
    
    namePaymentCSS = () => {
        return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
    }
    
    welcomePaymentCSS = () => {
        return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={this.props.navigation} />
                <MainGridContainer container dir={i18n.dir()}>
                    <Grid item >
                        <div
                            style={{...webStyle.innerContainer11, margin: this.navPaymentHeaderMargin()}}>
                            <Typography style={webStyle.innerContainer12}>
                                {this.tranPaymentHistory("Home")}
                            </Typography>
                            <img src={Vector} style={{transform: this.handleImgPaymentCSS()}}/>
                            <Typography style={webStyle.innerContainer23}>
                            {this.tranPaymentHistory("Payment History")}
                            </Typography>
                        </div>
                        <div >
                            <Typography style={{...webStyle.innerContainer3, margin: this.namePaymentCSS()}}>
                                <img src={scroll} />
                                {this.state.fullName}
                            </Typography>
                        </div>
                        <Typography style={{...webStyle.innerContainer4, margin: this.welcomePaymentCSS()}}>
                        {this.tranPaymentHistory("Welcome to your Account")}
                        </Typography>
                        <Sidebar navigation={this.props.navigation} />
                    </Grid>
                    {this.state.payment ?
                        <Box style={{
                            marginTop: '100px', paddingInline: 20,
                            justifyContent: "flex-end"
                        }}>
                            <Box className="paymentHistoryContainer">
                                <Typography style={{ ...webStyle.headerStyle, marginBottom: "23px" }}>
                                {this.tranPaymentHistory("Payment History")}
                                </Typography>
                                <Box>
                                    <div>
                                        <div style={{ ...webStyle.cardContainer, flexDirection: 'column' }}>
                                            {this.state.paymentDetail.length === 0 ? <Box style={{ ...webStyle.tabeLable, position: "absolute", top: "100%", left: "58%", transform: "translate(-50%, -50%)", }}>{this.tranPaymentHistory("No Payment history found")}</Box> :
                                                this.state.paymentDetail.map((card) => {
                                                    const { statusColor, titleRightColor, status } = this.getStatusStyles(card.status);
                                                    const formattedDate = this.dateFormet(card.created_at);
                                                    return (
                                                        <div key={card.id} style={webStyle.divBox}>
                                                            <div style={webStyle.divsubBox}>
                                                                <div style={webStyle.mainTitle}>
                                                                    <Typography style={webStyle.titleLeft}>{card.payment_type}</Typography>
                                                                    <Typography
                                                                        style={{ ...webStyle.titleRight, textAlign: "right", color: titleRightColor }}
                                                                    >
                                                                        $ {card.amount}.00
                                                                    </Typography>
                                                                </div>
                                                                <div style={webStyle.subBox}>
                                                                    <div>
                                                                        <Typography
                                                                            style={{ ...webStyle.status, color: statusColor }}
                                                                        >
                                                                            {status}
                                                                        </Typography>
                                                                        <Typography style={webStyle.time}>{formattedDate}</Typography>
                                                                    </div>
                                                                    <div style={webStyle.btnBox}>
                                                                        <div style={{ ...webStyle.btnBox1, textAlign: "center", cursor: 'pointer' }} data-testid={`transaction=${card.order_id}`} onClick={() => this.transaction(card.order_id)}>{this.tranPaymentHistory("VIEW")}</div>
                                                                        <div style={{ ...webStyle.btnBox2, textAlign: "center", cursor: 'pointer' }} data-testid={`paymentDetailDelete=${card.id}`} onClick={() => this.handleMenuOpenCard(card.id)}>{this.tranPaymentHistory("DELETE")}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={webStyle.btnDown}>
                                                                <StyledButton data-testid={`paymentDetailDownload=${card.id}`} onClick={() => this.paymentDetailDownload(card.id)}>{this.tranPaymentHistory("Download Receipt")}</StyledButton>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }

                                        </div>
                                    </div>
                                </Box>
                            </Box>
                        </Box> :
                        <Grid item xs={12} md={6} style={{
                            paddingInline: 20, justifyContent: "flex-end", margin: i18n.dir()==="ltr" ? "100px 0px 0px 127px" :"100px 127px 0px 0px"
                        }}>
                            <div
                                style={{
                                    fontSize: "20px",
                                    fontWeight: 800,
                                    lineHeight: "33.5px",
                                    marginBottom: "29px",
                                    color: "#375280",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 8,
                                    fontFamily: 'Lato , sans-serif',
                                }}
                            >
                                <img src={button_} data-testid="getNavigationMessage" onClick={this.getNavigationMessage} style={{ cursor: "pointer", transform: this.handleImgPaymentCSS() }} />
                                {this.tranPaymentHistory("Transaction")}
                            </div>
                            <div style={{ ...webStyle.viewBox, flexDirection: "column", alignItems: "center" }}>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Transaction ID")}</Typography>
                                    <Typography style={{ ...webStyle.rightText, textAlign: "center", }}>{this.state.paymentTransaction?.charge_id}</Typography>
                                </div>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Price")}</Typography>
                                    <Typography style={{ ...webStyle.rightText, textAlign: "center", }}>$ {this.state.paymentTransaction?.amount}.00</Typography>
                                </div>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Payment Method")}</Typography>
                                    <div style={{ ...webStyle.rightText, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", gap: "3px" }}>
                                        <img src={image_mastercard} alt="Payment Method" style={webStyle.img} />&nbsp;
                                        **** **** **** *{this.state.paymentTransaction?.last_four_card_digit}
                                    </div>
                                </div>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Payment Status")}</Typography>
                                    <Typography style={{ ...webStyle.rightText, textAlign: "center", }}>{this.state.paymentTransaction?.status}</Typography>
                                </div>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Order Confirmation Number")}</Typography>
                                    <Typography style={{ ...webStyle.rightText, textAlign: "center", }}>{this.state.paymentTransaction?.order_id}</Typography>
                                </div>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Date")}</Typography>
                                    <Typography style={{ ...webStyle.rightText, textAlign: "center", }}>{this.dateFormetTransaction(this.state.transactionDateAt)}</Typography>
                                </div>
                                <div style={webStyle.viewDetail}>
                                    <Typography style={{ ...webStyle.leftText, textAlign: "center", }}>{this.tranPaymentHistory("Time")}</Typography>
                                    <Typography style={{ ...webStyle.rightText, textAlign: "center", }}>{this.dateFormetTime(this.state.transactionDateAt)}</Typography>
                                </div>
                                <div style={webStyle.btnDown}>
                                    <StyledButton1 data-testid={`paymentDetailDownload2=${this.state.downloadId}`} onClick={() => this.paymentDetailDownload(this.state.downloadId)}>{this.tranPaymentHistory("Download Receipt")}</StyledButton1>
                                </div>
                            </div>
                        </Grid>
                    }
                </MainGridContainer>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open1}
                    closeAfterTransition
                    style={{ ...webStyle.menuDrawer }}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <h2 id="transition-modal-title" style={{ width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" }}>{this.tranPaymentHistory("Delete Popup Text")}</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <Button style={{ ...webStyle.styleButtonCancel, textTransform: "none" }} data-testid="handleClose1" onClick={this.handleMenuCloseCard}>{this.tranPaymentHistory("No")}</Button>
                                <Button style={{ ...webStyle.styleButtonSave, textTransform: "none" }} data-testid="cancelOrder" onClick={this.deleteCard}>{this.tranPaymentHistory("Yes")}</Button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <Footer navigation={this.props.navigation} />
            </>
            // Customizable Area End
        )
    }

}
// Customizable Area Start
//Add a style or MUI customized style
const webStyle = {
    styleButtonSave: {
        fontWeight: 600,
        color: "#fff",
        fonSize: "14px",
        width: "150px",
        height: "45",
        backgroundColor: '#CCBEB1',
        '&:hover': {
            backgroundColor: "#CCBEB1"
        },
    },
    btnDown: { display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px" },
    btnBox: { display: "flex", justifyContent: "center", alignItems: "end", gap: "24px" },
    divsubBox: { width: "638px", height: "84px", margin: "0 auto", },
    divBox: { width: "670px", fontFamily: "", boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)", alignItems: "center", padding: "20px 16px 20px  16px", borderRadius: "2px", backgroundColor: "#FFFFFF", },
    mainTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    titleLeft: {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
        color: "#375280",
    },
    titleRight: {
        color: "#059669",
        fontWeight: 700,
    },
    subBox: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "8px",
    },
    status: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "Lato",
        color: "#059669",
    },
    time: {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "Lato",
        color: "#94A3B8",
        marginTop: "10px"
    },
    btnBox1: {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Lato",
        color: "#375280",
        lineHeight: "22px",
    },
    btnBox2: {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Lato",
        color: "#DC2626",
        lineHeight: "22px",
    },
    viewBox: {
        width: "714px",
        marginTop: "30px",
        display: "flex",
        gap: "15px",
        padding: "24px 0px",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
        backgroundColor: "#FFFFFF"
    },
    viewDetail: {
        display: "flex",
        width: "657px",
        height: "18px",
        justifyContent: "space-between",
        lineHeight: "18px",
    },
    leftText: {
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Lato",
        color: "#375280",
        lineHeight: "18px",
    },
    rightText: {
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Lato",
        color: "#94A3B8",
        lineHeight: "18px",
    },
    img: {
        width: "24px",
        height: "24px",
    },
    innerContainer12: {
        lineHeight: "22px",
        fontStyle: "normal",
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",
        padding: "4px 6px 4px 6px",
    },
    innerContainer23: {
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",
        padding: "4px 6px 4px 6px",
        fontStyle: "normal",
        lineHeight: "22px",
        color: "#475569",
        fontWeight: 700
    },
    headerStyle: { fontWeight: 800, fontSize: "20px", color: "#375280", fontFamily: "Lato" },
    tabeLable: { color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    innerContainer11: {
        gap: "10px",
        display: "flex",
        fontWeight: 400,
        alignItems: "center",
        color: "#475569",
    },
    card: {
        width: "348px",
        height: "186px",
        backgroundImage: `url(${"img2"})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingLeft: "20px"
    },
    cardContainer: {
        display: 'flex',
        gap: '16px' // Define the gap between the cards
    },
    innerContainer3: {
        alignItems: "center",
        display: "flex",
        color: "#375280",
        fontWeight: 800,
        gap: "15px",
        fontStyle: "normal",
        fontSize: "28px",
        fontFamily: "Lato , sans-serif",
    },
    innerContainer4: {
        marginBottom: "37px",
        marginLeft: "40px",
        color: "#807D7E",
        fontWeight: 400,
        lineHeight: "33.5px",
        fontStyle: "normal",
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",

    },
    menuDrawer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "none",
    },
    styleButtonCancel: {
        fontWeight: 600,
        color: "#375280",
        fonSize: "14px",
        width: "150px",
        height: "45",
        border: "1px solid #CCBEB1",
        backgroundColor: '#ffff',
        '&:hover': {
            backgroundColor: "#fff"
        },
    },
}
const MainGridContainer = styled(Grid)({
    backgroundColor: "#F8F8F8",
    "& .paymentHistoryContainer": {
    paddingInline: 20, 
    margin: (props: {dir: 'ltr' | 'rtl'}) => (props.dir === 'ltr' ? "0px 0px 65px 127px" :"0px 127px 65px 0px"),
    "@media only screen and (max-width: 960px)": {
        margin: (props: {dir: 'ltr' | 'rtl'}) => (props.dir === 'ltr' ?"0px 0px 65px 10px" :"0px 10px 65px 0px"),
      }
    }
  });
const StyledButton = styled(Button)(() => ({
    fontWeight: 800,
    color: "#FFFFFF",
    border: "0px 0px 1px 0px",
    textTransform: "none",
    width: "638px",
    height: "54px",
    padding: "20px 16px 20px 16px",
    borderRadius: "2px",
    backgroundColor: "#CCBEB1 ",
    fontSize: '18px',
    "&:hover": {
        backgroundColor: "CCBEB1",
    },
}));
const StyledButton1 = styled(Button)(() => ({
    fontWeight: 800,
    color: "#FFFFFF",
    border: "0px 0px 1px 0px",
    textTransform: "none",
    width: "667px",
    height: "48px",
    padding: "20px 16px",
    borderRadius: "2px",
    backgroundColor: "#CCBEB1 ",
    fontSize: '18px',
    "&:hover": {
        backgroundColor: "CCBEB1",
    },
}));
// Customizable Area End