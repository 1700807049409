import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop, Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, scroll } from "../src/assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { CountrySuccess } from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ReactCountryFlag from 'react-country-flag';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OtpInput from "react-otp-input";
import i18n from "../../../components/src/i18next/i18n";


// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";

export default class Customisableuserprofiles2 extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlePWDImgCSS = () => {
    return i18n.dir()==='ltr' ? {right: 10} : {left: 10 }
  }
  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} />
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} dir={i18n.dir()} container>
          <Grid item >
            <Box
              dir={i18n.dir()}
              style={{margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "27px 40px 0 0",...webStyle.innerContainer, }}>
              <Typography style={webStyle.innerContainer1} >
              {this.transProfile(configJSON.HomeMsg)}
              </Typography>
              <img src={Vector} style={{ transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              <Typography style={{...webStyle.innerContainer2, fontWeight: 700}}>
              {this.transProfile(configJSON.ProfileMsg)}
              </Typography>
            </Box>
            <div >
              <Typography style={{margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "31px 40px 0 0",...webStyle.innerContainer3}} dir={i18n.dir()}>
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={{margin: i18n.dir() === "ltr" ? "0 0 40px 37px" : "0 40px 37px 0",...webStyle.innerContainer4}} dir={i18n.dir()}>
            {this.transProfile(configJSON.WelcomeAccountMsg)}
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xs={12} md={8} style={{ paddingInline: 55, marginTop: '90px' }}>
            <Typography style={webStyle.mainHeading}>
            {this.transProfileMain(configJSON.MyprofileMsg)}
            </Typography>
            <MainContainer style={webStyle.innerContainer6} dir={i18n.dir()}>
              <form data-test-id="formSubmitProfile" >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.Fname)}</Typography>
                    <TextField
                      data-test-id="FristNameId"
                      fullWidth
                      variant="outlined"
                      value={this.state.firstName}
                      onChange={(event)=> this.handleFirst(event.target.value)}
                      className={this.generateClassName(this.state.firstNameError, "inputFeild2", "inputFeild2Error2")}
                      helperText={this.handleHelperTextFirstname()}
                      error={true}
                      placeholder={this.transProfileMain(configJSON.PlaceholderFirst)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.Lname)}</Typography>
                    <TextField
                      data-test-id="LastNameId"
                      fullWidth
                      variant="outlined"
                      value={this.state.lastName}
                      onChange={(event)=> this.handleLast(event.target.value)}
                      helperText={this.handleHelperText(this.state.errorsMessage.lastNameMessage)}
                      error={true}
                      className={this.generateClassName(this.state.lastNameError, "inputFeild2", "inputFeild2Error2")}
                      placeholder={this.transProfileMain(configJSON.Placeholderlast)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>


                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.Email)}</Typography>
                    <TextField
                      data-test-id="email"
                      fullWidth
                      variant="outlined"
                      value={this.state.email}
                      onChange={(event)=> this.handleEmail(event.target.value)}
                      helperText={this.handleHelperLastText(this.state.errorsMessage.emailMessage)}
                      error={true}
                      className={this.generateClassName(this.state.errorEmail, "inputFeild2", "inputFeild2Error2")}
                      placeholder={this.transProfileMain(configJSON.PlaceholderEmail)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.Phone)}</Typography>
                    <Box style={{
                      display: "flex"
                    }}>
                      <CustomDropDown>
                        <DropDownHeader data-test-id="toggleCountryId"
                          onClick={this.toggleDropdown}
                          className={this.state.errorsMessage.errorPhoneNumber ? "isActive" : ""}>
                          {this.state.selectedCountry ? (
                            <SelectedCountry>
                              <Typography>
                                <ReactCountryFlag countryCode={this.state.selectedCountry.country_code} svg />
                                {this.state.selectedCountry.numeric_code}
                              </Typography>
                            </SelectedCountry>
                          ) : (
                            <Typography style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px"
                            }}>
                              <ReactCountryFlag countryCode="KW" svg />
                              {this.state.isCountryData}
                            </Typography>
                          )}
                          <span>{this.state.dropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                        </DropDownHeader>
                        {this.state.dropdownOpen && (
                          <DropDownOptions>
                            {this.state.contryCodeResp?.map((country: CountrySuccess, index: number) => (
                              <OptionsValue
                                data-test-id={`countrySelectId-${index}`}
                                key={country.country_code}
                                onClick={() => this.handleCountrySelect(country, country.numeric_code)}
                              >
                                <Typography style={{
                                  color: '#375280',
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "3px"
                                }}>
                                  <ReactCountryFlag countryCode={country.country_code} svg />
                                  {country.country_full_name} ({country.numeric_code})
                                </Typography>
                              </OptionsValue>
                            ))}
                          </DropDownOptions>
                        )}
                      </CustomDropDown>

                      <MobileTextField
                        data-test-id="phoneNumberTextInput"
                        variant="outlined"
                        onChange={(event)=>this.handleNumber(event.target.value)}
                        value={this.state.phoneNumber}
                        helperText={this.handlePhoneNumber()}
                        error={true}
                        className={this.generateClassName(this.state.phoneNumberError, "inputFeild2", "inputFeild2Error2")}
                        placeholder={this.transProfileMain(configJSON.PlaceholderPhone)}
                        inputProps={{ maxLength: 12 }}
                        InputProps={{
                          style: {
                            gap: "4px",
                            border: "none",
                            borderRadius: "0px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <Box style={webStyle.savebuttn} data-test-id = "handleSaveProfile" onClick={this.handleSaveClick} >
                    <Typography style={webStyle.saveText}>{this.transProfileMain(configJSON.SaveChangeMsg)}</Typography>
                  </Box>
                </Grid>

                <Typography style={webStyle.changeText}>{this.transProfileMain(configJSON.ChangePasswordMSG)}</Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.OldPass)}</Typography>
                    <Box style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative"
                    }}>
                      <TextField
                        data-test-id="oldPassword"
                        fullWidth
                        type={this.state.oldPasswordIcon ? "text" : "password"}
                        value={this.state.oldPassword}
                        variant="outlined"
                        helperText={this.handleOldPasswordText()}
                        error={true}
                        className={this.generateClassName(this.state.errorsMessage.errorOldPassword, "inputFeild2", "inputFeild2Error2")}
                        onChange={(event)=> this.handleOldPassword(event.target.value)}
                        placeholder={this.transProfileMain(configJSON.PlaceholderOldPasss)}
                      />
                      <Box style={{
                        position: "absolute",
                        cursor: "pointer",
                        top: "14px",
                        ...this.handlePWDImgCSS()
                      }} onClick={() => {
                        this.setState({
                          oldPasswordIcon: !this.state.oldPasswordIcon
                        })
                      }}
                      data-test-id="oldIconChange"
                      >
                        {
                          this.state.oldPasswordIcon ? <Visibility style={webStyle.iconstyle} /> : <VisibilityOff style={webStyle.iconstyle} />
                        }
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.NewPass)}</Typography>
                    <Box style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative"
                    }}>
                      <TextField
                        data-test-id="newPassword"
                        fullWidth
                        type={this.state.newPasswordIcon ? "text" : "password"}
                        variant="outlined"
                        value={this.state.newPassword}
                        helperText={this.state.errorsMessage.newPasswordMessage ? this.state.errorsMessage.newPasswordMessage : ""}
                        error={true}
                        className={this.generateClassName(this.state.errorsMessage.errorNewPassword, "inputFeild2", "inputFeild2Error2")}
                        onChange={(event)=> this.handleNewPassword(event.target.value)}
                        placeholder={this.transProfileMain(configJSON.PlaceholderNewPass)}
                      />
                      <Box style={{
                        position: "absolute",
                        cursor: "pointer",
                        top: "14px",
                        ...this.handlePWDImgCSS()
                      }}
                        onClick={() => {
                          this.setState({
                            newPasswordIcon: !this.state.newPasswordIcon
                          })
                        }}
                        data-test-id = "newPasswordViewClick"
                      >
                        {
                          this.state.newPasswordIcon ? <Visibility style={webStyle.iconstyle} /> : <VisibilityOff style={webStyle.iconstyle} />
                        }
                      </Box>
                    </Box>

                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography style={webStyle.inputHeadingBox1}>{this.transProfileMain(configJSON.ReNewPass)}</Typography>
                    <Box style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative"
                    }}>
                      <TextField
                        data-test-id="reNewPassword"
                        fullWidth
                        type={this.state.ReNewPasswordIcon ? "text" : "password"}
                        variant="outlined"
                        value={this.state.rePassword}
                        helperText={this.state.errorsMessage.rePasswordMessage ? this.state.errorsMessage.rePasswordMessage : ""}
                        error={true}
                        className={this.generateClassName(this.state.errorsMessage.errorRePassword, "inputFeild2", "inputFeild2Error2")}
                        onChange={(event)=> this.handleRePassword(event.target.value)}
                        placeholder={this.transProfileMain(configJSON.PlaceholderReNewPass)}
                      />

                      <Box style={{
                        position: "absolute",
                        cursor: "pointer",
                        top: "14px",
                        ...this.handlePWDImgCSS()
                      }} onClick={() => this.setState({
                        ReNewPasswordIcon: !this.state.ReNewPasswordIcon
                      })}
                      data-test-id="rePasswordView">
                        {
                          this.state.ReNewPasswordIcon ? <Visibility style={webStyle.iconstyle} /> : <VisibilityOff style={webStyle.iconstyle} />
                        }
                      </Box>

                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box style={webStyle.changebuttn} data-test-id="changePasswordApi" onClick={this.handleChangePassword} >
                      <Typography style={webStyle.saveText}>{this.transProfileMain(configJSON.ChangePasswordMSG)}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </MainContainer>
          </Grid>
        </Grid>

        <StyledModal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.emailOtpVerify} 
                            onClose={this.handleEmailAndOtpVerifyClose} 
                            closeAfterTransition
                            data-test-id="transition" 
                            BackdropComponent={Backdrop}
                            BackdropProps={{ 
                                timeout: 500, 
                            }}
                        > 
                            <Fade in={this.state.emailOtpVerify} style={{ borderRadius: '10px', maxWidth: "390px", backgroundColor: "white" }}>
                                <MainContainerDialog >  
                                <Box className={"formBox"}> 
                             <Box data-test-id="formSubmit" 
                              style={{ width: "100%" }}>
                                <Box className={"headBox"}> 
                                 <Typography className={"headText"}>{this.transProfileMain(configJSON.otpVerification)}</Typography>
                                </Box> 
                                 <Typography data-test-id="maskEmail" className={"headSubText"}>
                                    {this.handleOption() ? 
                                        <> 
                                              {this.transProfileMain(configJSON.sentTo)} <br />  {this.transProfileMain(configJSON.emailText)} 
                                            {this.maskEmail()} 
                                        </> : 
                                        <>  
                                               { this.transProfileMain(configJSON.sentTo)} <br />{this.transProfileMain(configJSON.smsText)}   
                                            {this.maskPhone()}  
                                           </>  
                                      } 
                                   </Typography> 
                                <Box 
                                className="otpBox1"> 
                                     <OtpContainer>
                                     <OtpInput
                                            onChange={this.handleOTPChange}
                                            value={this.state.otpInput}
                                            numInputs={4}
                                            containerStyle={{ gap: "15px" }}
                                            data-test-id="otpInputID"
                                            inputStyle={{
                                              height: "50px",
                                              width: "50px",
                                              background: "#F0F0F0",
                                              margin: "0px 3px",
                                              border: "none",
                                              fontSize: "25px",
                                              textAlign: "center",
                                              color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)",
                                              outline: "none"
                                            }}
                                            renderInput={(props) => (<input {...props}
                                                type="text"
                                                onKeyDown={(event) => {this.handlekeyDown(event)}} />
                                            )}
                /> 
                                        {this.state.errorOtpMsg && (<Box className={"otpMsgBox"}> {this.state.errorOtpMsg}</Box> 
                                        )} 
                                     </OtpContainer>
                                  </Box>
                                 <Box className={"textHeading1"}>
                                    <Typography className={"bottomText"} align="center"><Typography className={"reciveText"}>{this.transProfileMain(configJSON.reciveCodeText)} </Typography>
                                         <Typography data-test-id="resend-otp"  onClick={this.handeResentOTPApiCall}
                                              >&nbsp;{this.transProfileMain(configJSON.resendCode)}
                                        </Typography>
                                     </Typography>
                                    <Button
                                        variant="contained"
                                        className={"LoginUserButton"} 
                                        onClick={()=> this.handleSubmit()} 
                                         disableElevation
                                        data-test-id="handleSaveSubmit" 
                                    >
                                         {this.transProfileMain(configJSON.verifyAccount)} 
                                    </Button> 
                                  </Box>
                             </Box>
                         </Box>
                                </MainContainerDialog>
 
                            </Fade>
                         </StyledModal>
 
        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "none",
}));

const OtpContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 5px 10px",
});

const MainContainerDialog = styled(Box)({
  "& .formBox": {
      display: "flex",
      padding: "20px",
      alignItems: "center",
      justifyContent: "center",
      gap: 20,
      flexDirection: "column",
  },
  "& .LoginUserButton": {
      width: "100% ",
      borderRadius: "0px",
      color: "white ",
      backgroundColor: "var(--Primary-Purple-500, #CCBEB1) ",
      gap: "8px",
      height: "56px ",
      textTransform: 'none',
      fontSize: "19px",
      fontWeight: 600,
      margin: "15px 0px 0px 0px"
  },
  "& .reciveText": {
      color: "var(--Primary-Purple-500, #CCBEB1)"
  },
  "& .textHeading1": {
      textAlign: "center"
  },
  "& .bottomText": {
      display: "flex",
      color: "#375280 ",
      cursor: "pointer",
      fontFamily: "Lato,sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
  },
  "& .otpMsgBox": {
      color: "red",
      margin: "11px 12% 0px 0px",
      width: "100%",
      fontWeight: 300,
      fontFamily: "Avenir, sans-serif"
  },
  "& .headBox":{
      width:"100%",
      textAlign:"center"
  },
  "& .headText": {
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      textOverflow: "ellipsis",
      src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      fontFamily: "Avenir,sans-serif",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "26px",
      alignSelf: "center",
  },
  "& .headSubText": {
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      textOverflow: "ellipsis",
      src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      fontFamily: "Avenir,sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
      alignSelf: "center",
  },
  "& .otpBox1": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 15,
      padding: "10px 0px",
  },
});

const MainContainer = styled(Box)({
  "& .MuiFormHelperText-root.Mui-error":{
    display: "flex"
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    }
  },
});

const CustomDropDown = styled(Box)({
  position: "relative",
  width: "180px",
  cursor: "pointer"
});

const DropDownHeader = styled(Box)({
  padding: "10px",
  gap: "15px",
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  background: "#F0F0F0",
  height: "36px",
  color: "#375280",
  fontSize: "18px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const MobileTextField = styled(TextField)({
  width: "100%",
  marginLeft: "20px",
  gap: "4px",
  border: "none",
  borderRadius: "none",
  "& .MuiOutlinedInput-root": {
    border: "none"
  }
});

const SelectedCountry = styled(Box)({
  display: "flex",
  alignItems: "center"
});

const DropDownOptions = styled(Box)({
  left: 0,
  width: "100%",
  border: "1px solid #ccc",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  backgroundColor: "#fff",
  position: "absolute",
  zIndex: 1,
  height: "60vh",
  overflow: "auto"
});

const OptionsValue = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const webStyle = {
  plainText: {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    }
  } as React.CSSProperties,
  errorText: {
    "& .MuiFormControl-root": {
      border: "1px solid #F0F0F0",
      borderRadius: "2px",
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
  } as React.CSSProperties,
  iconstyle: {
    color: "#000000",
    opacity: "0.5",
  },
  changeText: {
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    color: "#375280",
    marginTop: '25px'
  },
  saveText: {
    fontFamily: 'Lato',
    fontSize: "20px",
    fontWeight: 800,
  },
  changebuttn: {
    height: "56px",
    borderRadius: "3px",
    backgroundColor: "rgb(204, 190, 177)",
    display: "flex",
    marginTop: "2.8rem",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    cursor: "pointer",
  },
  savebuttn: {
    width: "475px",
    height: "56px",
    borderRadius: "3px",
    backgroundColor: "rgb(204, 190, 177)",
    display: "flex",
    marginTop: "2.8rem",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    cursor: "pointer",

  },
  emailContainer: {
    width: "176px",
    height: "22px",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "16px",
    color: "#375280"
  },
  nameContainer: {
    width: "76px",
    height: "22px",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "16px",
    color: "#375280"
  },
  leftContainer: {
    width: "967px",
    height: "642px",
    position: "absolute" as "absolute",
    top: "198px",
    left: "405px",
  },
  mainHeading: {
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    color: "#375280",
    position: "relative",
    marginBottom: '35px'
  } as React.CSSProperties,

  secondMainHeading: {
    width: "183px",
    height: "31px",
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    color: "#375280",
    position: "relative",
    marginBottom: "16px"
  } as React.CSSProperties,

  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "40px",
    marginTop: "27px",
    fontFamily: "Lato",
  },
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  innerContainer2: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569"
  },
  innerContainer3: {
    fontFamily: "Lato",
    fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 800,
    color: "#375280",
    marginLeft: "40px",
    marginTop: "31px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    textTransform: "capitalize"
  } as React.CSSProperties,
  innerContainer4: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 400,
    color: "#807D7E",
    marginLeft: "40px",
    marginBottom: "37px"

  },
  innerContainer5: {
    borderRadius: "50%",
    backgroundColor: "#CCBEB121",
    width: "170px",
    height: "170px",
    alignItems: "center",

    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  innerContainer6: {
    boxShadow: "2px 2px 4px 0px #0000000D, -2px -2px 4px 0px #0000000D",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    padding: '30px'
  } as React.CSSProperties,

  inputHeadingBox1: {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px 0",
    textOverflow: "ellipsis",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },



};
// Customizable Area End
