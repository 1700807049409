export const enFooter = {
  "HELP": "HELP",
  "SHOP": "SHOP",
  "Woman": "Woman",
  "Man": "Man",
  "Kids": "Kids",
  "Store": "Store",
  "Stylist's": "Stylist's",
  "Customer Service": "Customer Service",
  "FAQ": "FAQ",
  "Terms Of Use": "Terms Of Use",
  "Privacy Policy": "Privacy Policy",
  "Shipping Policy": "Shipping Policy",
  "Return Policy": "Return Policy",
  "About Fash": "About Fash",
  "Fash": "Fash"
};

export const arFooter = {
    "HELP": "يساعد",
    "SHOP": "محل",
    "Woman": "امرأة",
    "Man": "رجل",
    "Kids": "أطفال",
    "Store": "محل",
    "Stylist's": "المصمم",
    "Customer Service": "خدمة العملاء",
    "FAQ": "التعليمات",
    "Terms Of Use": "شروط الاستخدام",
    "Privacy Policy": "سياسة الخصوصية",
    "Shipping Policy": "سياسة الشحن",
    "Return Policy": "سياسة العائدات",
    "About Fash": "عن فاش",
    "Fash": "فاش"
  };