import React from "react";
// Customizable Area Start
import { Paper, Typography, Grid,Box ,Button,Card} from "@material-ui/core";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import LandingPageController, { Props ,configJSON} from "./LandingPageController";
import { createTheme } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { ThemeProvider } from "react-native-elements";
import CustomSlider from "../../../components/src/CustomSlider.web";
import CustomCarousel from "../../../components/src/CustomCarousel.web";
import {noImage } from "./assets";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AuthenticateUser from "../../../components/src/AuthenticateUser";

const theme = createTheme({
  typography: {
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px"
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "Lato"
  }
});

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDialog = () => {
    return (
       <AuthenticateUser 
            auth={this.state.token} 
            title="Log in to view your Wishlist."
            description="Save your favorite items for later and easily find them when you're ready to shop"
            btnText="Log In or Sign up"
            navigation={this.props.navigation}
            onClose={this.closeModal}
        />
    )
}
closeModal = () => {
  this.setState({modalOpen: false});
};
// istanbul ignore next
getStylesByIndex = (index: number) => {
  let imageStyle;
  let gridItemStyle = {};

  if (index === 0 || index === 2) {
    imageStyle = webStyles.stylistImage;
    gridItemStyle = { gridRow: "span 2" };
  } else if (index === 1) {
    imageStyle = webStyles.stylistImage2;
  } else {
    imageStyle = {
      width: "317.37px",
      height: "338px",
      objectFit: "cover",
    };
  }

  return { imageStyle, gridItemStyle };
}

  // Customizable Area End

  render() {
    return (
      <div>
        <Header navigation={this.props.navigation}/>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
          dir={i18n.dir()}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        {this.state.modalOpen && this.renderDialog()}
        <ThemeProvider theme={theme}>
          <div style={{ background: "#f2efef" }}>
            <Grid
              dir={i18n.dir()}
              container
              direction="column"
              spacing={2}
              style={webStyles.headContentTextWrapper}
            >
              <Grid item style={{ justifyContent: "center" }}>
                <Typography align="center" style={webStyles.headContentText}>
                {this.state.landingPageTitleList?.attributes.title}
                </Typography>
                <Typography align="center" style={webStyles.headContentText1}>
                  {this.state.landingPageTitleList?.attributes.subtitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid dir={i18n.dir()}>
              <CustomCarousel data={this.state.carouselList} />
            </Grid>
            {this.state.recommendationDataList && this.state.recommendationDataList.length > 0 &&
              <>
                <Grid
                  dir={i18n.dir()}
                  style={webStyles.recommendationOuterGrid}
                >
                  <Typography style={webStyles.headContentText2}>
                    {this.transLand(configJSON.recommendedHeadingText)}
                  </Typography>
                  <div style={webStyles.divBroder}>
                    <p style={webStyles.textBroder} />
                  </div>
                  <CustomSlider data={this.state.recommendationDataList} navigateToDeatilPage={this.navigateToDeatilPage}  handleLikeApiCall={this.handleFavouriteApi} calledFrom="recommendation"/>
                </Grid>
                <div style={webStyles.divBroder}>
                  <p style={webStyles.borderStyle} />
                </div>
              </>}
            <Grid
              dir={i18n.dir()}
              style={{ paddingTop: this.state.recommendationDataList && this.state.recommendationDataList.length > 0 ? "0px" : "40px",
              ...webStyles.recommendationGrid}}
            >
            {this.state.stylistServiceDataList && this.state.stylistServiceDataList.length > 0 &&
            <>
              <Typography style={webStyles.headContentText3}>
                {this.transLand(configJSON.stylistHeadingText)}
              </Typography>
              <div style={webStyles.divBroder}>
                <p style={webStyles.textBroder} />
              </div>
              <Grid >
                <Grid container spacing={2} style={webStyles.stylistGrid}>
                    {this.state.stylistServiceDataList.map((item, index) => {
                      const { imageStyle } = this.getStylesByIndex(index);

                      return (
                        <Grid
                          item
                          lg={4}
                          xl={4}
                          xs={6}
                          key={item.id}
                          style={index === 0 || index === 2 ? { gridRow: "span 2" } : {}}
                        >
                          <Paper className="paperContainer">
                            <img
                              src={item?.image?.url}
                              alt={`Image ${index + 1}`}
                              style={imageStyle as React.CSSProperties}
                            />
                          </Paper>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Button variant="contained" style={webStyles.stylistBtn} onClick={this.handleHiredStylist}>{this.transLand("Explore Our Stylists")}</Button>
              </>
            }
            </Grid>
            {this.state.trendgingDataList && this.state.trendgingDataList.length > 0 &&
            <Grid
              dir={i18n.dir()}
              style={webStyles.trendingOuterGrid}
            >
              <Typography style={webStyles.headContentText4}>
                {this.transLand(configJSON.trendingHeadingText)}
              </Typography>
              <div style={webStyles.divBroder}>
                <p style={webStyles.textBroder} />
              </div>
                <Grid container spacing={4}>
                  {this.state.trendgingDataList && this.state.trendgingDataList.map((cardDetail, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                      <Card data-testId="cartDetails" style={webStyles.trendingCard}>
                       <Box style={{ position: 'relative' }}>
                      <img
                       src={cardDetail?.attributes?.primary_image === null ? noImage : cardDetail?.attributes?.primary_image}
                        alt={`Image ${index + 1}`}
                        style={webStyles.trendingImage}
                        data-test-id = "navigateToDetail"
                        onClick={this.navigateToDeatilPage.bind(this,{ id: cardDetail.id,screenName: "Productdescription3" })}
                      />
                      <Box style={{ ...webStyles.productIcon, position: 'absolute' }}>
                      <Box style={webStyles.productDiscount}>{configJSON.offerPercentage}</Box>
                      <Box style={webStyles.productFavorite} data-test-id="handleLikeClick" onClick={()=>this.handleFavouriteApi(cardDetail?.id, cardDetail.attributes?.is_wishlist, "trending")}>
                        {
                          cardDetail.attributes?.is_wishlist ? (
                            <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }}/>
                            ):(<FavoriteBorderIcon color="secondary"  style={{ fill: 'blue' }}/>)
                        }
                      </Box>

                    </Box>
                    </Box>
                        <Box style={{ padding: '24px' }}>
                          <Box style={{ ...webStyles.productData, whiteSpace: 'nowrap', flexDirection: 'row' }}>
                            <Box style={{ ...webStyles.productValue, whiteSpace: 'nowrap' }}>
                              <Typography style={{ ...webStyles.productName, whiteSpace: 'nowrap' }} >{cardDetail?.attributes?.name}</Typography>
                              <Typography style={{ ...webStyles.productDescription, whiteSpace: 'nowrap' }} >{cardDetail?.attributes?.description}</Typography>
                            </Box>
                            <Box style={{ ...webStyles.acountMrp, textAlign: 'center' }}>{`$ ${cardDetail?.attributes?.primary_price}`}</Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              <Button variant="contained" data-testId="trendingButton" style={webStyles.stylistBtn} onClick={this.handleTrendingList}>{this.transLand("Explore Our Trending Collection")}</Button>
            </Grid>
            }
            {this.state.newLaunchDataList && this.state.newLaunchDataList.length > 0 &&
            <Grid style={webStyles.newLaunchGrid}>
              <Typography dir={i18n.dir()} style={webStyles.headContentText5}>{this.transLand(configJSON.newLauchHeadingText)}</Typography>
              <div dir={i18n.dir()} style={webStyles.divBroder}>
                <p style={webStyles.textBroder} />
              </div>
              <CustomSlider data={this.state.newLaunchDataList} navigateToDeatilPage={this.navigateToDeatilPage} handleLikeApiCall={this.handleFavouriteApi}
              calledFrom="newLaunch"/>
            </Grid>
            }
          </div>
        </ThemeProvider>
        <Footer navigation={this.props.navigation}/>
      </div>
    );
  }
}

// Customizable Area Start
const webStyles = {
  landingPageCard: {
    width: "878px",
    height: "470px"
  },
  headContentTextWrapper: {
    padding: "3rem 0"
  },

  headContentText: {
    color: "#375280",
    fontSize: "48px",
    fontWeight: 800,
    fontFamily: "Lato", 
    textTransform: "capitalize" as "capitalize"
  },
  headContentText1: {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: "Lato",
    textTransform: "capitalize" as "capitalize"
  },
  headContentText2: {
    color: "#375280",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "47px",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato"
  },
  headContentText3: {
    color: "#375280",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "30px",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato"
  },
  headContentText4: {
    color: "#375280",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "30px",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato"
  },
  headContentText5: {
    color: "#375280",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "47px",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato"
  },
  stylistBtn:{
    marginTop:"40px",
    fontSize:"20px",
    fontFamily: "Lato",
    fontWeight: 800,
    backgroundColor:"#CCBEB1",
    color:"#FFF",
    padding:"10px 16px 10px 16px",
    textTransform:'none' as 'none'

  },
  stylistGrid:{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    width: "50%",
    marginLeft:"40px",
  },
  stylistImage:{
    width: "375px",
    height: "711px",
    objectFit: "cover" as 'cover'
  },
  stylistImage2:{
    width: "317px",
    height: "344px",
    objectFit: "cover" as 'cover'
  },
  paperContainer: {
    gridRow: "span 2"
  },
  paperContainer4: {
    gridColumn: 2,
    gridRow: "span 1"
  },
  paperContainer3: {
    gridRow: "span 2"
  },
  textBroder: {
    width: "100px",
    height: "4px",
    borderBottom: "solid #CCBEB1"
  },
  divBroder: {
    display: "flex",
    paddingBottom: "20px",
    width: "100%",
    alignItems: "center" as "center",
    justifyContent: "center" as "center"
  },
  borderStyle: {
    width: "1150px",
    height: "0px",
    borderBottom: "solid #DDDCDC",
    top: "1693px",
    left: "136px",
    gap: "0px"
  },
  productIcon: { top: '20px', display: "flex", justifyContent: 'space-between', alignItems: "center", width: "100%"  },
  productFavorite: { color: "#375280", margin: "0 20px", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productDiscount: { color: "#375280", margin: "0 20px", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  productData: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
  productValue: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto' },
  productDescription: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productName: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  acountMrp: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '14px', fontWeight: 700, backgroundColor: '#FFFFFF',padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
  recommendationGrid:{
    width: "100%",
    height: "auto",
    padding: "50 0 40px 0",
    display: "flex",
    flexDirection: "column" as 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  recommendationOuterGrid:{ width: "100%", height: "697 px", padding: "40px 0" },
  trendingOuterGrid:{
    padding: "40px",
    background: "#FFF",
    display: "flex",
    flexDirection: "column" as 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  trendingGrid:{
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  trendingCard:{ boxShadow: 'none', 
  borderRadius: "0px, 0px, 2px, 2px", 
  width: "100%", 
  height: "455px",
  backgroundColor:"#f2efef" 
},
  trendingImage:{
    width: "100%",
    height: "370px",
    objectFit: "cover" as 'cover',
    position: 'relative' as 'relative', 
    display: 'block', 
    margin: 'auto'
  },
  newLaunchGrid:{ 
    width: "100%", 
    height: "800px", 
    padding: "40px 0" }

};
// Customizable Area End
