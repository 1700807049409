import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Tabs,
    Tab,
    InputBase,
    IconButton
} from "@material-ui/core";
import { Search, button_filter, dcubesvg, shoppingBag, timerPause } from "./assets";
import { styled } from "@material-ui/core/styles";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellersAllOrder from "../src/SellersAllOrder.web"
import SellerReadyForCollection from "../src/SellerReadyForCollection.web"
import SellersNewOrderWebPage from "../src/SellersNewDetails/SellersNewOrder.web"
import SellersAllOrderDetailsController, {
    Props,
} from "./SellersAllOrderDetailsController.web";
import SellersInProcessOrderWeb from "../src/SellersInProcessOrderDetails/SellersInProcessOrder.web"
import OutOfDelivery from "./OutOfDelivery.web";
import SellersRejectedOrder from "../src/SellersRejectedOrder/SellersRejectedOrder.web"
import ReturnRequest from "./ReturnRequest.web";
import SellerDelivered from "./SellerDelivered.web";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
// Customizable Area End

export default class SellersAllOrderDetailsWebPage extends SellersAllOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderContent = () => {
        const { value } = this.state;
        switch (value) {
            case 0:
                return <SellersAllOrder navigation={this.props.navigation} id={this.props.id} apiRecall={() => this.allOrderData()} />;
            case 1:
                return <SellersNewOrderWebPage navigation={this.props.navigation} id={this.props.id} apiRecall={() => this.allOrderData()} />;
            case 2:
                return <SellersInProcessOrderWeb navigation={this.props.navigation} id={this.props.id} apiRecall={() => this.allOrderData()} />;
            case 3:
                return <SellerReadyForCollection navigation={this.props.navigation} id={this.props.id} />;
            case 4:
                return <OutOfDelivery navigation={this.props.navigation} id={this.props.id} />;
            case 5:
                return <SellerDelivered navigation={this.props.navigation} id={this.props.id} />;
            case 6:
                return <ReturnRequest navigation={this.props.navigation} id={this.props.id} apiRecall={() => this.allOrderData()} />;
            case 7:
                return <SellersRejectedOrder navigation={this.props.navigation} id={this.props.id} apiRecall={() => this.allOrderData()} />;
            default:
                return null;
        }
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                        {this.state.roleData === "stylist" && <StylishSidebar navigation={this.props.navigation} /> }
                        {this.state.roleData === "seller" && <SellerSideBar navigation={this.props.navigation} />}
                    </Grid>
                    <Grid item xl={10} md={10} lg={10} sm={10} xs={10}  >
                        {this.state.roleData === "stylist" && <StylishHeader navigation={this.props.navigation} />} 
                        {this.state.roleData === "seller" && <SellerHeader navigation={this.props.navigation} />}
                        <Box style={{ margin: "30px" }}>
                            <Box>
                                <Box style={webStyle.haderBox}>
                                    <Box style={webStyle.orderText}>
                                        Order Summary
                                    </Box>
                                    <Box>
                                        <Box style={webStyle.textFild}>
                                            <InputBase
                                                placeholder='Search for a product '
                                                style={webStyle.searchBox}
                                                startAdornment={
                                                    <IconButton style={{ padding: '10px' }} aria-label="search">
                                                        <img src={Search} width="20px" height="20px" />
                                                    </IconButton>
                                                }
                                            />
                                            <Box>
                                                <img src={button_filter} width="30px" height="30px" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <div style={webStyle.containerStyle}>
                                    <div style={webStyle.boxStyle}>
                                        <Box style={{ display: 'flex' }}>
                                            <img src={dcubesvg} style={webStyle.imgBakColor} />
                                            <Box>
                                                <Box style={webStyle.lableText}>Total</Box>
                                                <Box style={webStyle.numberBox}>{this.state.totalOrder?.total_orders}</Box>
                                            </Box>
                                        </Box>
                                    </div>
                                    <div style={webStyle.boxStyle}>
                                        <Box style={{ display: 'flex' }}>
                                            <img src={timerPause} style={webStyle.imgBakColor} />
                                            <Box>
                                                <Box style={webStyle.lableText}>Shipped</Box>
                                                <Box style={webStyle.numberBox}>{this.state.totalOrder?.total_shipped_orders}</Box>
                                            </Box>
                                        </Box>
                                    </div>
                                    <div style={webStyle.boxStyle1}>
                                        <Box style={{ display: 'flex' }}>
                                            <img src={shoppingBag} style={webStyle.imgBakColor} />
                                            <Box>
                                                <Box style={webStyle.lableText}>Delivered</Box>
                                                <Box style={webStyle.numberBox}>{this.state.totalOrder?.total_delivered_orders}</Box>
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                                <Box>
                                    <Box style={{ paddingBottom: "30px" }}>
                                        <Box>
                                            <TabWrapBox className="newValue" >
                                                <Tabs
                                                    value={this.state.value}
                                                    onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                                                    scrollButtons="auto"
                                                    TabIndicatorProps={{ style: { ...webStyle.tabs } }}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <Tab data-testid="Processing" label={`All Orders (${this.state.totalOrder?.total_orders})`} style={{ ...webStyle.allTab, textTransform: 'none', display: 'flex' }} />
                                                    <Tab data-testid="NewOrders" label={`New Orders (${this.state.totalOrder?.total_new_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                    <Tab data-testid="InProcess" label={`In Process (${this.state.totalOrder?.total_in_process_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                    <Tab data-testid="ReadyforCollection" label={`Ready for Collection (${this.state.totalOrder?.total_process_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                    <Tab data-testid="OutforDelivery" label={`Out for Delivery (${this.state.totalOrder?.total_shipped_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                    <Tab data-testid="Delivered" label={`Delivered (${this.state.totalOrder?.total_delivered_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                    <Tab data-testid="ReturnRefund" label={`Return & Refund (${this.state.totalOrder?.total_return_refund_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                    <Tab data-testid="Rejected" label={`Rejected (${this.state.totalOrder?.total_rejected_orders})`} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                </Tabs>
                                            </TabWrapBox>
                                        </Box>
                                        {this.renderContent()}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    textFild: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    haderBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    numberBox: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280" },
    lableText: { fontSize: "16px", fontFamily: "Lato", fontWeight: 400, color: "#334155", marginBottom: '30px' },
    imgBakColor: { backgroundColor: "#CCBEB1", padding: '20px', borderRadius: '2px', marginRight: '16px', width: "36px", height: "36px" },
    orderText: { color: "#375280", fontFamily: "Lato", fontWeight: 700, fontSize: "24px" },
    container: { display: "flex", justifyContent: "space-between" },
    tabs: { backgroundColor: '#375280', borderBottom: '1px solid #E2E8F0', weight: "20px" },
    allTab: { fontSize: "14px", fontFamily: "Lato", fontWeight: 600, color: "#375280" },
    searchBox: { marginRight: "20px", width: "249px", height: "48px", border: "1px solid #CBD5E1", background: "#FFFFFF", color: "#94A3B8", fontSize: "16px", fontFamily: "Lato", fontWeight: 400, borderRadius: "2px", padding: "10px, 8px, 10px, 12px" },
    containerStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 0px',
    },
    boxStyle: {
        width: 'calc(33.33% - 10px)',
        height: '92px',
        backgroundColor: '#F8F8F8',
        marginRight: '21px',
        display: 'flex', alignItems: 'center', padding: '16px 20px'
    },
    boxStyle1: {
        width: 'calc(33.33% - 10px)',
        height: '92px',
        backgroundColor: '#F8F8F8',
        display: 'flex', alignItems: 'center', padding: '16px 20px'
    }
};
const TabWrapBox = styled(Box)(() => ({
    ' & .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e3e8f0',
    }
}))
// Customizable Area End