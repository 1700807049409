import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { resetOTP } from "./assets";
import OtpInput from "react-otp-input";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import ResetOTPPasswordController, {
    Props,
    configJSON,
} from "./ResetOTPPasswordController.web";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default class ResetOTPPassword extends ResetOTPPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    render() {
        let isEmail= this.handleEmailData();
        let isPhone=this.handlePhoneData();
        return (
            <>
                <Header navigation={this.props.navigation}/>
                <MainGrid data-test-id="mainGrid" className="PreferenceFormMainGrid" container dir={i18n.dir()}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
                        <Box className="sideImageBox">
                            <img src={resetOTP} alt="resetImage" className="sideImage" />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
                        <Box className={"formBox"}>
                            <form data-test-id="formSubmit" onSubmit={this.handleSubmit} style={{ width: "100%" }}>
                                <Box className={"headBox"}>
                                    <Typography className={"headText"}>{this.transResetOtp(configJSON.otpVerification)}</Typography>
                                </Box>
                                <Typography className={"headSubText"}>
                                    {this.state.dataParam.forgotType==="email" ?
                                        <>
                                            {this.transResetOtp(configJSON.sentTo)} <br />
                                            {this.transResetOtp(configJSON.emailText)}
                                            {isEmail}
                                        </> :
                                        <>
                                            {this.transResetOtp(configJSON.sentTo)} <br />
                                            {this.transResetOtp(configJSON.smsText)}
                                            {isPhone}
                                        </>
                                    }
                                </Typography>
                                <Box className="otpBox" data-test-id = "otpBox">
                                    <OtpMainContainer data-test-id = "otpContainer">
                                        <OtpInput
                                            data-test-id="otpInputdataID"
                                            onChange={this.handleOTPChangeUser}
                                            value={this.state.otpInput}
                                            numInputs={4}
                                            containerStyle={{ gap: "15px" }}
                                            inputStyle={{
                                                width: "50px",
                                                margin: "0px 3px",
                                                color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)",
                                                fontSize: "25px",
                                                height: "50px",
                                                border: "none",
                                                background: "#F0F0F0",
                                                textAlign: "center",
                                                outline: "none"
                                            }}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        {this.state.errorMassgage?.otpError && (
                                            <Box data-test-id = "otpMsgBox" className={"otpMsgBox"}>
                                                {this.state.errorMassgage.errorMsg}
                                            </Box>
                                        )}
                                    </OtpMainContainer>
                                </Box>
                                <Box className={"textHeading"}>
                                    <Typography className={"bottomText"} align="center">
                                        <Typography className={"reciveText"}>{this.transResetOtp(configJSON.reciveCodeText)} </Typography>
                                        <Typography data-test-id="resend-otp" onClick={this.handeResentOTPButton}>
                                            {this.transResetOtp(configJSON.resendCode)}
                                        </Typography>
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className={"LoginUserButton"}
                                        disableElevation
                                        type="submit"
                                    >
                                        {this.transResetOtp(configJSON.verifyAccount)}
                                    </Button>
                                    <Snackbar
                                        open={this.state.isAlert}
                                        autoHideDuration={3000}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        onClose={this.oncloseMessageAlert}
                                        data-test-id="alertmessageId"
                                        dir={i18n.dir()}
                                    >
                                        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                                    </Snackbar>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </MainGrid>
                <Footer navigation={this.props.navigation}/>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const MainGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .otpMsgBox": {
        color: "red",
        margin: "11px 12% 0px 0px",
        width: "100%",
        fontWeight: 300,
        fontFamily: "Avenir, sans-serif"
    },
    "& .sideImageBox": {
        width: "100%",
    },
    "& .formGrid": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .imageGrid": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    "& .sideImage": {
        height: "100%",
        width: "100%",
    },
    "& .formBox": {
        display: "flex",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        gap: 20,
        flexDirection: "column",
    },
    "& .textHeading": {
        textAlign: "center"
    },
    "& .headBox": {
        width: "100%",
        textAlign: "center"
    },
    "& .headText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir,sans-serif",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "26px",
        alignSelf: "center",
    },
    "& .otpBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 15,
        padding: "10px 0px",
    },
    "& .headSubText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir,sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
        alignSelf: "center",
    },
    "& .inputOTP": {
        border: "none",
        backgroundColor: "rgba(0, 0, 0,0.1)",
        height: "50px",
        width: "50px",
        padding: "8px",
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "26px",
        textAlign: "center",
    },
    "& .LoginUserButton": {
        width: "100% ",
        borderRadius: "0px",
        color: "white ",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1) ",
        gap: "8px",
        height: "56px ",
        textTransform: 'none',
        fontSize: "19px",
        fontWeight: 600,
        margin: "15px 0px 0px 0px"
    },
    "& .bottomText": {
        display: "flex",
        color: "#375280 ",
        cursor: "pointer",
        fontFamily: "Lato,sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    "& .reciveText": {
        color: "var(--Primary-Purple-500, #CCBEB1)",
        marginRight:'3px'
    },
    "@media screen and (min-width: 0px) and (max-width: 599px)": {
        "& .formBox": { width: "400px", flexWrap: "wrap" },
        "& .LoginUserButton": {
            width: "290px ",
        },
        "& .bottomText": {
            color: "#375280 ",
            cursor: "pointer",
            fontFamily: "Lato,sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
        },
    },
});

const OtpMainContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 5px 10px",
});
// Customizable Area End
