import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

// INTERFACES STARTS
type ErrorObject = {
  [key: string]: string;
};
// INTERFACES ENDS


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  selectedFiles: File[];
  isAlert: boolean;
  message: string;
  severity: 'error' | 'success' | 'info' | 'warning';
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sourceProductApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      token: "",
      selectedFiles: [], 
      isAlert: false,
      severity: "success",
      message: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const userToken = await getStorageData('auth-token');
    this.setState({
      token: userToken,
    });
  }

  async receive(from: string, messages: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === messages.id) {
        let responseJson = messages.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiRequestCallId = messages.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (responseJson && !responseJson.errors) {
          if (apiRequestCallId === this.sourceProductApiCallId) {
            this.setState((prevState) => ({
              ...prevState,
              isAlert: true,
              message: "Product added successfully!",
              severity: "success"
            }), () => setTimeout(() => {
              this.handleNavigation("HiredStylist")
            }, 2000));
          }
        } else {
         this.handleApiError(responseJson)
        }    
      }
    // Customizable Area End
  }
  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleNavigation = async (pageLink: string, name?: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleApiError = (responseJson:any) => {
    const { errors } = responseJson;

    if (errors && errors.length > 0) {
      const formattedErrors = errors.map((errorObj: ErrorObject) => {
        const field = Object.keys(errorObj)[0]; 
        const message = errorObj[field];      
        return `${field}: ${message}`;  
      });
      const errorMessage = formattedErrors.join(', '); 
      this.setState({
        isAlert: true,
        message: errorMessage, 
        severity: "error",
      });
    } else {
      this.setState({
        isAlert: true,
        message: "An unexpected error occurred.",
        severity: "error",
      });
    }
  };

  handleFileChange = (event: any,  setFieldValue:any) => {
    const files = event.target.files ? Array.from(event.target.files) : [];

    if (files.every(file => file instanceof File)) {
      this.setState({ selectedFiles: files as File[] }); 
    }
      setFieldValue('attachment', files);
  };

  removeFile = (index: number,  setFieldValue:any) => {
    this.setState((prevState) => {
        const updatedFiles = prevState.selectedFiles.filter((_, i) => i !== index);
        return { selectedFiles: updatedFiles };
      },() => {
        setFieldValue('attachment', this.state.selectedFiles);
      }
    );
  };
  
  favStylistProfile = async (values:any) => {   
    const headers = { 
      "token": this.state.token, 
    };
    const formData = new FormData();
    formData.append("product_name", values.productName);
    formData.append("sizes[]", values.productSizes);
    for(let i= 0; i < this.state.selectedFiles.length; i++){
      formData.append("images[]", values.attachment[i]);
    }
    formData.append('colours[]', values.productColor);
    formData.append("max_price", values.maxPrice);
    formData.append("min_price", values.minPrice);
    formData.append("description", values.productDescription);
    formData.append("gender", values.gender);
    formData.append("quantity", values.quantity);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sourceProductApiCallId = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_catalogue/product_sourcing_requests`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  // Customizable Area End
}