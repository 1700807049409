import React, { Component } from 'react';
import { styled } from "@material-ui/core/styles";
import Slider from 'react-slick';
const likeImg = require("./image_Like.png")
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
// import { useMediaQuery } from '@material-ui/core';

import {
    Grid,
    Box,
    Card,
    Typography
} from "@material-ui/core";
import { noImage } from '../../blocks/landingpage/src/assets';
import i18n from './i18next/i18n';


interface Object1 {
    id: string;
    type: string;
    attributes: {
        name: string;
        description: string;
        catalogue_variants: {}[],
        primary_price: string;
        primary_image: string;
        is_wishlist: boolean;
    };
}
interface INavigateDetailsTo {
    id: string | undefined;
    screenName: string;
    raiseMessage?: any;
}

interface Likefavourite {
    favouriteId: string;
    fav: boolean;
}
type MyProps = {
    data: Object1[]; 
    navigateToDeatilPage: ({
        id,
        screenName,
        raiseMessage,
    }: INavigateDetailsTo) => void,
    handleLikeApiCall:(
        favouriteId: string,
        fav: boolean,
        favFrom: string
        ) => void,
        calledFrom: string
};
type MyState = { isChecked: boolean; onChangeValue?: (value: boolean) => void; };

const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className={`arrow ${className}`}>
            <ArrowBackIosIcon className="arrows" style={{ color: "#0F172A", fontSize: "24px", marginLeft: '8px' }} />
        </div>
    );
};

const SampleNextArrow = (props: any) => {
    const { className, style, onClick, na } = props;
    return (
        <div onClick={onClick} className={`arrow ${className}`}>
            <ArrowForwardIosIcon className="arrows" style={{ color: "#0F172A", fontSize: "24px" }} />
        </div>
    );
};

const slidesToShow = (data: any) => {
    if (data.length > 2) {
        return 3
    }
    else if (data.length > 1) {
        return 2
    }
    else return 1
};

// const isLargeScreen = useMediaQuery('(min-width:1600px)');

export default class CustomSlider extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);

    }


    render() {
        const { data, navigateToDeatilPage, handleLikeApiCall, calledFrom } = this.props;
        const settings = {
            rtl: i18n.dir() === 'rtl',
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: slidesToShow(data),
            slidesToScroll: 1,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1440,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return (
            <MainConatinerGrid>
                <div className='slider-container' >
                    <Slider {...settings} >
                        {data && data.map((item: Object1, index: number) => {
                            return (
                                <Grid container dir={i18n.dir()}>
                                    <Grid item style={webStyle.cardMargin} key={index}>
                                        <Card 
                                            
                                            style={webStyle.cardContainer}>
                                            <Box style={{ position: "relative" }}>
                                            <img
                                                style={{ position: 'relative', display: 'block', margin: 'auto' }}
                                                width="100%" height="500px"
                                                src={item?.attributes?.primary_image === null ? noImage : item?.attributes?.primary_image}
                                                alt="Product"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    navigateToDeatilPage({ id: item.id, screenName: "Productdescription3" })
                                                }}
                                            />
                                                
                                                <Box style={{ ...webStyle.productIcon, position: 'absolute' }}>
                                                    <Box style={webStyle.productDiscount}>15% Off</Box>
                                                    <Box style={webStyle.productFavorite} onClick={()=>handleLikeApiCall(item.id, item.attributes?.is_wishlist, calledFrom)}>
                                                    {
                                                    item.attributes?.is_wishlist ? (
                                                        <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }} />
                                                    ) : (<FavoriteBorderIcon color="secondary" style={{ fill: 'blue' }} />)
                                                }
                                                        </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ padding: '24px' }}>
                                                <Box style={{ ...webStyle.productData, whiteSpace: 'nowrap', flexDirection: 'row' }}>
                                                    <Box style={{ ...webStyle.productValue, whiteSpace: 'nowrap' }}>
                                                        <Typography style={{ ...webStyle.productName, whiteSpace: 'nowrap' }} >{item?.attributes?.name}</Typography>
                                                        <Typography style={{ ...webStyle.productDescription, whiteSpace: 'nowrap' }} >{item?.attributes?.description}</Typography>
                                                    </Box>
                                                    <Box style={{ ...webStyle.acountMrp, textAlign: 'center' }}>{`$ ${item?.attributes?.primary_price}`}</Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            )
                        }
                        )}

                    </Slider>
                </div>
            </MainConatinerGrid>
        );
    }
}

const webStyle = {
    productData: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
    productValue: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto' },
    productIcon: { top: '20px', width: "100%", alignItems: "center", display: "flex", justifyContent: 'space-between', },
    productFavorite: { color: "#375280", margin: '0 20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
    productDiscount: { color: "#375280", margin: '0 20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
    productDescription: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    productName: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    acountMrp: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', border: "1px solid #D5D5D5", padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
    cardContainer: { backgroundColor: "#F8F8F8", boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "100%", height: "597px" },
    cardMargin: { margin: "40px" }

}

const MainConatinerGrid = styled(Grid)({
    "& .divConatiner": {
        width: "100%",
    },
    "& .slick-slider": {
        width: "100%",
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    "& .slick-list": {
        width: "88% !important"
    },



    "@media screen and (max-width:1200px)": {
        " & .slick-slider": {
            maxWidth: "76vw !important"
        },


        "& .slider-container": {
            display: "flex !important",
            justifyContent: "center !important",
            alignItems: "center !important",
        }

    },

    "& .slick-arrow ": {
        backgroundColor: "#fff !important",
        position: "absolute",
        right: "195px !important",
        bottom: "18px !important",
        opacity: 1,
        fontSize: "62px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",

    },

    "& .slick-next::before": {
        display: "none !important"
    },
    "& .slick-prev::before": {
        display: "none !important"
    },
    "& .slick-next": {
        fontSize: "100px !important",
        width: "40px !important",
        height: "40px !important",
        display: "flex !important",
        alignItems: "center !important",
        borderRadius: "50% !important",
        justifyContent: "center !important",
        padding: "5px !important",
        position: "absolute",
        right: "120px !important",
        zIndex: 9,
        "@media screen and (max-width:1600px)": {
            right: "100px !important",
        },
        "@media screen and (max-width:1200px)": {
            right: "60px !important",
        },
        "@media screen and (max-width:1024px)": {
            right: "50px !important",
        },

    },
    "& .slick-prev": {
        fontSize: "100px !important",
        width: "40px !important",
        height: "40px !important",
        display: "flex !important",
        alignItems: "center !important",
        borderRadius: "50% !important",
        justifyContent: "center !important",
        padding: "5px !important",
        position: "absolute",
        left: "120px !important",
        zIndex: 9,
        "@media screen and (max-width:1600px)": {
            left: "100px !important",
        },
        "@media screen and (max-width:1200px)": {
            left: "60px !important",
        },
        "@media screen and (max-width:1024px)": {
            left: "50px !important",
        },
    }



})