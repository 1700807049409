import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

// INTERFACES START
export interface IStylistQuoteList {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  id: number;
  product_name: string;
  request_status: string;
  max_price: string;
  min_price: string;
  sizes?: (string)[] | null;
  description: string;
  gender: string;
  quantity: number;
  colours?: (string)[] | null;
  images?: (ImagesEntity)[] | null;
  total_stylist_offer_requests: number;
  product_sourcing_stylist_prices?: (null)[] | null;
}
export interface ImagesEntity {
  id: number;
  url: string;
}

// INTERFACES END

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  productQuoteDetail: any;
  isAlert: boolean;
  message: string;
  severity: 'error' | 'success' | 'info' | 'warning';
  productId: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistQuoteController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFaqDataApi: string = "";
  fetchProductSourcingApi: string = "";
  acceptRejectQuoteApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      token: "",
      productQuoteDetail: {}, 
      isAlert: false,
      severity: "success",
      message: "",
      productId: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const userToken = await getStorageData('auth-token');
    const path = window.location.pathname;
    const segments = path.split('/');
    const quoteId = segments[segments.length - 1];
    this.setState({
      token: userToken,
      productId: quoteId
    });
    this.getProductSourcing(quoteId)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (responseJson && !responseJson.errors) {
            if (apiRequestCallId === this.fetchProductSourcingApi) {
                this.setState({ productQuoteDetail: responseJson.data });
            } 
            else if( apiRequestCallId === this.acceptRejectQuoteApi) {
              this.setState((prevState) => ({
                ...prevState,
                isAlert: true,
                message: "Success!",
                severity: "success"
              }));
              this.getProductSourcing(this.state.productId)
            }
            
        }     
      }
    // Customizable Area End
  }
  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleNavigation = async (pageLink: string, name?: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  getProductSourcing = async (id: string) => {
    const headers = {
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchProductSourcingApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_catalogue/product_sourcing_requests/${id}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  acceptRejectQuote = async (stylistId: string, productId: string, status: string) => {
    const headers = {
      "token": this.state.token
    };
    const formData = new FormData();
    formData.append("product_sourcing_request_id", stylistId);
    formData.append("stylist_offer_request_id", productId);
    formData.append("status", status);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.acceptRejectQuoteApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_catalogue/accept_and_reject_request`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formData);

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
 
  // Customizable Area End
}