import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";

interface OrderItem {
  id: string;
  type: string;
  attributes: {
    quantity: number;
    unit_price: string;
    total_price: string;
    reason_of_rejection: string | null;
    status: string;
    catalogue_name: string;
    brand_name: string;
    catalogue_variant_color: string;
    catalogue_variant_sku: string;
    store_name: string;
    catalogue_variant_size: string;
    catalogue_variant_front_image: string;
    catalogue_variant_back_image: string;
    catalogue_variant_side_image: string;
    driver: string | null;
  };
}
export interface OrderSeller  {
  id: string;
  type: string;
  attributes: {
    order_number: string;
    account: string;
    order_item_count: number;
    sub_total: string;
    total: string;
    status: string;
    placed_at: string;
    confirmed_at: string;
    in_transit_at: string;
    delivered_at: string;
    process_at: string;
    shipped_at: string;
    return_at: string;
    return_cancel_at: string;
    return_pick_at: string;
    cancelled_at: string;
    refunded_at: string;
    returned_at: string;
    deliver_by: string;
    order_status_id: number;
    created_at: string;
    updated_at: string;
    order_deliver_date: string;
    order_deliver_time: string;
    delivery_addresses: {
      id: string;
      type: string;
      attributes: {
        name: string;
        country_code: string;
        phone_number: string;
        contact_number: string;
        street: string;
        zip_code: string;
        area: string;
        block: string;
        city: string;
        house_or_building_number: string;
        address_name: string;
        is_default: boolean;
        latitude: number;
        longitude: number;
      };
    };
    order_return_date: string;
    order_return_time: string;
    order_items: OrderItem[];
    payment_detail: unknown | null;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep:number,
  orderDetailsList: OrderSeller | {},
  profileName: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderDetailApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep:0,
      orderDetailsList:{},
      profileName: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getOrderDetailApiCallId) {
      this.setState({ orderDetailsList: responseJson.data });
      switch (responseJson.data.attributes.status) {
        case "placed":
          this.setState({ activeStep: 0 });
          break;
        case "confirmed":
          this.setState({ activeStep: 1 });
          break;
        case "ready_to_ship":
          this.setState({ activeStep: 2 });
          break;
        case "shipped":
          this.setState({ activeStep: 3 });
          break;
        case "in_transit":
          this.setState({ activeStep: 4 });
          break;
        case "delivered":
          this.setState({ activeStep: 5 });
          break;
        default:
          break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getOrderDetailApi()
    const profileHolder = await getStorageData("user_data")
    this.setState({ profileName: JSON.parse(profileHolder).first_name + " " + JSON.parse(profileHolder).last_name })
  }

  getFormatDate = (dates: string) => {
    const formatDate = moment(dates).format("DD MMMM YYYY")
    return formatDate
  }

  getFormatMonth = (dates: string) => {
    const formatDate = moment(dates).format("DD MMMM")
    return formatDate
  }

  getFormatMonthDayTime = (dates: string) => {
    const formatDate = moment(dates).format("D MMMM YYYY h:mm A")
    return formatDate
  }

  proceedToOrderManagementPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "OrderManagementPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  proceedToTrackOrderPage = ({id,props,screenName}: {id: string | undefined,props: unknown,screenName: string}) => {
    setStorageData("trackID",id)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    runEngine.sendMessage(message.id, message);
  }

  proceedToHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }


  getOrderDetailApi = async () => {
    const getToken = await getStorageData("auth-token")
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    
    const headers = {
      redirect: "follow",
      "token": getToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${idData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  transOrderDetail = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
