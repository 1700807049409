Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error';
exports.offerPercentage= "15% off";
exports.getSearchApiEndPoint = "/bx_block_catalogue/catalogues/catalogue_buyer";
exports.postFavouriteApi = "bx_block_favourites/favourites";
exports.deleteFavouriteApi = "/bx_block_favourites/favourites/destroy_by_favouritable";
exports.addFavTitle = "item added to wishlist";
exports.removeFavTitle = "item removed from wishlist";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
// Customizable Area End
