// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End


export const configJSON = require("./config.js");


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  fullName: string;
  wishlistData: {
    id: string,
    attributes: {
        profile_picture: string;
        full_name: string,
        bio: string,
        is_favorite: boolean
    }
  }[];
  circularProgress: boolean;
  token: string;
  isAlert:boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FavouriteStylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFavouriteStylishAPICallID:string = "";
  deleteFavouriteStylishAPICallID:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      fullName: "",
      wishlistData: [],
      circularProgress: false,
      token: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(apiRequestCallId === this.fetchFavouriteStylishAPICallID){
        if(responseJson?.data){
          this.setState({
            wishlistData: responseJson.data,
            circularProgress: false
          })
        } else{
          this.setState({
            circularProgress: false
          })
        }
      }else if(apiRequestCallId === this.deleteFavouriteStylishAPICallID){
        this.setState({
            isAlert: true,
            alertMsg: responseJson?.message,
            alertType: "success",
        }, () => this.getFavouriteStylist())
      }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const name = await getStorageData('user_data', true);
    const authToken = await getStorageData('auth-token');

    this.setState({
      fullName: `${name?.first_name} ${name?.last_name}`,
      token: authToken
    });
    this.getFavouriteStylist();
    // Customizable Area End
  }

  oncloseAlert = () => {
    this.setState({isAlert: false
    })
  };
 getFavouriteStylist = async () => {
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchFavouriteStylishAPICallID = requestMessage.messageId;
    
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FavouriteStylistGETApiEnd
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteFavouriteStylist = async (id: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFavouriteStylishAPICallID = requestMessage.messageId;
    
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FavouriteStylistGETApiEnd}/${id}`
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  tranFavorite = (transKey: string) =>{
    return i18n.t( transKey, {ns: "stylist"});
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
