import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  styled,
  ClickAwayListener,
  IconButton,
  InputBase,
  Tooltip,
  Popover
} from "@material-ui/core";
import SellerStoreStatusUpdateController, {
  Props,
} from "./SellerStoreStatusUpdateController";
import { ThemeProvider } from "react-native-elements";
import { expandArrowIcon } from "../../../../components/src/Seller/assets";
import { search } from "../../../catalogue/src/assets";
import { AntSwitch } from "../SellerStore.web";
import Loader from "../../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerStoreStatusUpdate extends SellerStoreStatusUpdateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  storeStatusBody = () =>
    this.state.acceptedStore.length === 0 ? (
      <div className="loader-container">
        <Typography className="no-active-store">
          Approved Store Not Found
        </Typography>
      </div>
    ) : (
      <StoreContainer>
        <Box className="input-container" style={{backgroundColor:"#fff", paddingTop:"20px"}}><InputBase
          placeholder="Search Store"
          style={{ ...webStyle.searchBox }}
          startAdornment={
            <IconButton style={{ padding: "10px" }} aria-label="search">
              <img src={search} width="20px" height="20px" />
            </IconButton>
          }
          value={this.state.searchStoreStatusQuery}
          onChange={this.handleSellerStoreSearch}
          data-test-id="seller-store-status-search-box-test-id"
          name="search"
        />
        </Box>
        {this.state.searchStoreStatusQuery === "" && (
          <Box className="select-all-product-container">
            <Typography className="select-all-title">
              Open/Close All Stores
            </Typography>
            <AntSwitch
              checked={this.state.isAllAccepted}
              name="checked"
              data-test-id={`ant-switch-all-test-id`}
              onChange={() =>
                this.handleStoreStatusUpdate(0, this.state.isAllAccepted)
              }
            />
          </Box>
        )}
        <Box className="store-name-display-container">
          {this.state?.acceptedStore?.map((acceptedStore) => (
            <Box className="store-name-container">
              <Box className="store-name-left" data-test-id={`store-test-id-${acceptedStore.id}`} onClick={()=>this.handleStoreSelect(acceptedStore)}>
                <Box className="store-name-img">
                  <Tooltip title={`${acceptedStore.name}`} arrow>
                    <img
                      src={acceptedStore.path}
                      alt={`img-${acceptedStore.id}`}
                      className="img-container"
                    />
                  </Tooltip>
                  <Typography className="store-name">
                    {acceptedStore.name}
                  </Typography>
                </Box>
              </Box>
              <Box className="right-container">
                <Box
                  className={`${
                    acceptedStore.is_open ? "Open" : "Close"
                  }-container`}
                >
                  {acceptedStore.is_open ? "Open" : "Close"}
                </Box>
                <Box className="switch-container">
                  <AntSwitch
                    checked={acceptedStore.is_open}
                    name="checked"
                    data-test-id={`ant-switch-${acceptedStore.id}`}
                    onChange={() =>
                      this.handleStoreStatusUpdate(
                        acceptedStore.id,
                        acceptedStore.is_open
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </StoreContainer>
    );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <ClickAwayListener onClickAway={this.props.handleCloseStore}>
          <Box
            aria-controls="simple-menu"
            className="profileBox"
            aria-haspopup="true"
            onClick={this.props.handleClickStore}
          >
            <IconButtonContainer
              onClick={this.props.handleClickStore}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <Tooltip title={this.state.storeName} arrow>
                <img className="img-container" src={this.state.storeImg} alt="" />
              </Tooltip>
              <Typography className="store-name">
                {this.state.storeName}
              </Typography>
              <img src={expandArrowIcon} alt="expand-arrow" />
            </IconButtonContainer>
            <PopOverContainer
              id="simple-menu"
              anchorEl={this.props?.anchorElStore}
              open={Boolean(this.props?.anchorElStore)}
              onClose={this.props.handleCloseStore}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {this.state.isLoader ? (
                <div className="loader-container">
                  <Loader
                    id="seller-store-status-loader"
                    dataTestId="seller-store-status-loader-test"
                  />
                </div>
              ) : (
                this.storeStatusBody()
              )}
            </PopOverContainer>
          </Box>
        </ClickAwayListener>
        <ReusableSnackbar
          onClose={this.onCloseSSAlert}
          open={this.state.isAlert}
          severity={this.state.severity}
          message={this.state.message}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const IconButtonContainer = styled(IconButton)({
  maxWidth: 200,
  "& .img-container":{
    width: 40, 
    height: 40,
    borderRadius: "50%"
  },
  "& .store-name":{
    margin: "0 10px 0 10px",
    cursor: "pointer",
    fontFamily: "Lato , sans-serif;",
    color: "#375280",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: " -webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "18px", 
    textTransform: "capitalize"
  }
})
const webStyle = {
  searchBox: {
    border: "1px solid #CBD5E1",
    fontFamily: "Lato, sans-serif",
    background: "#FFFFFF",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "2px",
    height: "40px",
    width: "100%",
    padding: "10px, 8px, 10px, 12px",
  },
};

const StoreContainer = styled(Box)({
  padding: 20,
  paddingTop: 0,
  width: 370,
  "& .input-container": {
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  "@media(max-width:600px)": {
    width: 320,
  },
  "@media(max-width:400px)": {
    width: 300,
  },
  "& .store-name-display-container": {
    maxHeight: 400,
    "& .store-name-container": {
      padding: "16px 0px",
      borderBottom: "1px solid #D9D9D9",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: 2,
      "& .store-name-left": {
        "& .store-name-img": {
          display: "flex",
          gap: 10,
          alignItems: "center",
          "& .img-container": {
            width: 32,
            height: 32,
            borderRadius: "50%",
          },
          "& .store-name": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 500,
            fontSize: 16,
            color: "#375280",
            width: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: " -webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
      },
      "& .right-container": {
        display: "flex",
        gap: 20,
        alignItems: "center",
        "& .Open-container": {
          borderRadius: 2,
          fontWeight: 400,
          backgroundColor: "#E2E8F0",
          fontSize: 14,
          fontFamily: "Lato, sans-serif",
          color: "#375280",
          width: 50,
          height: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .Close-container": {
          fontFamily: "Lato, sans-serif",
          color: "#DC2626",
          backgroundColor: "#FEE2E2",
          fontWeight: 400,
          fontSize: 14,
          width: 50,
          height: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .switch-container": {
          borderLeft: "1px solid #375280",
          height: 30,
          paddingLeft: 20,
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
  "& .select-all-product-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "16px 0px 10px 0px",
    "& .select-all-title": {
      color: "#375280",
      fontFamily: "Lato, sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
  },
});

const PopOverContainer = styled(Popover)({
  maxHeight: 410,
  marginTop: "55px",
  "& .MuiPaper-root.MuiPopover-paper" : {
    top: "20px !important",
  },
  "@media(max-width:587px)": {
    marginTop: "80px",
  },
  "@media(max-width:400px)": {
    marginTop: "120px",
  },
  "& .loader-container": {
    height: 200,
    width: 320,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .no-active-store": {
      textAlign: "center",
      fontFamily: "Lato, sans-serif",
      fontWeight: 500,
      fontSize: 18,
      color: "#375280",
    },
  },
});
// Customizable Area End
