import React from "react";
// Customizable Area Start
import { arrow, arrowselect, crossmini, upload, deleteBttn } from "./assets";
import {storeSuccessIcon} from "../../customform/src/assets";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box, Checkbox, TableBody, TableHead, TableRow, Paper, TableCell, TableContainer, styled, TextField, MenuItem, Select, RadioGroup, FormControlLabel, Radio, FormControl, OutlinedInput, Table, Typography, Button,Dialog } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import  { ChangeEvent } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AddProductCatalougeController, { Props } from "./AddProductCatalougeController";
import ArrowGender, { CircleDIvFIlledFunc, CircleDiv, FontListed, FontWight, HrTag, LinkStatusFirst } from "../../../components/src/ArrowGender.web";
import storage from '../../../framework/src/StorageProvider';

// Customizable Area End


export default class AddProductCatalougeWeb extends AddProductCatalougeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleCategoryChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCategoryName = event.target.value as string;
    const selectedCategory = this.state.category.find(
      (category) => category.attributes.name === selectedCategoryName
    );
    if (selectedCategory && selectedCategory.id) {
      this.setState({categoryIdd : selectedCategory.attributes.id})
      this.setState({ clothValue: selectedCategoryName });
      this.sub_category(selectedCategory.attributes.id); 
    }
  };
  handleChange = async(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;

    if (name) {
      this.setState({
        [name]: value,
        errors: { ...this.state.errors, [name]: '' }
      } as any);
    }

    if (name === 'productName') {
      await this.findNameOnchange(value);
    }
  };


  handleSubCategoryChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCategoryName1 = event.target.value as string;
    const selectedCategory1 = this.state.category1.find(
      (category1) => category1.attributes.name === selectedCategoryName1
    );
    if (selectedCategory1 && selectedCategory1.id) {
      this.setState({categoryValueId: selectedCategory1.attributes.id})
      this.setState({ categoryValue: selectedCategoryName1 });
      this.subsubCategorey(selectedCategory1.attributes.id); 
    }
  };
  handleSubSubCategoryChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedSubSubCategoryName = event.target.value as any;


    const selectedSubSubCategory = this.state.subsubCategory.find(
      (subsubCategory) => subsubCategory.attributes.name === selectedSubSubCategoryName
    );
    if (selectedSubSubCategory && selectedSubSubCategory.id) {
      this.setState({subsubcategoryValueId: selectedSubSubCategory.attributes.id})
      this.setState({ categoryValue1: selectedSubSubCategoryName });
    }
  };

  handleSelectAllChanges = () => {
    this.setState(prevState => {
      const { selectAll, storeList } = prevState;
      const newSelectAll = !selectAll;

      
      const newSelectedStores = newSelectAll ? storeList.map((store:any) => store.id) : [];

      return {
        selectedStores: newSelectedStores,
        selectAll: newSelectAll,
      };
    });
  };
  handleCheckboxChange = (id: number) => {
    this.setState(prevState => {
      const { selectedStores } = prevState;
      const isSelected = selectedStores.includes(id);
      const newSelectedStores = isSelected
        ? selectedStores.filter((storeId:any) => storeId !== id)
        : [...selectedStores, id];

      return {
        selectedStores: newSelectedStores,
        selectAll: newSelectedStores.length === this.state.storeList.length,
      };
    });
  };
 
  handleSizeChange = (event: any) => {
    const selectedSizes = event.target.value;
    const selectedSizeIds = selectedSizes.map((sizeName: any) => {
      const sizeObj = this.state.varient_size.find((size: { attributes: { name: any; }; }) => size.attributes.name === sizeName);
      return sizeObj ? sizeObj.id : null;
    }).filter((id: any) => id !== null);

    this.setState({ personName: selectedSizes, selectedSizeIds });
  };

  // Method to remove a selected size
  removeSelectedSize = (sizeName: any) => {
    const updatedSizes = this.state.personName.filter(item => item !== sizeName);
    const updatedSizeIds = updatedSizes.map(sizeName => {
      const sizeObj = this.state.varient_size.find((size: { attributes: { name: string; }; }) => size.attributes.name === sizeName);
      return sizeObj ? sizeObj.id : null;
    }).filter(id => id !== null);

    this.setState({ personName: updatedSizes, selectedSizeIds: updatedSizeIds });
  };
  handleColorChange = (event:any) => {
    const selectedColors = event.target.value;
    const selectedColorIds = selectedColors.map((colorName: any) => {
      const colorObj = this.state.varient_color.find((colorr:any) => colorr.attributes.name === colorName);
      return colorObj ? colorObj.id : null;
    }).filter((id: any) => id !== null);
    
    storage.set('selectedColor', JSON.stringify(selectedColors));
    this.setState({ selectedColors });
    this.setState({ colorArry: selectedColors, selectedColorIds });
    const newGridImages = selectedColors.map((color:any) => {
        return {
          color: color,
          images: [
            { name: "Front View", imgUrlLink: "", active: false },
            { name: "Back View", imgUrlLink: "", active: false },
            { name: "Side View", imgUrlLink: "", active: false },
          ],
          selectedImage: "",
          activeGridImage: "",
        };
    });
  
    this.setState({ gridImages: newGridImages });

  };

  removeSelectedColor = (colorName:any) => {
    const updatedColors = this.state.colorArry.filter(item => item !== colorName);
    const updatedColorIds = updatedColors.map(colorName => {
      const colorObj = this.state.varient_color.find((colorr: any) => colorr.attributes.name === colorName);
      return colorObj ? colorObj.id : null;
    }).filter(id => id !== null);

    this.setState({ colorArry: updatedColors, selectedColorIds: updatedColorIds });
  };
 





handleCreateVariant = async () => {
  const { create_vari } = this.state;
  let skuErrorExists = false;
  let validationErrors: string[] = [];

  const validateAndSetErrors = async (variant: any, index: number): Promise<boolean> => {
    const errors = [];

   

    const skuExists = await checkSku(variant.sku, index);
    if (skuExists) {
      errors.push("SKU already exists");
    }

    if (errors.length > 0) {
      validationErrors = validationErrors.concat(errors);
      return true;
    }
    return false;
  };

  const checkSku = async (sku: string, index: number): Promise<boolean> => {
    if (!sku) return false;

    await this.skuOnchange(sku);
    const skuExists = this.state.sku_change.already_exists.includes(sku);
    create_vari[index].skuError = skuExists ? "SKU already exists" : "";
    return skuExists;
  };

  for (let index = 0; index < create_vari.length; index++) {
    const variant = create_vari[index];
    const hasErrors = await validateAndSetErrors(variant, index);
    if (hasErrors) {
      skuErrorExists = true;
    }
  }

  this.setState({ create_vari, validationErrors });

  if (!skuErrorExists && validationErrors.length === 0) {
    this.createVarient(this.state.selectedSizeIds, this.state.selectedColorIds);
    this.setState({ isVariantCreated: true });
  } else {
    console.error("Some errors occurred. Please fix them before updating the variant.");
  }
};

handleInputChange = (index: any, field: any, value: any) => {
  const updatedVariants = [...this.state.create_vari];
  updatedVariants[index][field] = value;

  if (field === 'price')
   {
    updatedVariants[index].priceError = ""; 

    if (Number(value) <= 0) {

      updatedVariants[index].priceError = "Price should be greater than 0";
   
    }

  }
  if (field === 'sku') {
      updatedVariants[index].skuError = "";
      const isDuplicateSku1 = updatedVariants.some(
        (variant, idx) => idx !== index && variant.sku === value
      );
      if (isDuplicateSku1) {
        updatedVariants[index].skuError = "SKU is already taken";
      }
  }
 

  this.setState({ create_vari: updatedVariants });
};


renderTableRows = () => {
  const { create_vari } = this.state;

  return create_vari.map((variant: any, index: number) => (
    <TableRow key={index}>
      <TableCell style={webStyle.trHead} component="th" scope="row">
        {`${variant.variant_size || 'N/A'}/${variant.variant_color || 'N/A'}`}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left">
        <CustomInputTable 
          value={variant.stock_qty || ''} 
          onChange={(e) => this.handleInputChange(index, 'stock_qty', e.target.value)}
          id={`quantity-${index}`} 
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.quantityError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.quantityError}</p>
        )}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left">
        <CustomInputTable 
          value={variant.price || ''} 
          onChange={(e) => this.handleInputChange(index, 'price', e.target.value)}
          id={`price-${index}`} 
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.priceError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.priceError}</p>
        )}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left">
        <CustomInputTable 
          value={variant.sku || ''} 
          onChange={(e) => this.handleInputChange(index, 'sku', e.target.value)}
          id={`sku-${index}`} 
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.skuError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.skuError}</p>
        )}
      </TableCell>
    </TableRow>
  ));
};


  // Customizable Area End

  render() {
    // Customizable Area Start
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const userRole = JSON.parse(localStorage.getItem('userRole') || '{}');
  
    const fontListed = (data: boolean) => {
      return (FontListed(data))
    }
    const genderArrow = (arrowHandle: boolean) => {
      return (ArrowGender(arrowHandle))
    }
    const getSecondPagevariant = (title: string, qty: string, price: string, sku: string) => {
      return (<><div style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '70px', padding: 15 }}><div>
        <h2 style={webStyle.labelH}>Variant (Size)*</h2>
        <FormControl style={webStyle.selectDiv}>
          <SelectCustomMul
            multiple
            displayEmpty
            data-test-id="genderSel6"
            disableUnderline={true}
            value={this.state.personName}
            onChange={this.handleSizeChange}
            input={<OutlinedInput />}
            renderValue={() => {
              return <p style={{ paddingLeft: 15 }}>Select Size</p>;
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
            onOpen={() => this.setState({ sizeMulSelect: true })}
            onClose={() => this.setState({ sizeMulSelect: false })}
            IconComponent={() => <img src={arrowselect} style={{ transform: genderArrow(this.state.sizeMulSelect) }} />}
          >
            {this.state.varient_size?.map((varient_size:any) => (
              <MenuItem
                key={varient_size.attributes.name}
                value={varient_size.attributes.name}
              >
                {varient_size.attributes.name}
              </MenuItem>
            ))}
          </SelectCustomMul>
        </FormControl>
        <CustomBoxDiv style={{ marginTop: 12 }}>
          {this.state.personName?.map((item) => {
            return (
              <div style={{
                display: "flex", width: "fit-content", border: '1px solid #CCBEB1', borderRadius: 2, padding: "10px", height: "18px",
                alignItems: "center", marginRight: 12
              }} key={item}>
                <h1 style={webStyle.selectedItem}>{item}</h1>
                <img style={{ marginLeft: 15 }} src={crossmini} alt="crossmini" data-test-id="genderSel7" 
                onClick={() => this.removeSelectedSize(item)} />
              </div>
            )
          })}
        </CustomBoxDiv>
        <p style={webStyle.errorMsg}>Separate values with commas</p><br />
        <h2 style={webStyle.labelH}>Variant (Color)*</h2>
        <FormControl style={webStyle.selectDiv}>
          <SelectCustomMul
            multiple
            displayEmpty
            disableUnderline={true}
            data-test-id="genderSel8"
            value={this.state.colorArry}
            onChange={this.handleColorChange}
            input={<OutlinedInput />}
            renderValue={() => {
              return <p style={{ paddingLeft: 15 }}>Select Color</p>;
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
            onOpen={() => this.setState({ colorSel: true })}
            onClose={() => this.setState({ colorSel: false })}
            IconComponent={() => <img src={arrowselect} style={{ transform: genderArrow(this.state.colorSel) }} />}
          >
            {this.state.varient_color?.map((varient_color:any) => (
              <MenuItem
                key={varient_color.attributes.name}
                value={varient_color.attributes.name}
              >
                {varient_color.attributes.name}
              </MenuItem>
            ))}
          </SelectCustomMul>
        </FormControl>
        <CustomBoxDiv style={{ marginTop: 12 }}>
          {this.state.colorArry?.map((item) => {
            return (
              <div style={{
                display: "flex", width: "fit-content", border: '1px solid #CCBEB1', borderRadius: 2, padding: "10px", height: "18px",
                alignItems: "center", marginRight: 12
              }} key={item}>
                <h1 style={webStyle.selectedItem}>{item}</h1>
                <img style={{ marginLeft: 15 }} src={crossmini} alt="crossmini" data-test-id="genderSel9" 
              onClick={() => this.removeSelectedColor(item)}
              
                 />
              </div>
            )
          })}
        </CustomBoxDiv>
      
        <p style={webStyle.errorMsg}>Separate values with commas</p><br />
       
                    {this.state.create_vari.some((variant: any) => variant.skuError) && (
                        <p style={webStyle.errorMsg1}>
                            <p style={{ marginRight: "16px", marginBottom: "8px" }}><InfoOutlinedIcon /></p>
                            <p>
                                <p style={{ color: "#DC2626", fontSize: "18px", fontWeight: 600 }}>
                                    {this.state.create_vari.find((variant: any) => variant.skuError)?.skuError}
                                </p>
                                <p style={{ marginTop: "-10px" }}>A max of 30 variants can be created</p>
                            </p>
                        </p>
                    )}
        <button 
        style={webStyle.createVarBtn}
         onClick={this.handleCreateVariant} 
         data-test-id="handleCreateVariant">
          {this.state.isVariantCreated ? 'Update Variant' : 'Create Variant'}
          </button>
          
          <br />
        <p style={webStyle.errorMsg}>You can create a max of 30 variants of the product</p>
      </div></div>
        <div style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '70px', padding: 15 }}><h2 style={webStyle.labelVari}>Variant Details &nbsp;{'[0/30]'}</h2>
          <TableContainer component={Paper} style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '10px', boxShadow: "none" }}>
            <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={webStyle.thHead}>Variant</TableCell>
                  <TableCell style={webStyle.thHead} align="left">Quantity</TableCell>
                  <TableCell style={webStyle.thHead} align="left">Price</TableCell>
                  <TableCell style={webStyle.thHead} align="left">SKU</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
               

              {this.renderTableRows()}

              </TableBody>
            </Table>
          </TableContainer>
        </div></>)
    }
    const getSecondPage = () => {
      switch (this.state.linkStatus) {
        case 1:
          return (
            <div style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '70px', padding: 15 }}>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Product Name*</h2>
                  <CustomInput
                  data-test-id="handleChangeg"
                    id="outlined-basic"
                    placeholder="Product Name"
                    variant="standard"
                    name="productName"
                    value={this.state.productName}
                    onChange={this.handleChange}
                    InputProps={{ disableUnderline: true }}
                  />
                    {this.state.errors.productName && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.productName}</div>}
                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Gender*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.genderValue}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel"
                      onChange={(event) => this.setState({ genderValue: event.target.value as string })}
                      onOpen={() => this.setState({ genderSelect: true })}
                      onClose={() => this.setState({ genderSelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderArrow(this.state.genderSelect) }} />}
                    >
                      <MenuItem style={webStyle.menuItem} value={"male"}>Male</MenuItem>
                      <MenuItem style={webStyle.menuItem} value={"female"}>Female</MenuItem>
                      <MenuItem style={webStyle.menuItem} value={"other"}>Other</MenuItem>
                    </SelectCustom>
                    {this.state.errors.genderValue && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.genderValue}</div>}

                  </FormControl>
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Select Brand*</h2>
                  <CustomInput id="outlined-basic" placeholder="Brand" variant="standard"
                   name="brand"
                   value={this.state.brand}
                   onChange={this.handleChange}
                   InputProps={{ disableUnderline: true }}
                  />
                              {this.state.errors.brand && <div style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.brand}</div>}

                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Select Category*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.clothValue}
                      label="Age"
                      data-test-id="genderSel2"
                      disableUnderline={true}
                      onChange={this.handleCategoryChange}
                      onOpen={() => this.setState({ clothSelect: true })}
                      onClose={() => this.setState({ clothSelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderArrow(this.state.clothSelect) }} />}
                    >
                     
 {this.state.category.map((category) => (
              <MenuItem
                key={category.id}
                style={webStyle.menuItem}
                value={category.attributes.name}
              >
                {category.attributes.name}
              </MenuItem>
            ))}
                    </SelectCustom>
                    {this.state.errors.clothValue && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.clothValue}</div>}
                  </FormControl>
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Select Sub-category*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.categoryValue}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel3"
                      onChange={this.handleSubCategoryChange}
                      onOpen={() => this.setState({ categorySelect: true })}
                      onClose={() => this.setState({ categorySelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderArrow(this.state.categorySelect) }} />}
                    >
                      {this.state.category1?.map((sub) => (
                <MenuItem
                  key={sub.id}
                  style={webStyle.menuItem}
                  value={sub.attributes.name}
                >
                  {sub.attributes.name}
                </MenuItem>
              ))}
                    </SelectCustom>
                    {this.state.errors.subCategory && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.subCategory}</div>}
                  </FormControl>
                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Select Sub-Sub-category*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.categoryValue1}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel34"
                      onChange={this.handleSubSubCategoryChange}
                      onOpen={() => this.setState({ categorySelect: true })}
                      onClose={() => this.setState({ categorySelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderArrow(this.state.categorySelect) }} />}
                    >
                      {this.state.subsubCategory?.map((subsub) => (
                <MenuItem
                  key={subsub.id}
                  style={webStyle.menuItem}
                  value={subsub.attributes.name}
                >
                  {subsub.attributes.name}
                </MenuItem>
              ))}
                    </SelectCustom>
                    {this.state.errors.subSubCategory && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.subSubCategory}</div>}
                  </FormControl>
                </div>
              </CustomBoxDiv>

              <CustomBoxDiv>
              <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Material*</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder="Material"
              variant="standard"
              name="material"
              value={this.state.material}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
                        {this.state.errors.material && <div style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.material}</div>}

                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Fit*</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder="Fit"
              variant="standard"
              name="fit"
              value={this.state.fit}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
                        {this.state.errors.fit && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif",}}>{this.state.errors.fit}</div>}

                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
               
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>Product Care*</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder="Product Care"
              variant="standard"
              name="productCare"
              value={this.state.productCare}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
                        {this.state.errors.productCare && <div style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.productCare}</div>}

                </div>
              </CustomBoxDiv>
            

<h2 style={webStyle.labelH}>List the products*</h2>
<FormControl style={{ display: 'flex' }}>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="listed"
    data-test-id="listedd"
    name="radio-buttons-group"
    onChange={(event) => this.setState({ listed: event.target.value })} 
    style={{ display: 'block', marginTop: 4 }}
  >
    <FormControlLabel
      value="listed"
      data-test-id="genderSel4"
      style={{
        fontStyle: "normal !important",
        fontFamily: "Lato , sans-serif",
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: fontListed(this.state.listed === "listed"), 
        color: '#375280'
      }}
      control={<CustomRadio />}
      label="Listed"
    />
    <FormControlLabel
      value="unlisted"
      data-test-id="genderSel5"
      style={{
        fontStyle: "normal !important",
        fontFamily: "Lato , sans-serif",
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: fontListed(this.state.listed === "unlisted"), 
        color: '#375280'
      }}
      control={<CustomRadio />}
      label="Unlisted"
    />
  </RadioGroup>
</FormControl>

              <h2 style={webStyle.labelH}>Product Description*</h2>
              <div style={webStyle.detailDiv}>
              <CustomInput
            id="outlined-basic"
            placeholder="Product Description"
            variant="standard"
            name="productDescription"
            value={this.state.productDescription}
            onChange={this.handleChange}
            InputProps={{ disableUnderline: true }}
            style={webStyle.detailTitle}
          />
                      {this.state.errors.productDescription && <div style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.productDescription}</div>}

              </div></div>
          )
        case 2:
          return (getSecondPagevariant('Create', '-', '-', '-'))
        case 3:
          return (getSecondPagevariant('Update', '20', '25', 'AS123SS'))
        case 4:
          return (
            <CustomBoxDivs style={{ display: 'flex', flexWrap: 'wrap', gap: "30px" }}>
            {this.state.gridImages.map((grid: any) => (
              <div key={grid.color} style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '70px', width: '580px' }}>
                {uploadBtnPart(
                  `${grid.color.charAt(0).toUpperCase() + grid.color.slice(1)} Variant`,
                  grid.images,
                  grid.selectedImage,
                  grid.activeGridImage,
                  grid.color,
                  (viewName: string, e: ChangeEvent<HTMLInputElement>) => this.handleGridImage(grid.color, viewName, e),
                  (name: string) => this.handleActiveGrid(grid.color, name),
                  this.handleDeleteImage 
                )}
              </div>
            ))}
          </CustomBoxDivs>
          )
        case 5:
          const userRole = JSON.parse(localStorage.getItem('userRole') || '{}');
          if (userRole.userType !== 'stylist') {
          return (<CustomBoxDiv style={{ display: 'flex' }}>
          <div style={{ marginTop: '70px', width: '100%', border: "1px solid #E2E8F0", borderRadius: "4px", padding: '10px 20px 10px 20px' }}>
            <div style={{ padding: "10px 0px 10px 0px", display: 'flex', alignItems: 'center', borderBottom: '1px solid #D9D9D9', gap: "20px", justifyContent: 'space-between' }}>
            <h2 style={webStyle.headingL}>Select All Stores</h2>
             
            <GreenCheckbox
                checked={this.state.selectAll}
                onChange={this.handleSelectAllChanges}
                
                data-test-id="selectAllCheckbox"
              />
            </div>
            {this.state.storeList?.map((store: any) => (
              <div key={store.id} style={{ padding: "10px 0px 10px 0px", display: 'flex', alignItems: 'center', borderBottom: '1px solid #D9D9D9', gap: "20px", justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: "20px",alignItems:"center" }}>
                  <img  style={{width:"40px",height:"40px",borderRadius:"50px"}} src={store.attributes.image} alt="upload" />
                  <p style={{ fontSize: "18px", color: "#375280", fontFamily: "Lato, sans-serif" }}>{store.attributes.store_name}</p>
                </div>
                <GreenCheckbox
                  checked={this.state.selectedStores.includes(store.id)}
                  onChange={() => this.handleCheckboxChange(store.id)}
                  data-test-id="checkBox"
                />
              </div>
            ))}
          </div>
        </CustomBoxDiv>
          )
            }
        default:
          break;
      }

    }
    const uploadBtnPart = (
      imgName: string,
      griddata: ImageData[],
      selectedImg: string,
      activeGrid: string,
      color: string,
      handleGridImage: (viewName: string, e: ChangeEvent<HTMLInputElement>) => void,
      handleActiveGrid: (name: string) => void,
      handleDeleteImage: (color: string, viewName: string) => void 
    ) => {
      if (!griddata) {
        return null;
      }
    
      return (
        <div style={{ margin: "22px" }} key={"activeee" + activeGrid}>
          <h1 style={webStyle.titleImg}>{imgName}</h1>
    
          {selectedImg === '' ? (
            <>
              <Button
                component="label"
                style={{ paddingLeft: 50, textAlign: 'center', display: "grid", boxShadow: 'none', width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }}
                variant="contained"
              >
                <img src={upload} alt="upload" style={{ marginLeft: 56 }} />
                <UploadBtn>
                  Upload Image
                </UploadBtn>
                <UploadText>Only png or Jpg files are accepted</UploadText>
                <input
                  disabled={activeGrid === ''}
                  type="file"
                  hidden
                  data-test-id="upload-image-test"
                  onChange={(e) => handleGridImage(activeGrid, e)} 
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
              <button
                style={webStyle.uploadBtn}
                data-test-id="genderSe11"
                onClick={() => this.fileInputRef.current.click()}
              >
                Upload Photo
              </button>
            </>
          ) : (
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <img src={selectedImg} alt='testttt' style={{ objectFit: "contain", textAlign: 'center', display: "grid", boxShadow: 'none', width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }} />
              <button 
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '17px',
                  backgroundColor: 'white',
                  color: 'white',
                  border: 'none',         
                  borderRadius: '50%',
                  width: '36px',
                  height: '36px',
                  cursor: 'pointer'
                }} 
                onClick={() => handleDeleteImage(color, activeGrid)}
              >
                    <img 
            src={deleteBttn}
            alt="Delete"
            style={{
            width: '30px',
            height: '30px'
          }}
        />
              </button>
            </div>
          )}
    
          <div style={{ display: "flex" }}>
            {griddata.map((item:any, index:number) => (
              <div 
                style={{ ...webStyle.boxesImg, border: activeGrid === item.name ? '2px solid #375280' : "" }} 
                key={`${item.name + index}`}
                onClick={() => handleActiveGrid(item.name)}
              >
                <img style={webStyle.allThreeimg} src={item.imgUrlLink} alt={item.name} />
                <CustomImgFont>{item.name}</CustomImgFont>
              </div>
            ))}
          </div>
        </div>
      );
    }
    
    


    const linkStatusFirst = (lnk: number) => {
      return (LinkStatusFirst(this.state.linkStatus, lnk))
    }
    const circleDiv = (link: number) => {
      return (CircleDiv(this.state.linkStatus, link, webStyle.circleDivActive, webStyle.circleDiv))
    }

    const fontWeight = (weight: number) => {
      return (FontWight(this.state.linkStatus, weight))
    }

    const hrTag = (hrNum: number) => {
      return (HrTag(this.state.linkStatus, hrNum, webStyle.hrTagFill, webStyle.hrTag))
    }

    const circleDIvFIlledFunc = (item: number, circleNum: number) => {
      return (CircleDIvFIlledFunc(this.state.linkStatus, item, circleNum, webStyle.circleDivFilled, circleDiv))
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }} >
          <img src={arrow} alt="arrow" onClick={() => {
    if (this.state.linkStatus === 1) {
      window.location.reload();
    } else {
      this.setState({ linkStatus: this.state.linkStatus - 1 });
    }
  }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h2 style={webStyle.addPro}>Add Products</h2>
        </div>
        <CustomBox>
          <div style={this.state.linkStatus >= 2 ? webStyle.circleDivFilled : { border: "2px solid #375280", height: 32, width: 32, borderRadius: '30px' }}>
            <h1 style={{
              marginTop: "4px", fontSize: "16px", marginLeft: "11px", color: linkStatusFirst(2), marginBottom: '24px', fontStyle: "normal !important",
              fontFamily: "Lato , sans-serif", lineHeight: "24px", fontWeight: 700
            }}>1</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby", marginLeft: "-30px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: 700 , whiteSpace: "nowrap",}}>Product Details</h2>
          </div>
          <hr style={hrTag(2)} />
          <div style={circleDIvFIlledFunc(3, 2)}>
            <h1 style={{
              marginTop: "4px", fontSize: "16px", marginLeft: "11px", color: linkStatusFirst(3), marginBottom: '24px', fontStyle: "normal !important", fontWeight: fontWeight(2),
              fontFamily: "Lato , sans-serif", lineHeight: "24px"
            }}>2</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby", marginLeft: "-30px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: fontWeight(2), whiteSpace: "nowrap" }}>Create Variants</h2>
          </div>
          <hr style={hrTag(3)} />
          <div style={circleDIvFIlledFunc(4, 3)}>
            <h1 style={{
              marginTop: "4px", fontSize: "16px", marginLeft: "11px", color: linkStatusFirst(4), marginBottom: '24px', fontStyle: "normal !important", fontWeight: fontWeight(3),
              fontFamily: "Lato , sans-serif", lineHeight: "24px"
            }}>3</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby", marginLeft: "-30px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: fontWeight(3), whiteSpace: "nowrap" }}>Update Variants</h2>
          </div>
          <hr style={hrTag(4)} />
          <div style={circleDIvFIlledFunc(5, 4)}>
            <h1 style={{
              marginTop: "4px", fontSize: "16px", marginLeft: "11px", color: linkStatusFirst(5), marginBottom: '24px', fontStyle: "normal !important", fontWeight: fontWeight(4),
              fontFamily: "Lato , sans-serif", lineHeight: "24px"
            }}>4</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby", marginLeft: "-30px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: fontWeight(4), whiteSpace: "nowrap" }}>Upload Images</h2>
          </div>
          {userRole.userType==='seller' && (
  <>
    <hr style={hrTag(5)} />
    <div style={circleDIvFIlledFunc(6, 5)}>
      <h1 style={{
        marginTop: "4px", fontSize: "16px", marginLeft: "11px", color: linkStatusFirst(6), marginBottom: '24px', fontStyle: "normal !important", fontWeight: fontWeight(5),
        fontFamily: "Lato , sans-serif", lineHeight: "24px"
      }}>5</h1>
      <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby", marginLeft: "-30px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: fontWeight(5), whiteSpace: "nowrap" }}>Assign Stores</h2>
    </div>
  </>
)}
        </CustomBox>


        {getSecondPage()}

        <div style={{ display: 'flex', marginTop: '25px',alignItems:"center" }}>
          <div>
            <button style={webStyle.backCsvBtn} data-test-id="genderSel11"  onClick={() => {
    if (this.state.linkStatus === 1) {
      window.location.reload();
    } else {
      this.setState({ linkStatus: this.state.linkStatus - 1 });
    }
  }} >Back</button>&nbsp;&nbsp;
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <button style={webStyle.confirmBtn} data-test-id="genderSel12" onClick={this.handleNextStep}>
            {this.state.linkStatus === this.state.totalSteps - 1 ? 'Confirm' : 'Next'}
              </button>
          </div>
        </div>

        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
        <DialogContainer data-test-id="handleRedirect1"  open={this.state.isSuccess} >
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">Product listed successfully!</Typography>
          </Box>
        </DialogContainer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        textAlign: "center",
        color: "#0F172A",
        fontSize: 30,
        fontWeight: 400,
        fontFamily: "Lato, sans-serif",
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});
const GreenCheckbox = styled(Checkbox)({
  color: "#CCBEB1",
  '&.Mui-checked': {
    color: "#CCBEB1",
  },

  '&.Mui-checked + .MuiIconButton-label': {
    backgroundColor: '#CCBEB1',
  }
});
const UploadText = styled(Typography)({
  marginTop: "5px",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  fontStyle: "normal !important",
  textTransform: "lowercase",
  marginLeft: "-59px",
  color: "#94A3B8",
  fontFamily: "Lato , sans-serif",
})
const UploadBtn = styled(Typography)({
  fontStyle: "normal !important",
  lineHeight: "26px",
  fontFamily: "Lato , sans-serif",
  fontWeight: 700,
  fontSize: "18px",
  marginLeft: "-45px",
  marginTop: "15px",
  color: "#375280",
  textTransform: "capitalize",
  boxShadow: 'none',
})
const CustomRadio = styled(Radio)({
  '&  .MuiIconButton-label': {
    color: '#C7B9AD',
    fontWeight: 'inherit !Important'
  },
  '& MuiTypography-body1': {
    fontWeight: 'inherit !Important'
  }
})
const SelectCustom = styled(Select)({
  fontSize: "18px",
  height: "53px",
  borderRadius: "4px",
  lineHeight: "26px",
  border: "1px solid #F8F8F8",
  fontWeight: 400,
  background: "#f1f1f1",
  fontStyle: "normal !important",
  paddingRight: "15px",
  fontFamily: "Lato , sans-serif",
  paddingLeft: "20px",
  color: '#375280',
  '& .MuiSelect-select.MuiSelect-select': {
    background: '#f1f1f1'
  }
})
const SelectCustomMul = styled(Select)({

  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  fontWeight: 400,
  lineHeight: "26px",
  color: '#94A3B8',
  fontSize: "18px",
  borderRadius: "4px",
  paddingRight: 15,
  background: "#f1f1f1",
  boxShadow: 'none', '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select.MuiSelect-select': {
    margin: 0,
    padding: "0px !important",
    background: '#f1f1f1',
    height: 60,
  },
})
const CustomBoxDiv = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
})
const CustomBoxDivs = styled(Box)({
  gap:"30px",
  flexWrap: 'wrap',
  display: 'flex',
})
const CustomBox = styled(Box)({
  marginTop: '12px',
  marginLeft: "34px",
  width: '100%',
  alignItems: "center",
  display: 'flex',
})

const CustomInput = styled(TextField)({
  paddingTop: 8,
  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  width: "94%",
  paddingLeft: 15,
  borderRadius: "4px",
  marginBottom: 10,
  border: "1px solid #F8F8F8",
  background: "#f1f1f1",
  height: '43px',
  '& ::placeholder': {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "18px",
    color: "#375280",
  },
  '& .MuiInputBase-root': {
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontFamily: "Lato , sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    color: "#375280",
  }
});
const CustomInputTable = styled(TextField)({
  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  width: "100px",
  borderRadius: "4px",
  height: '40px',

  border: '1px solid #E2E8F0',
  '& ::placeholder': {
    fontStyle: "normal !important",
    fontWeight: 400,
    fontFamily: "Lato , sans-serif",
    lineHeight: "24px",
    fontSize: "18px",
    color: "#375280",
  },
  '& .MuiInputBase-root': {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#375280",
    textAlign: 'center',
    fontSize: "16px",
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
    paddingTop: '10px'
  }
});
const CustomImgFont = styled(Typography)({
  color: '#94A3B8',
  fontFamily: "Lato , sans-serif",
  marginTop: 42,
  fontStyle: "normal !important",
  textAlign: 'center',
  background: '#E2E8F0',
  lineHeight: "18px",
  fontWeight: 500,
  fontSize: "16px",
})
const webStyle = {
  titleImg: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: '#375280',
  },

  allThreeimg: {
    width: "100%",
    marginBottom: "-40px",
    height: 80
  },
  boxesImg: { width: 100, height: 100, border: '1px solid #E2E8F0', marginTop: 18, marginRight: 22, borderRadius: 2 },
  tableInput: {
    textAlign: "center",
    border: '1px solid #E2E8F0',
    height: 40,
    width: 100,
  },
  thHead: {
    color: '#375280',
    fontFamily: "Lato , sans-serif",
    borderBottom: "1px solid #CBD5E1",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
  },
  trHead: {
    color: '#375280',
    fontFamily: "Lato , sans-serif",
    lineHeight: "24px",
    fontStyle: "normal !important",
    borderBottom: "1px solid #CBD5E1",
    paddingTop: 25,
    fontSize: "16px",
    paddingBottom: 25,
    fontWeight: 400,
  },
  errorMsg: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    margin: 0,
    color: '#94A3B8'
  },
  errorMsg1:{
    padding: '1px 8px 1px 16px',
    border: '1px solid #DC26264D',
    borderRadius: '4px',
    fontFamily: 'Lato, sans-serif',
    backgroundColor: '#FEE2E24D',
    fontSize: '16px',
    display:"flex",
    fontWeight: 100,
    color: 'rgb(244, 67, 54)',
    alignItems:"center",
  },
  selectedItem: {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  detailTitle: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "30px",
    fontWeight: 400,
    height: 122,
    fontSize: "18px",
    color: "#375280",
  },
  detailDiv: {
    width: "103%"
  },
  selectDiv: {
    width: '97%'
  },
  labelH: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "26px",
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "5px",
    color: "#375280",
    width: '97%',
  },
  headingL: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "26px",
    marginBottom: "5px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    width: '97%',
  },
  labelVari: {
    marginTop: '20px',
    color: "#375280",
    fontSize: "20px",
    marginBottom: "5px",
    fontWeight: 700,
    lineHeight: "24px",
    width: '97%',
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
  },
  menuItem: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontSize: "16px",
    color: "#375280",
  },
  hrTag: {
    margin: 0,
    height: 0,
    width: 315,
    border: '1px solid #E2E8F0'
  }, hrTagFill: {
    border: '1px solid #375280',
    margin: 0,
    height: 0,
    width: 244,
  },
  circleDivActive: {
    borderRadius: '30px',
    width: 32,
    height: 32,
    border: "2px solid #375280",
  },
  circleDiv: {
    borderRadius: '30px',
    width: 32,
    height: 32,
    border: "2px solid rgb(89, 117, 163)",
  }, circleDivFilled: {
    borderRadius: '30px',
    background: '#375280',
    width: 32,
    height: 32,
    border: "2px solid #375280",
  },
  addPro: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "32px",
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
  },
  uploadBtn: {
    fontWeight: 500,
    fontStyle: "normal !important",
    fontSize: "18px",
    color: "#375280",
    lineHeight: "24px",
    border: "1px solid #CCBEB1",
    fontFamily: "Lato , sans-serif",
    background: "#FFFFFF",
    cursor: "pointer",
    height: '54px',
    width: '535px',
    marginTop: 18,
  },
  backCsvBtn: {
    lineHeight: "24px",
    fontWeight: 500,
    fontSize: "18px",
    color: "#375280",
    fontStyle: "normal !important",
    border: "1px solid #CCBEB1",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    background: "#FFFFFF",
    height: '54px',
    width: '200px',
  }, createVarBtn: {
    width: '250px',
    height: '54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    marginBottom: 6
  },
  confirmBtn: {
    fontWeight: 700,
    fontSize: "18px",
    color: "#FFFFFF",
    lineHeight: "26px",
    border: "1px solid #CCBEB1",
    fontStyle: "normal !important",
    background: "#CCBEB1",
    fontFamily: "Lato , sans-serif",
    borderRadius: '2px',
    height: '54px',
    cursor: "pointer",
    width: '200px',
  },
};
// Customizable Area End
