import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
import { ErrorMessage, FormError, FormErrorTouched } from "../../customform/src/CreateSellerStoreController";
import * as Yup from "yup";

export interface Chat {
  data: ChatData;
  current_user_role: string;
}

interface ChatData {
  id: string;
  type: string;
  attributes: ChatAttributes;
  relationships: IRelationships
}

interface IRelationships {
  accounts: {
    data: IType[];
  }
}

interface IType{
  id: string;
  type: string;
}

interface ChatAttributes {
  id: number;
  name: string;
  accounts_chats: AccountsChat[];
  messages: ChatMessage[];
}

interface AccountsChat {
  id: string;
  type: string;
  attributes: AccountsChatAttributes;
}

interface AccountsChatAttributes {
  account_id: number;
  muted: boolean;
  role: string;
  account_role: string;
  account_name: string;
  account_phone: string;
}

interface ChatMessage {
  id: string;
  type: string;
  attributes: ChatMessageAttributes;
}

interface ChatMessageAttributes {
  id: number;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
  message: RecieveMessage;
  role: string;
}

interface RecieveMessage {
  order_request_id?: number;
  wishlist_id?: number;
  wishlist_name?: string;
  product_name?: string;
  gender?: string;
  size?: string;
  color?: string;
  product_quantity?: number;
  price_per_unit?: string;
  shipping_cost?: string;
  product_display_image_url?: string | null;
  catalogues?: Catalogue[];
}

interface Catalogue {
  catalogue_id: number;
  catalogue_name: string;
  images: string[];
}

interface ICatalogueVariantColor {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
  };
}
interface ICatalogueVariantSize {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
  };
}

interface ICatalogueVariantSizeResp{
  data: ICatalogueVariantSize[]
}

interface ICatalogueVariantColorResp{
  data: ICatalogueVariantColor[]
}

interface IFormValue{
  productName: string;
  gender: string;
  size: string;
  color: string;
  productQuantity: string;
  pricePerUnit: string;
  shippingCost: string;
  image: File | null;
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  image: File | null,
  isAlert: boolean,
  message: string,
  isLoading: boolean,
  severity: "error" | "success", 
  sizes: ICatalogueVariantSize[],
  colors: ICatalogueVariantColor[]
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientNewOrderRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  chatIdAPIcallId: string = "";
  colorAPIcallId: string = "";
  sizeAPIcallId: string = "";
  addNewOrderAPIcallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      image: null,
      isAlert: false,
      message: "",
      isLoading: true,
      severity: "success",
      sizes: [],
      colors: []
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors && !responseData.message && !responseData.error) {
        this.apiClientNewOrderSuccess(apiRequestCallId, responseData);
      } else if (responseData && (responseData.errors || responseData.message || responseData.error)) {
        this.apiClientNewOrderFailer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if (userData.userType === "stylist") {
      const chatId = this.props.navigation.getParam("id");
      await this.handleChatData(chatId);
      await this.handleSize();
      await this.handleColor();
    }
    else history.back();
    // Customizable Area End
  }

  apiClientNewOrderSuccess = async (
    apiRequestCallId: string,
    responseJson: Chat & ICatalogueVariantColorResp & ICatalogueVariantSizeResp
  ) => {
    if (apiRequestCallId === this.chatIdAPIcallId) {
      if (responseJson.data) this.handleChatIdAPIResp(responseJson);
    } else if (apiRequestCallId === this.colorAPIcallId) {
      if (responseJson.data) this.handleColorAPIResp(responseJson);
    } else if (apiRequestCallId === this.sizeAPIcallId) {
      if (responseJson.data) this.handleSizeAPIResp(responseJson);
    } else if (apiRequestCallId === this.addNewOrderAPIcallId){
      if (responseJson.data) 
        this.setState((prevState) => ({
          ...prevState,
          isAlert: true,
          message: "Your New Order Request Added Successfully",
          severity: "success"
        }), () => setTimeout(() => {
            this.navigateToOrderClientChat("ClientChat");
        }, 2000));
    }
  };

  apiClientNewOrderFailer = async (responseJson: ErrorMessage & { message: string } & { error: string }) => {
    if (responseJson.message) {
      this.handleRedirectWithOutChat("You are not authorize user to perform task. or chat not Found");
    } 
    else if(responseJson.error){
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        message: responseJson.error,
        isLoading: false,
        severity: "error"
      }));
    }
    else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token)
    } 
  }

  handleChatIdAPIResp = async (responseJson: Chat) =>{
    if(responseJson.current_user_role!=="stylist"){
      this.handleRedirectWithOutChat("You are not authorize user to perform task.");
    } else{
      const userData = await getStorageData("user_data", true);
      const accountExists = responseJson.data.relationships.accounts.data.some(account => +account.id === userData.id);
      if(!accountExists) this.handleRedirectWithOutChat("You are not authorize user to perform task.");
    }
  }

  handleColorAPIResp = (resp: ICatalogueVariantColorResp) => {
    this.setState((prevState) => ({
      ...prevState,
      colors: resp.data
    }))
  }

  handleSizeAPIResp = (resp: ICatalogueVariantSizeResp) => {
    this.setState((prevState) => ({
      ...prevState,
      sizes: resp.data
    }))
  }

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      isLoading: false,
      message: message,
      severity: "error",
    }), () => {
      setTimeout(() => {
        this.navigateToOrderClientChat("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  onAlertNOBoxClose = () => {
    this.setState((prevState)=>({...prevState, isAlert: false}))
  }

  handleRedirectWithOutChat = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      message: message,
      isLoading: false,
      severity: "error"
    }), () => {
      setTimeout(() => {
        this.navigateToOrderClientChat("ClientChat");
      }, 2000);
    });
  }

  navigateToOrderClientChat = (path: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (path === "ClientChat") {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        id: this.props.navigation.getParam("id"),
      });
      message.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
    }
    this.send(message);
  }

  handleNewOrderRequestSubmit = async (values: IFormValue) => {
    const formData = new FormData();
    formData.append("order_request[product_name]", values.productName.trim());
    formData.append("order_request[gender]", values.gender);
    formData.append("order_request[size]", values.size);
    formData.append("order_request[color]", values.color);
    formData.append("order_request[product_quantity]", values.productQuantity);
    formData.append("order_request[price_per_unit]", values.pricePerUnit);
    formData.append("order_request[shipping_cost]", values.shippingCost);
    formData.append("chat_id", this.props.navigation.getParam("id"));
    if (values.image) {
      formData.append("order_request[product_display_image]", values.image);
    }

    this.addNewOrderAPIcallId = await apiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.addNewOrderRequestEndPoint,
      token: await this.getTokenDataStorage(),
      body: formData,
      isJsonStringify: false,
    });
  }

  handleChatData = async (chatId: number) => {
    this.chatIdAPIcallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.chatDataEndPoint}${chatId}`,
      token: await this.getTokenDataStorage(),
    });
  }

  handleColor = async () => {
    this.colorAPIcallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.catalogues_variants_colors}`,
      token: await this.getTokenDataStorage(),
    });
  }

  handleSize = async () => {
    this.sizeAPIcallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.catalogues_variants_sizes}`,
      token: await this.getTokenDataStorage(),
    });
  }

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };

  getTokenDataStorage = async () => {
  return await getStorageData("auth-token");
  }

  handleRemoveImage = () => {
    this.setState((prevState)=>({...prevState, image: null}))
  }

  handleFileAdd = (image: File) => {
    this.setState((prevState)=>({...prevState, image: image}))
  }

  // Customizable Area End
}
// Customizable Area Start

const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
const FILE_SIZE = 5 * 1024 * 1024;

export const validationSchema = Yup.object({
  productName: Yup.string()
  .trim()
  .matches(/^[A-Za-z][A-Za-z\s]*$/, '* Product name must start with a letter and only contain letters')
  .required('* Product name is required. Please enter the necessary information'),
  gender: Yup.string().required('* Gender is required. Please enter the necessary information'),
  size: Yup.string().required('* Size is required. Please enter the necessary information'),
  color: Yup.string().required('* Color is required. Please enter the necessary information'),
  productQuantity: Yup.string()
    .matches(/^\S+$/, '* Product Quantity cannot contain spaces')
    .matches(/^[1-9]\d*$/, '* Product Quantity must be a positive integer and cannot start with 0')
    .required('* Product Quantity is required. Please enter the necessary information'),
  pricePerUnit: Yup.string()
    .matches(/^\S+$/, '* Price Per Unit cannot contain spaces')
    .matches(/^[1-9]\d*$/, '* Price Per Unit must be a positive integer and cannot start with 0')
    .required('* Price Per Unit is required. Please enter the necessary information'),
  shippingCost: Yup.string()
    .matches(/^\S+$/, '* Shipping Cost cannot contain spaces')
    .matches(/^[1-9]\d*$/, '* Shipping Cost must be a positive integer and cannot start with 0')
    .required('* Shipping Cost is required. Please enter the necessary information'),
      image: Yup.mixed()
        .required('* Image is required. Please enter the necessary information')
        .test(
          'fileSize',
          'File size is too large. Maximum 5MB is allowed.',
          (value) => value && value.size <= FILE_SIZE
        )
      .test(
        'fileFormat',
        '* Unsupported file format. Only PNG, JPG, and JPEG are allowed.',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
});
// Customizable Area End
