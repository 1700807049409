import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Typography, IconButton, Menu, MenuItem, styled, Box, InputAdornment, TextField, Avatar } from "@material-ui/core";
import OneToOneChatController, {
  Props,
} from "./OneToOneChatCotroller.web";
import { ThemeProvider } from "react-native-elements";
import { ClientChatMore, chatFashionIMg, linkImageIcon, sendMessageIcon, profileImage } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class OneToOneChat extends OneToOneChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderWelcomeContent = () => {
    return <WelcomeSellerContainer>
      <Box>
      <img src={this.props.senderDetails.profile || profileImage} alt="profile"></img>
      </Box>
      <Box>
      <Typography style={{ fontSize: "17px"}} className="title">Welcome!</Typography>
      <Typography style={{ fontSize: "25px", textTransform: "capitalize"}} className="title">{this.props.senderDetails.full_name ||  ""}</Typography>
      </Box>
  </WelcomeSellerContainer>
  }

  renderInfiniteScroll = () => {
    const { chatMeta, chatMessages} = this.state

    return <Box style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }} id="scrollableChatListDiv">
      <InfiniteScroll
        inverse={true}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        dataLength={chatMessages.length}
        next={this.getMoreApiChatList}
        hasMore={chatMeta.next_page ? !!chatMeta.next_page : false}
        loader={
          <div style={{height: "100px"}}>
          <Loader
            id="chat-list-request-loader"
            dataTestId="chat-list-request-loader-test"
          />
          </div>
        }
        scrollableTarget="scrollableChatListDiv"  
      >
        {chatMessages.map((message) => {
            const isSender = message.role === "sender"
            return <Box 
              padding={2} className="chatMessageContainer"
              style={{
                padding: "5px 24px",
                justifyContent: isSender ? "end" : 'flex-start',
                alignItems: isSender ? "flex-start": "end"
              }}
            >
              <Box>
                <Box className="chatMessageContentContainer">
                    <Typography className="chatMessageText">
                    {typeof message.message === 'string' ? message.message : `${message.message_type || "UI"}: comming soon`}
                    </Typography>
                </Box>
                <Box className="messageTimeTextContainer">
                <Typography className="messageTimeText">{message.created_at}</Typography>
              </Box>
          </Box>
          </Box>
          })
        }
      </InfiniteScroll>
      </Box>
  }

  renderChatInput = () => {
    return <Box display="flex" padding={2} alignItems="center">
        <TextField
          fullWidth
          data-test-id="chat_input"
          placeholder="Type your message here"
          variant="outlined"
          value={this.state.message}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ message: event.target.value})}
          onKeyDown={(event)=>{
            if(event.key === "Enter") this.sendMessageChat()
          }}
          InputProps={{
          endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="file-upload">
                    <IconButton data-test-id="sendMedia" component="span">
                        <img src={linkImageIcon} alt="attach file" />
                    </IconButton>
                </label>
                <input
                    accept=""
                    style={{ display: 'none' }}
                    id="file-upload"
                    type="file"
                    onChange={this.handleFileUpload}
                />
              <IconButton data-test-id="sendMsg" onClick={this.sendMessageChat} >
                  <img src={sendMessageIcon} alt="end" />
              </IconButton>
              </InputAdornment>
          )
          }}
        />
      </Box>
  }

  renderChat = () => {
    const { candidate_name } = this.props.receiverDetails || {}
    const { receiverProfile} = this.state
    return this.props.receiverDetails ? <Box className="chatMessageContainerMain" flex={1} display="flex" flexDirection="column">
        <Box className="chatProfileContainer">
        <Box className="chatProfileNameContainer">
        <Avatar className="chatAvtar" src={receiverProfile  || chatFashionIMg}/>
        <Typography className="userProfileName" variant="h6">{candidate_name}</Typography>
        </Box>
        <Box>
            <img data-test-id="handleClickChatMore" onClick={this.handleClickChatMore} src={ClientChatMore} alt="" />
        </Box>
        </Box>
        {this.renderInfiniteScroll()}
        {this.renderChatInput()}
      </Box> : this.renderWelcomeContent()
  }

  // Customizable Area End
  render() {
  // Customizable Area Start

  // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
            <ClientChatContainer>
              {this.renderChat()}
              <ClientChatCustomMenu
                id="simple-menu"
                keepMounted
                anchorEl={this.state.anchorElClientsChat}
                open={Boolean(this.state.anchorElClientsChat)}
                data-test-id="handleCloseChatMore"
                onClose={this.handleCloseChatMore}
              >
                <Box className="menuItemContainer">
                <MenuItem className="chatfeaturetext" data-test-id="navigateToNewOrderReq" onClick={() => this.navigateToNewPaymentReq("NewOrderRequestId")}>Send New Order Request</MenuItem>
                <MenuItem data-test-id="navigateToNewPaymentReq" onClick={() => this.navigateToNewPaymentReq("NewPaymentRequestId")} className="chatfeaturetext">Send Payment Request</MenuItem>
                <MenuItem className="chatfeaturetext">Share Wishlist</MenuItem>
                <MenuItem className="chatfeaturetext">Report person</MenuItem>
                <MenuItem className="chatfeatureblocktext">Block</MenuItem>
                </Box>
            </ClientChatCustomMenu>
            <ReusableSnackbar
              open={this.state.isChatAlert}
              message={this.state.errorMessage}
              onClose={this.handleCloseChatSnack}
              severity="error"
              dataTestId="alertTestId"
              autoHideDuration={3000}
            />
            </ClientChatContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const ClientChatContainer = styled(Box)({
    "& .chatProfileContainer": {
      padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
      borderBottom: "1px solid #E2E8F0"
    },
    "& .chatMessageContainerMain": {
      boxShadow: "0px 4px 8px 0px #00000008",
      height: "732px"
    },
    "& .chatProfileNameContainer": {
      display: "flex",
      alignItems: "center"
    },
    "& .chatAvtar": {
      marginRight: 16
    },
    "& .chatMessageContainer": {
      display: 'flex', alignItems: 'start', justifyContent: 'end'
    },
    "& .chatMessageContentContainer": {
      padding: 16, backgroundColor: '#CCBEB1', marginBottom: 5,
    },
    "& .messageTimeTextContainer": {
      display: "flex",
      justifyContent: "end"
    },
    "& .messageTimeText": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#8A91A8",
      fontFamily: "lato"
    },
    "& .chatMessageText": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#4D4742",
      fontFamily: "lato"
    },
    "& .userProfileName":{
      fontSize: "18px",
      fontFamily: "lato",
      color: "#375280",
      fontWeight: 500
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      background: "#F1F5F9",
      borderBottom: "1px solid #CBD5E1"
    },
    "& .MuiInputBase-root":{
      backgroundColor: "#F8FAFC",
      fontSize: "18px",
      color: "#375280",
      fontFamily: "lato",
      fontWeight: 400
    },
    "& .MuiListItem-gutters":{
      borderBottom: "1px solid #CBD5E1"
    },
    "& .MuiListItemText-primary": {
        fontSize: "18px",
        color: "#375280",
        fontFamily: "lato",
        fontWeight: 700
    },
    "& .MuiOutlinedInput-root": {
      height: "60px",
      '& fieldset': {
        border: 'none'
      },
      border: "1px solid #F1F5F9",
      borderRadius: "2px",
    },
    "& .MuiListItemText-secondary":{
        fontSize: "16px",
        fontFamily: "lato",
        color: "#94A3B8",
        fontWeight: 400,
    }
  });

const ClientChatCustomMenu = styled(Menu)({
  height: '395px',
  width: '260px',
  marginTop: "40px",
  "& .menuItemContainer": {
    padding: '5px 15px'
  },
  "& .chatfeaturetext": {
    padding: '10px',
    color: "#375280",
    fontFamily: 'Lato',
    borderBottom: '1px solid #E2E8F0',
    fontSize: '16px',
    fontWeight: 500,
  },
  "& .MuiMenu-paper": {
    top: "46px !important"
  },
  "& .chatfeatureblocktext": {
    padding: '10px',
    color: "#F87171",
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Lato',
  }
});

const WelcomeSellerContainer = styled(Box)({
   display: "flex",
   alignItems: "center",
   fontSize: 18,
   fontWeight: 600,
   justifyContent: "center",
   marginTop: "100px",
   fontFamily: "Lato, sans-serif",
   "& img":{
    height: "100px",
    width: "100px",
    marginRight: "7px",
    borderRadius: "50%"
   },
   "& .title": {
    fontWeight: 600,
    fontFamily: 'Lato, sans-serif',
    color: "#375280",
    margin: '0',
    lineHeight: "26px"
   }
})
// Customizable Area End
