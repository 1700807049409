import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { logoutSellerNavigation } from "../../../../../components/src/Seller/logOut";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../../components/src/APICall";
import { ErrorMessage } from "../../CreateSellerStoreController";
import { ErrorMessageResponse } from "../../SellerStoreController";
interface IRespData {
  id: number;
  clientName: string;
  status: string;
}

interface IClientDataResp {
  data: IClientData[];
}
interface IClientData {
  id: string;
  type: string;
  attributes: IClientDataAttributes;
}

interface IClientDataAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  full_name: string;
  phone_number: string;
  type: string | null;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  driver_status: string;
  id_proof: IClientIDProof;
  license: IClientIDProof;
  seller_status: string;
  approve_status: string;
  language: string;
  currency: string;
  service_status: string;
}

interface IClientIDProof {
  name: string;
  record: {
    id: number;
    first_name: string;
    last_name: string;
    full_phone_number: string;
    country_code: number;
    phone_number: number;
    email: string;
    activated: boolean;
    device_id: string | null;
    unique_auth_id: string;
    password_digest: string;
    created_at: string;
    updated_at: string;
    user_name: string | null;
    platform: string | null;
    user_type: string | null;
    app_language_id: string | null;
    last_visit_at: string | null;
    is_blacklisted: boolean;
    suspend_until: string | null;
    status: string;
    gender: string | null;
    date_of_birth: string | null;
    age: string | null;
    stripe_id: string | null;
    stripe_subscription_id: string | null;
    stripe_subscription_date: string | null;
    role: string;
    full_name: string;
    is_verified: string | null;
    share_token: string | null;
    approve_status: string;
    seller_status: string;
    notification: {
      push_notification: INotification;
      email_notification: INotification;
    };
    customer_id: string;
    wallet_amount: string;
    withdrawable_amount: string;
    latitude: string | null;
    longitude: string | null;
    driver_status: string;
    language: string;
    currency: string;
    driver_redirect_flag: string | null;
    stylist_redirect_flag: string | null;
    filter_range: string;
    selected_month: string;
  };
}

interface INotification {
  order_invoices: boolean;
  order_confirmations: boolean;
  delivery_confirmation: boolean;
  reviews_and_feedback_requests: boolean;
  refund_or_payment_complete: boolean;
  marketing_emails: boolean;
  product_stock_updates: boolean;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  respData: IRespData[];
  isLoader: boolean;
  isAlert: boolean;
  message: string
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylishMyClientsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStylishClientId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      respData: [],
      isLoader: true,
      isAlert: false,
      message: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseSCJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseSCJson && !responseSCJson.errors && !responseSCJson.message) {
        this.apiSCSuccessCalled(apiRequestCallId, responseSCJson);
      } else if (responseSCJson && (responseSCJson.errors || responseSCJson.message)) {
        this.apiSCFailureCalled(responseSCJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="stylist") this.fetchStylishClientData();
    else history.back();
    // Customizable Area End
  }

  apiSCSuccessCalled = (apiRequestCallId: string,
    responseSCJson: IClientDataResp) => {
      if (apiRequestCallId === this.getStylishClientId) {
        if (responseSCJson.data) {
          this.setState((prevState) => ({
            ...prevState,
            respData: this.extractStylishClientData(responseSCJson.data),
            isLoader: false
          }));
        }
      }
  }

  extractStylishClientData = (stylishClients: IClientData[]) : IRespData[] => {
    return stylishClients.map((stylishClient) => {
      const attributes = stylishClient.attributes;
      return {
        clientName: attributes.full_name,
        id: attributes.id_proof.record.id,
        status: attributes.service_status
      };
    });
  }

  apiSCFailureCalled = (responseSCJson: ErrorMessage & ErrorMessageResponse & {message: string}) => {
    if(responseSCJson.message	=== "No client found for the stylist."){
      this.setState((prevState)=>({...prevState, isLoader: false}));
    }
if (responseSCJson.errors && !responseSCJson.errors[0].token) {
  this.handleRedirectToHome(responseSCJson.errors);
    } else if (responseSCJson.errors[0].token) {
      this.handleRedirectToHome(responseSCJson.errors[0].token);
     }
  }

  fetchStylishClientData = async () => {
    this.getStylishClientId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getStylishClientEndPoint}`,
      token: await getStorageData("auth-token"),
    });
  }

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      isLoader: false,
      message: message,
    }), () => {
      setTimeout(() => {
        this.handleStylishMyClientsRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  onCloseSCAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  }

  handleStylishMyClientsRedirect = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
