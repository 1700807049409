import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  circularProgress: boolean;
  advancedSearchData: any;
  page: number;
  perPage: number;
  search: string;
  paginateScroll: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSearchdataApiCallID: string = "";
  getSearchdataScrollApiCallID: string = "";
  deleteFavouriteApiCalledId: string = "";
  postFavouriteApiCalledId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      circularProgress: false,
      page: 1,
      perPage: 10,
      search: "",
      advancedSearchData: [],
      paginateScroll: true
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("auth-token");
    this.setState({
        token: token
    });
    this.getSearchApiCallID();
    const dataBox = document.getElementById('scrollableDiv') as HTMLElement;
    dataBox.addEventListener('scroll', this.handleScroll);
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSearchdataApiCallID) {
        if(responseJson?.data){
            this.setState(prev => {
              return {
              advancedSearchData: responseJson.data,
               page: prev.page + 1,
               circularProgress: false
           }
        });       
        }
        }else if(apiRequestCallId === this.getSearchdataScrollApiCallID){
            if(responseJson?.data){
                const arrTrendList = [...this.state.advancedSearchData, ...responseJson.data];
                this.setState(prev => {
                  return {
                    advancedSearchData: [...arrTrendList],
                   page: prev.page + 1,
                   circularProgress: false
               }
            });       
            }else if(responseJson?.errors){
                this.setState({
                    circularProgress: false,
                    paginateScroll: false
                })
            } 
        }else if(apiRequestCallId === this.deleteFavouriteApiCalledId){
            if(responseJson.error){
                this.setState({
                  isAlert: true,
                  alertMsg: responseJson.error,
                  alertType: "error"
                })
              }else{
                this.setState({
                  isAlert: true,
                  alertMsg: `${configJSON.removeFavTitle}`,
                  alertType: "success"
                });
              }
              this.getSearchApiLike();
        }else if(apiRequestCallId === this.postFavouriteApiCalledId){
            if(responseJson?.error){
                      this.setState({
                        isAlert: true,
                        alertType: "error",
                        alertMsg: responseJson.error
                      });
                    }else if(responseJson.errors?.length > 0 && responseJson.errors[0]?.token){
                      this.setState({
                        isAlert: true,
                        alertType: "error",
                        alertMsg: `${responseJson.errors[0].token}`,
                      });
                    }else{
                        this.setState({
                          isAlert: true,
                          alertType: "error",
                          alertMsg: `${configJSON.addFavTitle}`,
                        });
                      }
                    this.getSearchApiLike();
        }
    // Customizable Area End
  }

  // Customizable Area Start
  changeSearch = (value: string) => {
    this.setState({
        search: value,
        page: 1
    }, () => this.getSearchApiCallID())

  }

  handleFavouriteApi = (favouriteId: string, fav: boolean) => {
    if (fav) {
      this.handleDeleteWishlist(favouriteId);
    } else {
      this.handleAddWishlistApiCall(favouriteId);
    }
  }

  handleDeleteWishlist = async (favouriteId?: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFavouriteApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleAddWishlistApiCall = async (favouriteId?: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const httpBody = {
      data: {
        favouriteable_id: favouriteId
      }
    };
  
    this.postFavouriteApiCalledId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFavouriteApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    if(this.state.paginateScroll && (dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight)){
        this.getSearchApiPaginateScroll();
    };
  };
  getSearchApiPaginateScroll = async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
    this.setState({
        circularProgress: true,
    })
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getSearchdataScrollApiCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getSearchApiEndPoint}?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }
    getSearchApiLike = async ()=>{
        const token = await getStorageData("auth-token");
        const header = {
            token: token,
        };
        this.setState({
            circularProgress: true,
        })
            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
        
            this.getSearchdataScrollApiCallID = requestMessage.messageId;
            
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.getSearchApiEndPoint}?search=${this.state.search}&page=${this.state.page -1}&per_page=${this.state.perPage}`
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              `GET`
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            
        }
  getSearchApiCallID = async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
    this.setState({
        circularProgress: true,
        paginateScroll: true
    })
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getSearchdataApiCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getSearchApiEndPoint}?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  // Customizable Area End
}
