import React, { ChangeEvent } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n, { i18nUpdate } from "../../../components/src/i18next/i18n";
const navigation = require("react-navigation");

interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
}

interface AccountAttributes {
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string | null;
    full_phone_number: string;
    last_name: string | null;
    full_name: string;
    phone_number: string;
    type: string | null;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
}

interface AccountData {
    id: string;
    type: string;
    attributes: AccountAttributes;
}

interface ForgotDetails {
    isToken: string;
}
interface DataResponse {
    data: AccountData;
}

type AlertType = 'success' | 'error' | 'warning' | 'info';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    newPassword: string;
    showNewPassword: boolean;
    confirmPassword: string;
    showConfirmPassword: boolean;
    errorNewPass: boolean;
    errorConfirmPass: boolean;
    errorMsg: {
        errorNewMsg?: string;
        newError?: boolean;
        errorConfirm?: string;
        confirmError?: boolean;
    };
    isAlert: boolean;
    alertMsg: string;
    alertType: AlertType;
    userDataParam: ForgotDetails;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    forgotPasswordAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            newPassword: "",
            showNewPassword: false,
            confirmPassword: "",
            showConfirmPassword: false,
            errorNewPass: false,
            errorConfirmPass: false,
            errorMsg: {
                errorNewMsg: "",
                newError: false,
                errorConfirm: "",
                confirmError: false
            },
            isAlert: false,
            alertMsg: '',
            alertType: 'success' as AlertType,
            userDataParam: {
                isToken: ""
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        i18nUpdate()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const userNavigationParams = message.getData(
              getName(MessageEnum.NavigationPayLoadMessage)
              );
              this.setState({userDataParam:userNavigationParams})
          }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiSucessCall(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.apiFailureCall()
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    transResetOtp = (newKey: string) => {
        return i18n.t(newKey, { ns: "loginSignup"})
    }


    apiSucessCall = async (apiRequestCallId: string, responseJson: DataResponse) => {
        if (apiRequestCallId === this.forgotPasswordAPICallId) {
            alert("Password reset successfully")
            this.setState({ 
                alertType: 'success',
                isAlert: true, 
                alertMsg: this.transResetOtp(configJSON.successMsg),
                newPassword:"",
                confirmPassword:""},()=>this.openEmailLoginScreen())
        }
    };

    apiFailureCall = () => {
        this.setState({ isAlert: true, alertMsg: this.transResetOtp(configJSON.failedMsg), alertType: 'error' })
    };

    apiCallService = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const forgotPassRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        forgotPassRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        forgotPassRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        forgotPassRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            forgotPassRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(forgotPassRequestMessage.id, forgotPassRequestMessage);
        return forgotPassRequestMessage.messageId;
    };

    handleToggleNewPassword = () => {
        this.setState((prevState) => ({
            showNewPassword: !prevState.showNewPassword,
        }));
    };

    handleToggleCondirmPassword = () => {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false })
    };

    handleNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        const passRegx = configJSON.passRegx;
        if (!passRegx.test(newPassword)) {
            this.setState({
                newPassword: newPassword,
                errorNewPass: true,
                errorConfirmPass: false,
                errorMsg: {
                    errorNewMsg: this.transResetOtp(configJSON.min8digitValidationMsg),
                    newError: true
                }
            });
        } else {
            this.setState({
                errorNewPass: false,
                errorConfirmPass: false,
                newPassword: newPassword,
                errorMsg: {
                    errorNewMsg: "",
                    newError: false
                }
            });
        }
    };

    handleConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = event.target.value;
        const {newPassword} = this.state;
        if (newPassword !== confirmPassword) {
            this.setState({
                confirmPassword: confirmPassword,
                errorNewPass: false,
                errorConfirmPass: true,
                errorMsg: {
                    errorConfirm: this.transResetOtp(configJSON.confirmPass),
                    confirmError: true
                }
            });
        } else {
            this.setState({
                confirmPassword: confirmPassword,
                errorNewPass: false,
                errorConfirmPass: false,
                errorMsg: {
                    errorConfirm: "",
                    confirmError: false
                }
            });
        }
    };

    handleSubmit = (submitEvent: React.FormEvent) => {
        submitEvent.preventDefault();
        const { newPassword, confirmPassword } = this.state;
        const passRegx = configJSON.passRegx;
        if (newPassword === "" || !passRegx.test(newPassword)) {
            this.setState({
                errorNewPass: true,
                errorMsg: {
                    newError: true,
                    errorNewMsg: newPassword ? this.transResetOtp(configJSON.min8digitValidationMsg) : this.transResetOtp(configJSON.validationPassMsg),
                }
            });
        }
        else if (confirmPassword === "" || newPassword !== confirmPassword) {
            this.setState({
                errorConfirmPass: true,
                errorMsg: {
                    errorConfirm: confirmPassword ? this.transResetOtp(configJSON.confirmPass) : this.transResetOtp(configJSON.reEnterValidationMsg),
                    confirmError: true
                }
            });
        }
        else {
            this.postFormApi();
        }
    };

    postFormApi = async () => {
        const body = {
            data: {
                token: this.state.userDataParam.isToken,
                new_password: this.state.newPassword
            }
        };
        this.forgotPasswordAPICallId = await this.apiCallService({
            contentType: configJSON.forgotPasswordAPiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.passwordRecoveryChangePasswordAPiEndPoint,
            body: body
        });
    };

    openEmailLoginScreen = () => {
        const toMobile = new Message(getName(MessageEnum.NavigationMessage));
        toMobile.addData(getName(MessageEnum.NavigationTargetMessage),"EmailAccountLoginBlock");
        toMobile.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(toMobile);
      };
    // Customizable Area End
}
