import React from "react";

// Customizable Area Start
import {
  Box,
  Tabs,
  Tab,
  capitalize,
  styled,
  Grid, Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { setting, tshirt } from "./assets";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import { Vector, scroll} from "../../customisableuserprofiles2/src/assets";
import Header from "../../../components/src/Header";
import Sidebar from "../../../components/src/Sidebar";
import Footer from "../../../components/src/Footer";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.userType === "buyer" && <Header navigation={this.props.navigation} />}
      <Grid container style={{backgroundColor: this.state.userType === "buyer" ? "#F8F8F8" : "#FFFFFF"}}>
      <Grid item xl={3} md={3} lg={3} sm={3} xs={3}>
        {this.state.userType === "seller" && <SellerSidebar navigation={this.props.navigation} />   }
        {this.state.userType === "stylist" && <StylishSidebar navigation={this.props.navigation} />}
        {this.state.userType === "buyer" && (
          <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} container>
          <Grid item >
            <Box
            
              style={{margin: "27px 40px 0 0",...webStyle.innerContainer, }}>
              <Typography style={webStyle.innerContainer1} >
              {configJSON.HomeMsg}
              </Typography>
              <img src={Vector}/>
              <Typography style={webStyle.innerContainer2}>
              {configJSON.ProfileMsg}
              </Typography>
            </Box>
            <div >
              <Typography style={{margin: "31px 40px 0 0",...webStyle.innerContainer3}}>
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={{margin: "0 40px 37px 0",...webStyle.innerContainer4}}>
            {configJSON.WelcomeAccountMsg}
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </Grid>
        </Grid>
        )}
      </Grid>
      <Grid item xl={8} md={8} lg={8} sm={8} xs={8} >
        {this.state.userType === "seller" && <SellerHeader navigation={this.props.navigation} />   }
        {this.state.userType === "stylist" && <StylishHeader navigation={this.props.navigation} />}
          <div style={webStyle.mainDiv}>
          <div style={webStyle.headerDiv}>
            <h1 style={webStyle.titleNotification}>{configJSON.titleMessage}</h1>
            <img src={setting} alt="setting" data-test-id="settingBtn" style={webStyle.settingicon} onClick={()=>this.handleNavigation()}/>
          </div>
          <Box sx={{ width: '100%' }}>
      <Box style={{borderBottom:'1px solid', color:"rgb(226, 232, 240)",width:'60%'}}>
          {
            this.state.userType === "stylist" &&(
              <Tabs value={this.state.value} data-test-id="StylistvalueTab" TabIndicatorProps={{style: {backgroundColor: "#375280"}}}onChange={(event,newValue)=>{
                this.setState({value:newValue})
              }} aria-label="basic tabs example">
              <CustomTab style={webStyle.tabsStyle}  label={capitalize("Buyer")} {...a11yProps(0)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Seller")} {...a11yProps(1)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Stylist")} {...a11yProps(2)} />
        </Tabs>
              )}
              {this.state.userType === "seller" &&(
              <Tabs value={this.state.value} data-test-id="valueTab" TabIndicatorProps={{style: {backgroundColor: "#375280"}}}onChange={(event,newValue)=>{
                this.setState({value:newValue})
              }} aria-label="basic tabs example">
              <CustomTab style={webStyle.tabsStyle}  label={capitalize("All")} {...a11yProps(0)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Orders")} {...a11yProps(1)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Inventory")} {...a11yProps(2)} />
              </Tabs>
            )
          }

            {this.state.userType === "buyer" &&(
              <Tabs value={this.state.value}
               data-test-id="valueTab"
              TabIndicatorProps={{style: {backgroundColor: "#375280"}}}
              onChange={(event,newValue)=>{
                this.setState({value:newValue})  
              }}
               aria-label="basic tabs example">
               <CustomTab style={webStyle.tabsStyle} {...a11yProps(0)}  label={capitalize("All")}   />
                <CustomTab style={webStyle.tabsStyle} label={capitalize("Deals")} {...a11yProps(1)} />
                <CustomTab style={webStyle.tabsStyle} {...a11yProps(2)} label={capitalize("Your Order")}  />
              </Tabs>
            )
          }
      </Box>
     
      <div
      role="tabpanel"
      hidden={this.state.value !== 0}
      id={`simple-tabpanel-${0}`}
      aria-labelledby={`simple-tab-${0}`}
      data-test-id="custome1"
    >{this.state.value === 0&&<Box sx={{ p: 3 }}>
        <div  style={webStyle.mainDivContent}>
        {getContent([1,2,3,4,5,6])}
        </div></Box>}
        </div>
      
      <div
      role="tabpanel"
      hidden={this.state.value !== 1}
      id={`simple-tabpanel-${1}`}
      aria-labelledby={`simple-tab-${1}`}
      data-test-id="custome1"
    >{this.state.value === 1&&<Box sx={{ p: 3 }}>
        <div  style={webStyle.mainDivContent}>
        {getContent([1,2,3,4,5,6])}
        </div></Box>}
        </div>
        <div
      role="tabpanel"
      hidden={this.state.value !== 2}
      id={`simple-tabpanel-${2}`}
      aria-labelledby={`simple-tab-${2}`}
      data-test-id="custome1"
    >{this.state.value === 2&&<Box sx={{ p: 3 }}>
        <div  style={webStyle.mainDivContent}>
        {getContent([1,2,3,4,5,6])}
        </div></Box>}
        </div>
    </Box>
          </div>
      </Grid>
    {this.state.userType === "buyer" && <Footer  />}
  </Grid></ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const getContent = (data:number[]) =>{
  return (
    data?.map((item)=>{
      return(
        <><div style={webStyle.subDivCont} key={item}>
        <img src={tshirt} alt="" />
        <div style={webStyle.contentDiv}>
          <h1 style={webStyle.contentDivH1}>Your order of White Oversized T-shirt-Order #1456879 is accepted by your delivery partner</h1>
          <h3 style={webStyle.contentDivH2}>Today | 12:00 PM</h3>
        </div>
        </div>
        <hr style={webStyle.hrDiv}/></>

      )
    })
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  palette: {
      primary: {
          main: "#0000ff",
          contrastText: "#fff",
      },
  },
});

const webStyle = {
  headerDiv:{
   display:'flex'
  },
  titleNotification:{
    color: '#375280',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "40px",
    marginTop: "27px",
    fontFamily: "Lato",
  },
  settingicon:{
    marginLeft:'auto'
  },
  mainDiv:{
  margin:'30px',
  marginTop:'20px'
  },
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  innerContainer3: {
    fontFamily: "Lato",
      fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "33.5px",
      fontWeight: 800,
    color: "#375280",
    marginLeft: "40px",
    marginTop: "31px",
    display: "flex",   
    alignItems: "center",
    gap: "15px",
    textTransform: "capitalize"
  } as React.CSSProperties,
  innerContainer2: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
     fontStyle: "normal",   
    lineHeight: "22px",
    color: "#475569"
  },    
  innerContainer4: {
    fontFamily: "Lato",
     fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 400,
    color: "#807D7E",
    marginLeft: "40px",
    marginBottom: "37px"

  },
  tabsStyle:{
    color: '#375280',
    fontSize: '14px',
    lineHeight:'18px',
    fontWeight:600,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  mainDivContent:{
    padding:'20px',
    border:'2px solid #E2E8F0',
    marginLeft:'-24px'
  },
  subDivCont:{
   display:'flex',
   marginBottom:'22px'
  },
  contentDiv:{
   marginLeft:'20px'
  },
  contentDivH1:{
   fontSize:'18px',
   lineHeight:'24px',
   fontWeight:400,
   color:'#375280',
    margin:'0px',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  contentDivH2:{
    fontSize:'16px',
    lineHeight:'22px',
    fontWeight:400,
    color:'#94A3B8',
    margin:'0px',
    marginTop:'10px',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  hrDiv:{
   marginBottom:'22px',
   color:'#fffcfc'
  }
};
const CustomTab = styled(Tab)({
 textTransform:'capitalize'
});
// Customizable Area End
