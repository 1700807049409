import React from "react";

// Customizable Area Start
import {
  styled, Grid,
  Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerDeliveredDetailController, {
  Props,
} from "./SellerDeliveredDetailController.web";
import { Noimageavailablepng, backBlueArrowImg } from "./assets";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import moment from "moment";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SellerDeliveredDetail extends SellerDeliveredDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sellerDeliveredHeader = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return <SellerHeader navigation={this.props.navigation} data-testid="sellerDeliveredHeader"/>;
      case "stylist":
        return <StylishHeader navigation={this.props.navigation} data-testid="sellerDeliveredHeader"/>;
      default:
        return <></>;
    }
  };
  sellerDeliveredSidebar = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return (
          <SellerSidebar
            navigation={this.props.navigation}
            className="sellerDeliveredHeader"
          />
        );
      case "stylist":
        return (
          <StylishSidebar
            navigation={this.props.navigation}
            className="sellerDeliveredHeader"
          />
        );
      default:
        return <></>;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const SellerDeliveredDetailData = this.state.SellerDeliveredDetailView.find(
        (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
            order.attributes.order_management_order.attributes.order_number === this.state.productDetailId
    );
    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xl={2} md={3} lg={2} sm={3} xs={3}>
                    {this.sellerDeliveredSidebar()}
                </Grid>
                <Grid item xl={10} md={9} lg={10} sm={9} xs={9}>
                    {this.sellerDeliveredHeader()}
                    {this.state.isLoadingReady ? (

                        <Box style={{ ...webStyle.tabsBoxReady }}>
                            <CircularProgress style={webStyle.CircularProgressColor} />
                        </Box>

                    ) :
                        <><Box style={webStyle.readyForCollectionTableContainer}>
                            <GroupDetailBox>
                                <Box display="block" alignItems="center">
                                    <Box className="GroupDetailBoxLeft">
                                        <Box data-testid="proceedToSellerDeliveredDetailPage" onClick={this.proceedToSellerDeliveredDetailPage}>
                                            <img src={backBlueArrowImg} alt="" />
                                        </Box>
                                        <Typography className="itemIdText" data-testid="order_number">
                                            #{SellerDeliveredDetailData?.attributes.order_management_order.attributes.order_number}
                                        </Typography>
                                        <button className="deliveredStatusTopBtn">Delivered</button>
                                    </Box>
                                </Box>
                            </GroupDetailBox>
                            <Box>
                                <Typography style={webStyle.itemIdTextDescription}>
                                    Total Products: {SellerDeliveredDetailData?.attributes.order_items[0].attributes.quantity}
                                </Typography>
                            </Box>
                            <GroupOtherDetails className="container">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Store Name
                                        </Typography>
                                        <Typography className="detailTitleDescription">{SellerDeliveredDetailData?.attributes.order_items[0].attributes.store_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Customer Name
                                        </Typography>
                                        <Typography className="detailTitleDescription">{SellerDeliveredDetailData?.attributes.order_management_order.attributes.account}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Delivery Date
                                        </Typography>
                                        <Typography className="detailTitleDescription">{moment(SellerDeliveredDetailData?.attributes.estimated_arrival_time as string).format("D MMM, YYYY")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Shipping Address
                                        </Typography>
                                        <Typography className="detailTitleDescription">
                                            {SellerDeliveredDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.house_or_building_number}, {SellerDeliveredDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.block} {SellerDeliveredDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.area}, {SellerDeliveredDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.street}, {SellerDeliveredDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.city} - {SellerDeliveredDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.zip_code}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Coupon Applied
                                        </Typography>
                                        <Typography className="detailTitleDescription">Fash10(-$5)</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            Payment Method
                                        </Typography>
                                        <Typography className="detailTitleDescription">{SellerDeliveredDetailData?.attributes.order_management_order.attributes.payment_detail?.payment_type}</Typography>
                                    </Grid>
                                </Grid>
                            </GroupOtherDetails>

                            <CustomStyledTable className="container">
                                <Table stickyHeader size="small" className="custom-table">
                                    <TableHead>
                                        <TableRow style={webStyle.SellerDeliveredDetailTableHeaderBg}>
                                            <TableCell style={webStyle.tableHaderSellerDeliveredDetail}><Typography variant="h6">Product</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderSellerDeliveredDetail}><Typography variant="h6">Brand</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderSellerDeliveredDetail}><Typography variant="h6">Size</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderSellerDeliveredDetail}><Typography variant="h6">Color</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderSellerDeliveredDetail}><Typography variant="h6">Product Code</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderSellerDeliveredDetail}><Typography variant="h6">Price</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {SellerDeliveredDetailData?.attributes.order_items?.map((order, index: number) => (
                                            <React.Fragment>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box style={webStyle.productDflex}>
                                                            <Box><img src={order.attributes.catalogue_variant_front_image ? order.attributes.catalogue_variant_front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                                            <Box style={{ ...webStyle.tableHaderrowSellerDeliveredDetail }}>{order.attributes.catalogue_name}</Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowSellerDeliveredDetail }}>{order.attributes.brand_name}</TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowSellerDeliveredDetail }}>{order.attributes.catalogue_variant_size}</TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowSellerDeliveredDetail }}><Box style={webStyle.tableVarientColorMainBoxReady}>
                                                        <Box style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: order.attributes.catalogue_variant_color }} /> <Typography>{order.attributes.catalogue_variant_color}</Typography>
                                                    </Box></TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowSellerDeliveredDetail }}>{order.attributes.catalogue_variant_sku}</TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowSellerDeliveredDetail }}>${order.attributes.total_price}</TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CustomStyledTable>
                        </Box></>
                    }
                    
                </Grid>
            </Grid>
        </ThemeProvider> 
    ); 
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
    fontFamily: "Lato",
  tableHaderSellerDeliveredDetail: {
      color: "#375280",
    fontSize: '16px', 
    fontWeight: 700, 
    WebkitTextStrokeWidth: "0.5px", 
    letterSpacing: "1px",
  },
  tableHaderrowSellerDeliveredDetail: {
      color: "#375280", 
    fontSize: '16px', 
     fontWeight: 400,
  },
  tableVarientColorBoxReady: {
    width: '14px', 
    height: '14px', 
    borderRadius: '10px', 
    marginRight: "8px"
  },
  tableVarientColorMainBoxReady: {
      alignItems: "center",
    display: "flex",
  },
  productDflex: {
    display: "flex",
    alignItems: "center",
  },
  SellerDeliveredDetailTableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  productIMg: {
      height:'34px',
    width: '34px',
    marginRight:"10px",
    borderRadius:'2px',
  },
  readyForCollectionTableContainer: {
    padding: "20px 30px"
  },
  tabsBoxReady: {
      paddingBottom: '20px',
    width: '100%',
    alignItems: 'center',
    height: '75%',
    justifyContent: 'center',
    display: 'flex',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  ArrivingCell: {
      backgroundColor: '#F4F4F4',
    padding: '12px 16px',
    justifyContent: "space-between",
    display: "flex",
  },
  itemIdTextDescription: {
      fontSize: "16px",
      color: "#9A9A9A",
      marginLeft: "32px",
      fontWeight: 500,
  }
}
const CustomStyledTable = styled(TableContainer)({
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    height: "34px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  '& .custom-table': {
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#F4F4F4",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .cell': {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: '#ffffff',
  },
})
const GroupDetailBox = styled(Box)({
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    fontFamily: "Lato",
    padding: "10px 0px",
    "& .GroupDetailBoxLeft": {
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
    },
    "& .deliveredStatusTopBtn": {
        color: "#375280",
        border: "none",
        width: "147px",
        height: "30px",
        textAlign: "center",
        fontWeight: 500,
        marginLeft: "15px",
        borderRadius: "2px",
        fontSize: "12px",
        textTransform: "none",
        backgroundColor: "#E2E8F0",
    },
    "& .itemIdText": {
        fontSize: "24px", 
        color: "#375280", 
        marginLeft: "20px",
        fontWeight: 700,
    },
    "& .readyForStatusButton": {
        width: "150px",
        color: "rgb(55, 82, 128)",
        height: "44px",
        border: "1px solid rgb(204, 190, 177)",
        fontWeight: 500,
        borderRadius: "2px",
        fontSize: "16px",
        textTransform: "none",
        backgroundColor: "#ffffff",
      },
  })
const GroupOtherDetails = styled(Box)({
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
    margin: "25px 0px",
    padding: "24px",
    "& .detailTitleDescription": {
        fontSize: "16px", 
      color: "#94A3B8", 
      marginTop: "10px",
      fontWeight: 400,
    },
    "& .detailTitle": {
        color: "#375280", 
        fontSize: "16px", 
        fontWeight: 400, 
      },
})
// Customizable Area End
