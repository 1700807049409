import {enHeader, arHeader} from './Headerlang'
import { enSidebar, arSidebar } from "./siderbarLang";
import {enFooter, arFooter} from './footerLang'
import { enLoginSignup, arLoginSignup } from "../../../blocks/utilities/src/Language/loginSignupLang"
import { enProdDesc, arProdDesc} from "./productDescLang"
import { enCategoryFilter, arCategoryFilter } from "../../../blocks/utilities/src/Language/CategoriesFilterLang"
import { enCart, arCart } from "../../../blocks/utilities/src/Language/ShoppingCartLang"
import { enOrderManagement, arOrderManagement } from "../../../blocks/utilities/src/Language/OrderManagement"
import { enOrderDetail, arOrderDetail } from "../../../blocks/utilities/src/Language/OrderDetail"
import { enWishlist, arWishlist } from "../../../blocks/utilities/src/Language/WishList"
import { enLanguageCurrency, arLanguageCurrency } from "../../../blocks/utilities/src/Language/LanguageCurrency"
import { enPayment, arPayment} from "../../../blocks/utilities/src/Language/Payment"
import { enStylist, arStylist} from "../../../blocks/utilities/src/Language/Stylist"
import { enProfile, arProfile} from "../../../blocks/utilities/src/Language/Profile"

export const availableResources = {
    en: {
      header: enHeader,
      sidebar: enSidebar,
      footer: enFooter,
      loginSignup: enLoginSignup,
      prodDesc: enProdDesc,
      categoryFilter: enCategoryFilter,
      shoppingCart: enCart,
      orderManagement: enOrderManagement,
      orderDetail: enOrderDetail,
      wishlist: enWishlist,
      languageCurrency: enLanguageCurrency,
      payment: enPayment,
      stylist: enStylist,
      profile: enProfile
    },
    ar: {
      header: arHeader,
      sidebar: arSidebar,
      footer: arFooter,
      loginSignup: arLoginSignup,
      prodDesc: arProdDesc,
      categoryFilter: arCategoryFilter,
      shoppingCart: arCart,
      orderManagement: arOrderManagement,
      orderDetail: arOrderDetail,
      wishlist: arWishlist,
      languageCurrency: arLanguageCurrency,
      payment: arPayment,
      stylist: arStylist,
      profile: arProfile
    }
}
