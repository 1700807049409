import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config");
interface DeliveryAddressAttributesRejected {
  country_code: string;
  contact_number: string;
  name: string;
  area: string;
  zip_code: string;
  phone_number: string;
  block: string;
  city: string;
  house_or_building_number: string;
  street: string;
  longitude: number;
  address_name: string;
  is_default: boolean;
  latitude: number;
}

interface DeliveryAddressRejected {
  attributes: DeliveryAddressAttributesRejected;
  type: string;
  id: string;
}

interface PaymentDetailRejected {
  id: number;
  created_at: string;
  status: string;
  refund_id: string | null;
  updated_at: string;
  amount: number;
  charge_id: string;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  refund_reason: string | null;
  deleted: boolean;
  order_management_order_id: number;
  merchant_id: string | null;
  order_id: string;
  seller_order_id: string | null;
  last_four_card_digit: string;
  refund_amount: string | null;
  payment_type: string;
}

interface OrderManagementOrderAttributesRejected {
  total: string;
  delivery_addresses: DeliveryAddressRejected;
  account: string;
  in_transit_at: string | null;
  placed_at: string;
  order_number: string;
  sub_total: string;
  deliver_by: string | null;
  confirmed_at: string;
  status: string;
  cancelled_at: string | null;
  delivered_at: string | null;
  refunded_at: string | null;
  order_status_id: number;
  order_deliver_time: string | null;
  payment_detail: PaymentDetailRejected;
  returned_at: string | null;
  order_return_time: string | null;
  updated_at: string;
  order_return_date: string | null;
  created_at: string;
  order_deliver_date: string | null;
}

interface OrderManagementOrderRejected {
  attributes: OrderManagementOrderAttributesRejected;
  type: string;
  id: string;
}

interface OrderItemAttributesRejected {
  status: string;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  rejected_at: string | null;
  return_at: string | null;
  process_at: string;
  return_pick_at: string | null;
  shipped_at: string | null;
  unit_price: string;
  return_cancel_at: string | null;
  quantity: number;
  driver_latitude: number | null;
  reason_of_rejection: string | null;
  store_name: string;
  catalogue_variant_sku: string;
  brand_name: string;
  catalogue_name: string;
  catalogue_variant_color: string;
  driver_name: string | null;
  total_price: string;
  catalogue_variant_back_image: string;
  otp: string | null;
  catalogue_variant_size: string;
  catalogue_variant_front_image: string;
  catalogue_variant_side_image: string;
  driver_longitude: number | null;
  driver_phone_number: string | null;
}

interface OrderItemRejected {
  id: string;
  attributes: OrderItemAttributesRejected;
  type: string;
}
interface SellerOrderAttributes {
  id: number;
  order_management_order: OrderManagementOrderRejected;
  order_items: OrderItemRejected[];
  status: string;
  accept_order_upload_time: string | null;
}

interface SellerOrderRejected {
  id: string;
  type: string;
  attributes: SellerOrderAttributes;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rejectOpen: boolean;
  page: number;
  short: string[],
  tableAllOrder: SellerOrderRejected[];
  numberData: string;
  txtInputValue: string;
  isAlert: boolean,
  isLoading: boolean,
  visible: boolean,
  reloadTime: number,
  roleData:string,
  orderId: number,
  newOrderaOpen: boolean,
  confirmId: number,
  already: string,
  rejectId: number,
  rejectedOrder: string[],
  isAlertOrder: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersRejectedOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRejectedOrderDetailseCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderId: 0,
      page: 1,
      visible: false,
      roleData:"",
      tableAllOrder: [],
      rejectedOrder: ["Product", "Brand", "Size", "Color", "Product Code", "Price"],
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      numberData: "",
      rejectOpen: false,
      txtInputValue: "",
      reloadTime: 45,
      isLoading: true,
      newOrderaOpen: true,
      rejectId: 0,
      isAlert: false,
      isAlertOrder: false,
      already: "",
      confirmId: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectedOrderDetailseCallId !== null &&
      this.getRejectedOrderDetailseCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonRejectedDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ tableAllOrder: responseJsonRejectedDetails?.orders.data, isLoading: false, isAlert: true })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  dateFormetRejectTransaction = (created_at: string | null) => {
    if (!created_at) return "";
    const [day, month, year] = created_at.split('-').map(Number);
    const monthsReject = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedMonthRejeact = monthsReject[month - 1];
    return `${day} ${formattedMonthRejeact} ${year}`;
  };

  contioneShopping = () => {
    const toNavigateReject = new Message(getName(MessageEnum.NavigationMessage));
    toNavigateReject.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    toNavigateReject.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessageReject: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessageReject.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 7 });
    toNavigateReject.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessageReject);
    this.send(toNavigateReject);
  }

  async componentDidMount() {
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ orderId: idData })
    this.allOrderData()
    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })
  }

  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListRejetedDeatils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectedOrderDetailseCallId = requestMessageListRejetedDeatils.messageId;
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRejectedAllOrder
    );
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListRejetedDeatils.id, requestMessageListRejetedDeatils);
  }

  getAddressRejeted = (delivery_addresses: {
    id: string;
    type: string;
    attributes: {
      country_code: string;
      name: string;
      contact_number: string;
      phone_number: string;
      zip_code: string;
      street: string;
      block: string;
      area: string;
      house_or_building_number: string;
      city: string;
      is_default: boolean;
      address_name: string;
      latitude: number;
      longitude: number;
    };
  }) => {
    if (delivery_addresses) {
      const { house_or_building_number, block, area, street, city, zip_code } = delivery_addresses.attributes
      return `${house_or_building_number}, ${block} ${area}, ${street}, ${city} - ${zip_code}`
    } else {
      return ""
    }
  }
  // Customizable Area End
}
