import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { format } from 'date-fns'
import { Moment } from 'moment';
import i18n from "../../../components/src/i18next/i18n";
import { IOrderDetail } from "./OrderManagementPage.web";
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
  fixID: string[] | undefined,
  fixIDMode: string[] | undefined,
  returnOrderIde: string;
  cancelReturnOrder: boolean;
  modeOfOrderId: string;
  modeofreturn: string,
  modeReturnOpen: boolean;
  returnOpen: boolean;
  sampleState: string;
  startDate: string | null;
  endDate: string | null;
  showDatePicker: boolean;
  value: number;
  short: string[];
  numberData: string;
  orderStatus: string | undefined;
  myOrders: IOrderDetail[];
  isAlert: boolean,
  allOrderMasg: string;
  tabeLoder:boolean;
  open1:boolean;
  modelLable: string;
  modelId: string;
  profileName: string;
  modeOfsuccessOpen:boolean;
  modedeliverysuccessOpen:boolean;
  deliveryCharges:string | undefined;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderManagementPageWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getCancelOrderCallId: string = "";
  getModeOfReturnOrderCallId: string = "";
  getreturunOrderDetailCallId:string = "";
  clothingSizes: {
    [size: string]: string;
  } = {
    Small: 'S',
    Medium: 'M',
    Large: 'L',
    "Extra Small": 'XS',
    "Extra Large": 'XL',
    "Double Extra Large": 'XXL'
  }
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.CountryCodeMessage),
          getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            deliveryCharges:"",
            modeOfsuccessOpen:false,
          fixID: [],
          fixIDMode: [],
          returnOrderIde: "",
          cancelReturnOrder: false,
          modeOfOrderId: "",
          modeofreturn: "self_drop_off",
          short: [`${this.transProfile("Most")}`, `${this.transProfile("Recent")}`],
          value: 0,
          showDatePicker: false,
          sampleState: "",
          startDate: null,
          endDate: null,
          numberData: '',
          orderStatus: "processing",
          myOrders: [],
          isAlert: false,
          allOrderMasg: "",
          tabeLoder:false,
          open1:false,
          modelLable:"",
          modelId:"",
          profileName: "",
          returnOpen: false,
          modeReturnOpen: false,
          modedeliverysuccessOpen:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getMy_OrdersCallId !== null &&
      this.getMy_OrdersCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory.message === "No order record found.") {
        this.setState({ isAlert: true, myOrders: [], allOrderMasg: responseJsonCategory.message, tabeLoder: false })
      } else {
        this.setState({ myOrders: responseJsonCategory.data, tabeLoder: false, isAlert: false })
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCancelOrderCallId !== null &&
      this.getCancelOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory.meta.message === "Order cancelled successfully") {
        this.orderCategorey(this.state.orderStatus)
        this.setState({ open1: false })
      }
    }
      this.modeReturnOpenapiCall(message)
      this.cancelReturnOrderapiCall(message)
        // Customizable Area End
    }


    // Customizable Area Start
    transProfile = (transKey: string) => {
      return i18n.t( transKey, {ns: "orderManagement"})
    }

    modeReturnOpenapiCall = (message:Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getModeOfReturnOrderCallId !== null &&
      this.getModeOfReturnOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const responseJsonCategoryData = JSON.stringify(responseJsonCategory.order.return_type)
      const responseOff = JSON.parse(responseJsonCategoryData)
      if (responseOff === "self_drop_off") {
        this.setState({ modeOfsuccessOpen: true, })
      }else if(responseOff === "request_delivery_partner") {
        this.setState({ modedeliverysuccessOpen: true })
      }
    }
  }
  cancelReturnOrderapiCall = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getreturunOrderDetailCallId !== null &&
      this.getreturunOrderDetailCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory.data) {
        this.setState({ cancelReturnOrder: false }, () => this.orderCategorey())
      }
    }
  }
    retunOrder = ({
      productId,
      props,
      screenName,
    }: INavigateTo) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
      runEngine.sendMessage(message.id, message);
    }
    navigateTo = ({id,props,screenName}: {id: string | undefined,props: unknown,screenName: string}) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      runEngine.sendMessage(message.id, message);
    }
  
  handleOpen1 = (label:string,oderId:string) => {
    this.setState({open1:true,modelLable: label,modelId: oderId})
  };
  handleClose1 = () => {
    this.setState({open1:false})
  };
  cancelReturnOpen = (returnOrderId:string) => {
    const orderItem = this.state.myOrders && this.state.myOrders.find((orderIde: { id: string | number }) => orderIde.id === returnOrderId)
    const checkId = orderItem && orderItem.attributes.order_items.map((value: { id: string }) => {
      return value.id
    })
    this.setState({cancelReturnOrder:true,returnOrderIde:returnOrderId,fixID:checkId})
  }
  handleReturnClose = () => {
    this.setState({cancelReturnOrder:false})
  };

  modeOfsuccessClose = () => {
    this.setState({modeOfsuccessOpen:false})
  };
  modedeliverysuccessOpen = () => {
    this.setState({modedeliverysuccessOpen:false})
  }

  modeRetuneHandleOpen = (returnOrderId:string) => {
    const orderItemID = this.state.myOrders && this.state.myOrders.find((orderIde: { id: string | number }) => orderIde.id === returnOrderId)
    const checkItemId = orderItemID && orderItemID.attributes.order_items.map((value: { id: string }) => {
      return value.id
    })
    this.setState({ modeReturnOpen: true ,modeOfOrderId:returnOrderId,fixIDMode:checkItemId , deliveryCharges:orderItemID?.attributes.delivery_charges})
  };
  modeRetuneHandleClose = () => {
    this.setState({ modeReturnOpen: false })
  };
    async componentDidMount() {
      this.orderCategorey(this.state.orderStatus)
      const profileHolder = await getStorageData("user_data")
      this.setState({ profileName: JSON.parse(profileHolder).first_name + " " + JSON.parse(profileHolder).last_name })
    }
    getStatus = (label: number) => {
      if (label === 1) return "delivered"
      else if (label === 0) return "processing"
      else if (label === 2) return "returned"
    }
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      event.preventDefault();
      const label = this.getStatus(newValue);
      this.setState({ value: newValue, orderStatus: label });
      this.orderCategorey(label)
    };
  
    cancelOrder = (label: string, oderId: string) => {
      if (label === "Processing") {
        this.setState({open1:false,tabeLoder: true})
        this.orderCancel(oderId)
      }
    }
    onChange = (value: {
      start: Moment;
      end: Moment;
    }) => {
      const startDate: Date = value.start.toDate();
      const endDate: Date = value.end.toDate();
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");
      this.setState({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        showDatePicker: false,
      });
    };
  
    handleInputClick = () => {
      this.setState({ showDatePicker: true });
    };
  
    handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ numberData: event.target.value as string });
    };
   
    orderCategorey = async (orderLable?: string | undefined) => {
      this.setState({tabeLoder:true})
      const token = await getStorageData("auth-token");
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
      };
      const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMy_OrdersCallId = requestMessageList.messageId;
      requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getOrderByIdMethod
      );
      requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        orderLable === undefined ? `${configJSON.myAllorders}` : `${configJSON.myorderscategories}=${orderLable}`
      );
      requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
  
    orderCancel = async (orderLable: string) => {
      const token = await getStorageData("auth-token");
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
      };
      const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getCancelOrderCallId = requestMessageList.messageId;
      requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.cencelOrderMethod
      );
      requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.cancelOrder}=${orderLable}`
      );
      requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    getAddress = (delivery_addresses: {
      id: string;
      type: string;
      attributes: {
        name: string;
        country_code: string;
        phone_number: string;
        contact_number: string;
        street: string;
        zip_code: string;
        area: string;
        block: string;
        city: string;
        house_or_building_number: string;
        address_name: string;
        is_default: boolean;
        latitude: number;
        longitude: number;
      };
    }) => {
      if (delivery_addresses) {
        const { house_or_building_number, block, area, street, city, zip_code } = delivery_addresses.attributes
        return `${house_or_building_number} ${block} ${area}, ${street}, ${city} - ${zip_code}`
      } else {
        return ""
      }
    }

  handleGenderChange = (event: React.ChangeEvent<{}>) => {
    const value = (event.target as HTMLInputElement).value;
    this.setState({modeofreturn:value})
  }

  sellerReuturnType = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getModeOfReturnOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.modeOfReturn}=${this.state.modeofreturn}&seller_order_id=${this.state.modeOfOrderId}&order_item_ids=[${this.state.fixIDMode}]`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  cancelReturnOrder = async () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    const date = new Date(); let hours = date.getHours();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListRejetedDeatils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getreturunOrderDetailCallId = requestMessageListRejetedDeatils.messageId;
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod
    );
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.returunOrder}?order_id=${this.state.returnOrderIde}&type=cancel_return&order_return_date=${formattedDate}&order_return_time=${hours}${ampm}&order_item_ids=[${this.state.fixID}]`
    );
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListRejetedDeatils.id, requestMessageListRejetedDeatils);
  }
    // Customizable Area End
}
