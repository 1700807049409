import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config");
interface INavigateToInProcess {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface DeliveryAddressAttributesInProcess {
  name: string;
  phone_number: string;
  country_code: string;
  contact_number: string;
  street: string;
  zip_code: string;
  block: string;
  city: string;
  house_or_building_number: string;
  area: string;
  is_default: boolean;
  longitude: number;
  latitude: number;
  address_name: string;
}

interface DeliveryAddressInProcess {
  attributes: DeliveryAddressAttributesInProcess;
  id: string;
  type: string;
}

interface PaymentDetailInProcess {
  status: string;
  updated_at: string;
  created_at: string;
  order_id: string;
  id: number;
  merchant_id: string | null;
  currency: string;
  charge_id: string;
  reason: string;
  customer_id: string;
  order_management_order_id: number;
  amount: number;
  refund_amount: string | null;
  account_id: number;
  seller_order_id: string | null;
  refund_id: string | null;
  last_four_card_digit: string;
  refund_reason: string | null;
  deleted: boolean;
  payment_type: string;
}

interface OrderManagementOrderAttributesInProcess {
  placed_at: string;
  account: string;
  total: string;
  order_number: string;
  confirmed_at: string;
  sub_total: string;
  in_transit_at: string | null;
  status: string;
  refunded_at: string | null;
  returned_at: string | null;
  order_deliver_date: string | null;
  cancelled_at: string | null;
  order_status_id: number;
  delivered_at: string | null;
  deliver_by: string | null;
  created_at: string;
  order_deliver_time: string | null;
  delivery_addresses: DeliveryAddressInProcess;
  order_return_date: string | null;
  payment_detail: PaymentDetailInProcess;
  updated_at: string;
  order_return_time: string | null;
}

interface OrderManagementOrderInProcess {
  type: string;
  attributes: OrderManagementOrderAttributesInProcess;
  id: string;
}

interface OrderItemAttributesInProcess {
  status: string;
  confirmed_at: string;
  in_transit_at: string | null;
  placed_at: string;
  delivered_at: string | null;
  cancelled_at: string | null;
  process_at: string;
  shipped_at: string | null;
  rejected_at: string | null;
  return_pick_at: string | null;
  reason_of_rejection: string | null;
  return_at: string | null;
  quantity: number;
  return_cancel_at: string | null;
  catalogue_variant_color: string;
  catalogue_variant_sku: string;
  catalogue_variant_side_image: string;
  total_price: string;
  unit_price: string;
  brand_name: string;
  driver_name: string | null;
  store_name: string;
  catalogue_name: string;
  catalogue_variant_back_image: string;
  catalogue_variant_front_image: string;
  driver_longitude: number | null;
  catalogue_variant_size: string;
  driver_latitude: number | null;
  driver_phone_number: string | null;
  otp: string | null;
}

interface OrderItemInProcess {
  id: string;
  attributes: OrderItemAttributesInProcess;
  type: string;
}
interface SellerOrderAttributes {
  order_management_order: OrderManagementOrderInProcess;
  order_items: OrderItemInProcess[];
  accept_order_upload_time: string | null;
  id: number;
  status: string;
}

interface SellerOrderInProcess {
  id: string;
  type: string;
  attributes: SellerOrderAttributes;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  apiRecall: Function
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tableAllOrderInProcess: SellerOrderInProcess[];
  txtInputValue: string;
  metaData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  numberData: string;
  page: number;
  visible: boolean,
  rejectId: number,
  isAlert: boolean,
  short: string[],
  rejectOpen: boolean;
  reloadTime: number,
  confirmId: number,
  newOrderaOpen: boolean,
  isLoadingInProcess: boolean,
  tableHaderName: string[],
  already: string,
  isAlertOrder: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersInProcessOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllInProcessOrderCallId: string = "";
  getMy_OrdersCallId: string = "";
  getTimeInProcessOrderCallId: string = "";
  getRejectInProcessOrderCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      already: "",
      txtInputValue: "",
      tableHaderName: ["Order ID", "Order Time", "Products", "Shop", "Total Price", "Status", "Action"],
      isAlertOrder: false,
      page: 1,
      metaData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      tableAllOrderInProcess: [],
      rejectId: 0,
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      numberData: "",
      isAlert: false,
      rejectOpen: false,
      reloadTime: 45,
      newOrderaOpen: true,
      visible: false,
      isLoadingInProcess: true,
      confirmId: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllInProcessOrderCallId !== null &&
      this.getAllInProcessOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonInProcessOrderOrder = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ metaData: responseJsonInProcessOrderOrder?.meta, tableAllOrderInProcess: this.sortById(responseJsonInProcessOrderOrder?.data), isLoadingInProcess: false, isAlert: responseJsonInProcessOrderOrder.error === "No orders found." ? true : false })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeInProcessOrderCallId !== null &&
      this.getTimeInProcessOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonInProcess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonInProcess) {
        this.setState({ already: "Order Accepted", isAlertOrder: true })
        this.props.apiRecall()
        setTimeout(() => {
          this.allOrderDataInProcess()
        }, 3000)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.allOrderDataInProcess()
  }
  handleCancelInProcess = () => {
    this.setState({ visible: false });
  };
  handleInProcess = (confirmid: number) => {
    this.setState({ visible: true, confirmId: confirmid });
  };
  oncloseAlertInProcess = () => {
    this.setState({ isAlertOrder: false });
  };

  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const orderInProcessDate = new Date(created_at);
    let hoursOrder = orderInProcessDate.getHours();
    const minutesInProcess = orderInProcessDate.getMinutes();
    const ampm = hoursOrder >= 12 ? 'PM' : 'AM';
    hoursOrder = hoursOrder % 12;
    hoursOrder = hoursOrder ? hoursOrder : 12;
    const minutesStr = minutesInProcess < 10 ? '0' + minutesInProcess : minutesInProcess;
    return `${hoursOrder}:${minutesStr} ${ampm}`;
  }

  timeDataInProcess = () => {
    this.timeOrderDataInProcess()
    this.setState({ visible: false, isLoadingInProcess: true })
    this.allOrderDataInProcess()
  }


  allOrderDataInProcess = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageInProcessOrderList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllInProcessOrderCallId = requestMessageInProcessOrderList.messageId;
    requestMessageInProcessOrderList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageInProcessOrderList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInProcessAllOrder}&page=${this.state.page}&per_page=10`
    );
    requestMessageInProcessOrderList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageInProcessOrderList.id, requestMessageInProcessOrderList);
  }

  timeOrderDataInProcess = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListInProcessOrderTime = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeInProcessOrderCallId = requestMessageListInProcessOrderTime.messageId;
    requestMessageListInProcessOrderTime.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListInProcessOrderTime.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmId}&type=ready_to_ship`
    );
    requestMessageListInProcessOrderTime.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListInProcessOrderTime.id, requestMessageListInProcessOrderTime);
  }
  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateToInProcess) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }

  sortById = (items: SellerOrderInProcess[]): SellerOrderInProcess[] => {
    return items.sort((aide, bide) => parseInt(bide.id) - parseInt(aide.id));
  };
  // Customizable Area End
}
